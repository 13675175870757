import {px2mm} from "./conversion";

export const frame = (planogram, workspace, frame) => {

    let mapRatio = Math.max(frame.width / 200, frame.height / 50);

    let image = null;

    let widthRatio = frame.width / workspace.width;
    let heightRatio = frame.height / workspace.height;

    let ratio = Math.max(widthRatio, heightRatio);
    /*
    {
        ratio: ratio,
        minRatio: ratio / 5,
        maxRatio: ratio,
        mapRatio: mapRatio,
        widthRatio: widthRatio,
        heightRatio: heightRatio,
        image: image,
        width: frame.width,
        height: frame.height,
        workspace: workspace,
        frame: frame
    };
     */
};


export const recomputeFrame = (planogram, frame, workspace, olRatio, newRatio, position = null) => {

    if (olRatio === newRatio) {
        return frame;
    }

    if (position === null) {
        position = {
            top: frame.top + (frame.height / 2),
            left: frame.left + (frame.width / 2)
        }
    }

    let hRatio = frame.width / px2mm(workspace.width);
    let vRatio = frame.height / px2mm(workspace.height);

    let top = Math.max((position.top * newRatio) / olRatio, (position.top * newRatio) / vRatio);
    let left = Math.max((position.left * newRatio) / olRatio, (position.left * newRatio) / hRatio);
    let width = Math.max((frame.width * newRatio) / olRatio, (frame.width * newRatio) / hRatio);
    let height = Math.max((frame.height * newRatio) / olRatio, (frame.height * newRatio) / vRatio);

    return {
        top : Math.max(0, top - height / 2),
        left : Math.max(0, left - width / 2),
        width : Math.min(planogram.width, width),
        height : Math.min(planogram.height, height),
        behavior: frame.behavior
    };
}