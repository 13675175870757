import {createBrowserHistory} from 'history'
import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {routerMiddleware} from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './reducers/rootReducer';
import gRpcMiddleware from './middlewares/gRpcMiddleware';
import appMiddleware from "./middlewares/appMiddleware";

export const history = createBrowserHistory();
const store = configureStore();

/**
 * https://redux.js.org/recipes/configuring-your-store
 */
function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware()
    const middlewares = [sagaMiddleware, gRpcMiddleware, appMiddleware, routerMiddleware(history)];

    if (process.env.NODE_ENV === `development`) {
        const { createLogger } = require(`redux-logger`);

        const logger = createLogger({
            predicate: (getState, action) => !/_(SUCCESS|FAILURE)$/.test(action.type) && action.type !== 'LAST_MOMENTUM' && !/^GRPC_/.test(action.type),
            collapsed: (getState, action) => true
        });

        middlewares.push(logger);
    }

    const middlewareEnhancer = applyMiddleware(...middlewares)
    const enhancers = [middlewareEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)

    return {
        ...createStore(rootReducer(history), preloadedState, composedEnhancers),
        runSaga: sagaMiddleware.run
    }
}

export default store
