import {connect} from "react-redux";
import Types from '../../lib/Types';
import Planogram from "./Planogram";
import Module from "./Module";
import Shelf from "./Shelf";
import Slot from "./Slot";
import Shelf3d from "./Shelf3d";
import Range from "./Range";

const ComponentFactory = (props) => {

    if (Types.is(props.node.type, Types.PLANOGRAM)) {
        return <Planogram {...props} />;
    }

    if (Types.is(props.node.type, Types.MODULE)) {
        return <Module {...props} />;
    }

    if (Types.is(props.node.type, Types.SHELF3D)) {
        return <Shelf3d {...props} />;
    }

    if (Types.is(props.node.type, Types.SHELF)) {
        return <Shelf {...props} />;
    }

    if (Types.is(props.node.type, Types.RANGE)) {
        return <Range {...props} />;
    }

    if (Types.is(props.node.type, Types.ITEM)) {
        return <Slot {...props} />;
    }

    return null;
};

const mapStateToProps = ({viewer, app}, ownProps) => {
    let node = viewer.components[ownProps.componentId];

    if (typeof ownProps.acceptItems === 'object') {
        node.acceptItems = {
            ...ownProps.acceptItems,
            ...node.acceptItems
        };
    }

    if (Types.is(node.type, Types.MODULE) && typeof app.dependencies[node.reference] === 'object') {
        node.name = app.dependencies[node.reference].name;
    }

    return {
        ...ownProps,
        node,
    }
};

export default connect(mapStateToProps, null)(ComponentFactory);