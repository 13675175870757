import {Box} from '@material-ui/core';
import {useBreadcrumb} from "../actions/appAction";
import {makeStyles} from "@material-ui/core/styles";
import Filter from "../components/Filter";
import RedirectUrlTable from "../components/table/RedirectUrlTable";

const useStyles = makeStyles({
    item: {
        width: 250
    }
});

const ManageRedirectUrlView = (props) => {

    useBreadcrumb([
        {text: 'Settings', href: '/settings'},
    ]);

    return (
        <Box>
            <Filter />
            <RedirectUrlTable />
        </Box>
    );
};

export default ManageRedirectUrlView;
