import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import defaultColumns from './columns';
import TableWrapper from "./TableWrapper";
import {BoolBadge, TesterBadge} from "../Badge";
import {Percentage} from "../Percentage";

const columns = [
    ...defaultColumns,
    {
        label: 'Stock',
        align: 'left',
        format: (row) => {
            return (
                <Typography gutterBottom variant='body1' noWrap={true}>
                    {row.nbItems.current} <em>/</em> {row.nbItemCapacity.current}
                </Typography>
            );
        },
        orderBy: row => row.nbItems.current,
    },
    {
        label: 'Tester',
        align: 'left',
        format: (row) => {
            return <TesterBadge {...row} />
        },
        orderBy: row => row.testerStatus.current ? 1 : 0
    }
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        padding: '2em 0'
    }
});

const Table = ({shopsAnalytics}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Transformation rate
            </Typography>
            <TableWrapper rows={shopsAnalytics.products} columns={columns} defaultOrderBy={3} />
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shopsAnalytics: app.shopsAnalytics,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);