import {mm2px, size} from './conversion';

export const generateGrid = async (width, height, levels, mapRatio = 100) => {
    const canvas = document.createElement("canvas");
    canvas.width = size(mm2px(width), mapRatio);
    canvas.height = size(mm2px(height), mapRatio);

    if (typeof(canvas.getContext) !== 'function') {
        throw new Error('Canvas context not supported!');
    }

    const ctx = canvas.getContext("2d");

    const areas = generateAreas(width, height, levels);
    areas.forEach((node) => {
        let x = size(mm2px(node.left), mapRatio) + 1;
        let y = size(mm2px(node.top), mapRatio) + 1;
        let width = size(mm2px(node.width), mapRatio) - 2;
        let height = size(mm2px(node.height), mapRatio) - 2;

        ctx.fillStyle = "#e0e0e0";
        ctx.fillRect(x, y, width, height);
    });

    return await new Promise(function(resolve, reject) {
        canvas.toBlob((blob) => {
            if (blob instanceof Blob) {
                resolve({width, height, areas, image: URL.createObjectURL(blob)});
            } else {
                reject('Canvas is empty');
            }
        }, 'image/png');
    });
};

/**
 * return grid placeholders
 * @param width
 * @param levels
 * @returns {Array}
 */
export const generateAreas = (width, height, levels) => {

    if (!(levels instanceof Array)) {
        return [];
    }

    let areas = [];

    let top = 0;

    levels.forEach((level, lvl) => {
        top += level.margin.top;

        areas.push({
            top: top + level.padding.top,
            left: 0,
            width: level.width - (level.padding.left + level.padding.right),
            height: level.height - (level.padding.top + level.padding.bottom)
        });

        top += level.height;
        top += level.margin.bottom;
    });

    return areas;
}