import {Grid, Typography} from "@material-ui/core";
import React from "react";
import ProductImage from "../ProductImage";
import {Skeleton} from "@material-ui/lab";
import ProductCard from "../ProductCard";

const style = {
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

export default [
    {
        label: 'Product',
        align: 'left',
        format: (row, product) => <ProductCard {...product} />,
        orderBy: 'ean',
        skeleton:
            <Grid container spacing={3}>
                <Grid item>
                    <Skeleton variant="rect" width={50} height={50} />
                </Grid>
                <Grid item>
                    <Skeleton animation="wave" width={70} />
                    <Skeleton animation="wave" width={200} />
                    <Skeleton animation="wave" width={200} />
                </Grid>
            </Grid>
    },
    {
        label: 'Furniture',
        align: 'left',
        format: (row) => <Typography variant="body1">{row.furSn}</Typography>,
        orderBy: 'furSn',
    }
];