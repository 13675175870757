/**
 * @fileoverview gRPC-Web generated client stub for apidashboardgrpc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var apidashboardgrpc_common_pb = require('./common_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.apidashboardgrpc = require('./product_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.ProductClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.ProductPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.GetInfoArg,
 *   !proto.apidashboardgrpc.ProductInfo>}
 */
const methodDescriptor_Product_GetInfo = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Product/GetInfo',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.apidashboardgrpc.GetInfoArg,
  proto.apidashboardgrpc.ProductInfo,
  /**
   * @param {!proto.apidashboardgrpc.GetInfoArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ProductInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.GetInfoArg,
 *   !proto.apidashboardgrpc.ProductInfo>}
 */
const methodInfo_Product_GetInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.ProductInfo,
  /**
   * @param {!proto.apidashboardgrpc.GetInfoArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ProductInfo.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.GetInfoArg} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.ProductInfo>}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductClient.prototype.getInfo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/apidashboardgrpc.Product/GetInfo',
      request,
      metadata || {},
      methodDescriptor_Product_GetInfo);
};


/**
 * @param {!proto.apidashboardgrpc.GetInfoArg} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.ProductInfo>}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductPromiseClient.prototype.getInfo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/apidashboardgrpc.Product/GetInfo',
      request,
      metadata || {},
      methodDescriptor_Product_GetInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.Promo,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Product_SetPromo = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Product/SetPromo',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.Promo,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Promo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.Promo,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Product_SetPromo = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Promo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.Promo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductClient.prototype.setPromo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetPromo',
      request,
      metadata || {},
      methodDescriptor_Product_SetPromo,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.Promo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.ProductPromiseClient.prototype.setPromo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetPromo',
      request,
      metadata || {},
      methodDescriptor_Product_SetPromo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.ItemsURL,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Product_UpdateURL = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Product/UpdateURL',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.ItemsURL,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.ItemsURL} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.ItemsURL,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Product_UpdateURL = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.ItemsURL} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.ItemsURL} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductClient.prototype.updateURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Product/UpdateURL',
      request,
      metadata || {},
      methodDescriptor_Product_UpdateURL,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.ItemsURL} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.ProductPromiseClient.prototype.updateURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Product/UpdateURL',
      request,
      metadata || {},
      methodDescriptor_Product_UpdateURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.ItemsReference,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Product_SetReference = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Product/SetReference',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.ItemsReference,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.ItemsReference} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.ItemsReference,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Product_SetReference = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.ItemsReference} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.ItemsReference} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductClient.prototype.setReference =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetReference',
      request,
      metadata || {},
      methodDescriptor_Product_SetReference,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.ItemsReference} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.ProductPromiseClient.prototype.setReference =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetReference',
      request,
      metadata || {},
      methodDescriptor_Product_SetReference);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.Image,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Product_SetImage = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Product/SetImage',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.Image,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Image} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.Image,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Product_SetImage = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Image} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.Image} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.ProductClient.prototype.setImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetImage',
      request,
      metadata || {},
      methodDescriptor_Product_SetImage,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.Image} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.ProductPromiseClient.prototype.setImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Product/SetImage',
      request,
      metadata || {},
      methodDescriptor_Product_SetImage);
};


module.exports = proto.apidashboardgrpc;

