import {Container, Grid} from '@material-ui/core';
import {useBreadcrumb} from "../actions/appAction";
import {makeStyles} from "@material-ui/core/styles";
import GridCardItem from "../components/GridCardItem";


import {ReactComponent as LoyaltyIcon} from "../assets/icons/promotion.svg";
import {ReactComponent as TapAndPlayIcon} from "../assets/icons/url.svg";
import {ReactComponent as PhoneInTalkIcon} from "../assets/icons/phone.svg";


const useStyles = makeStyles({
    item: {
        width: 250
    }
});

const SettingsView = (props) => {

    useBreadcrumb([
        {text: 'Settings', href: '/settings'},
    ]);

    return (
        <Container fixed>
            <Grid container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  alignContent="center"
            >
                <GridCardItem name={"Manage Promotions"} icon={LoyaltyIcon} to={"/settings/promotion"} />
                <GridCardItem name={"Manage Redirect Urls"} icon={TapAndPlayIcon} to={"/settings/redirect_url"} />
                <GridCardItem name={"Manage Phone Alerts"} icon={PhoneInTalkIcon} to={"/settings/alert"} />
            </Grid>
        </Container>
    );
};

export default SettingsView;
