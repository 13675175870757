import {all} from 'redux-saga/effects';
import Auth from './authSaga';
import App from './appSaga';
import Toast from './toastSaga';
import Faker from './fakerSaga';

export default function* RootSaga() {
    let effects = [Auth(), App(), Toast()];

    if (process.env.NODE_ENV === 'development') {
        effects.push(Faker());
    }

    yield all(effects)
}