import AuthenticateWeb from "@itrayl/authenticate-grpc/grpc/jwt_grpc_web_pb";
import AuthenticatePb from "@itrayl/authenticate-grpc/grpc/jwt_pb";

import CommonPb from "@itrayl/api-dashboard-grpc/grpc/common_pb";

import AnalyticWeb from "@itrayl/api-dashboard-grpc/grpc/analytic_grpc_web_pb";
import AnalyticPb from "@itrayl/api-dashboard-grpc/grpc/analytic_pb";

import MapWeb from "@itrayl/api-dashboard-grpc/grpc/map_grpc_web_pb";
import MapPb from "@itrayl/api-dashboard-grpc/grpc/map_pb";

import ProductWeb from "@itrayl/api-dashboard-grpc/grpc/product_grpc_web_pb";
import ProductPb from "@itrayl/api-dashboard-grpc/grpc/product_pb";

import NoostifyWeb from "@itrayl/api-dashboard-grpc/grpc/noostify_grpc_web_pb";
import NoostifyPb from "@itrayl/api-dashboard-grpc/grpc/noostify_pb";

import PlanoWeb from "@itrayl/api-dashboard-grpc/grpc/plano_grpc_web_pb";
import PlanoPb from "@itrayl/api-dashboard-grpc/grpc/plano_pb";

import crypto from 'crypto';
import moment from 'moment';
import * as grpc from "../constants/gRpcConstants";
import {SubmissionError} from "redux-form";
import TimestampPb from 'google-protobuf/google/protobuf/timestamp_pb';
import EmptyPb from 'google-protobuf/google/protobuf/empty_pb';

class GrpcFactory {
    static create(type, payload, token) {

        if (type !== grpc.GRPC_AUTHENTICATION_CREATE_JWT) {
            token = AuthenticatePb.JWTResult.deserializeBinary(token);
        }

        switch (type) {
            case grpc.GRPC_AUTHENTICATION_CREATE_JWT:
                return this.grpcAuthenticateCreateJWT(payload);

            case grpc.GRPC_AUTHENTICATION_RENEW_JWT:
                return this.grpcAuthenticateRenewJWT({refreshJWT: token.getRefreshjwt()}, token);

            case grpc.GRPC_GET_SHOPS:
                return this.grpcGetShops(payload, token);

            case grpc.GRPC_ANALYTIC_SHOPS_ANALYTICS:
                return this.grpcAnalyticShopsAnalytics(payload, token);

            case grpc.GRPC_ANALYTIC_SHOPS_INSTRUCTIONS:
                return this.grpcAnalyticShopsInstructions(payload, token);

            case grpc.GRPC_ANALYTIC_METRICS:
                return this.grpcAnalyticMetrics(payload, token);

            case grpc.GRPC_ANALYTIC_LAST_MOMENTUM:
                return this.grpcAnalyticLastMomentum(payload, token);

            case grpc.GRPC_MAP_FURNITURES:
                return this.grpcMapFurnitures(payload, token);

            case grpc.GRPC_MAP_PLANOS_STATUS:
                return this.grpcMapPlanosStatus(payload, token);

            case grpc.GRPC_PRODUCTS_INFO:
                return this.grpcProductsInfo(payload, token);

            case grpc.GRPC_GET_OOS_NOTIFIER_INFO:
                return this.grpcGetOosNotifierInfo(payload, token);

            case grpc.GRPC_ENABLE_OOS_NOTIFIER_INFO:
                return this.grpcEnableOosNotifierInfo(payload, token);

            case grpc.GRPC_DISABLE_OOS_NOTIFIER_INFO:
                return this.grpcDisableOosNotifierInfo(payload, token);

            case grpc.GRPC_GET_PLANO_OVERMAP:
                return this.grpcGetPlanoOvermap(payload, token);

            case grpc.GRPC_SET_PLANO_OVERMAP:
                return this.grpcSetPlanoOvermap(payload, token);

            case grpc.GRPC_UNSET_PLANO_OVERMAP:
                return this.grpcUnsetPlanoOvermap(payload, token);

            case grpc.GRPC_SET_REDIRECT_URL:
                return this.grpcSetRedirectUrl(payload, token);

            case grpc.GRPC_SET_PROMO:
                return this.grpcSetPromo(payload, token);

        }
    }


    static grpcAuthenticateCreateJWT({credentials}) {
        const hash = crypto.createHash('sha256');

        const userCredentials = new AuthenticatePb.UserCredentials();
        userCredentials.setUsername(credentials.username);
        userCredentials.setHashpass(hash.update(credentials.password).digest('hex'));

        const promise = new AuthenticateWeb.JWTPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        return promise.create(userCredentials, {})
            .then(response => {
                if (response.getHttpstatuscode() !== 200) {
                    throw new SubmissionError({
                        username : 'Username or password invalid.'
                    });
                }
                return {token: response.serializeBinary(), credentials};
            });
    };

    static grpcAuthenticateRenewJWT({refreshJWT}, token) {

        const jwtToken = new AuthenticatePb.JWTToken();
        jwtToken.setJwt(refreshJWT);

        const promise = new AuthenticateWeb.JWTPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        return promise.renew(jwtToken, {jwt: token.getJwt()})
            .then(response => {
                if (response.getHttpstatuscode() !== 200) {
                    throw new Error('Error while trying to renew JWT token.');
                }
                return {token: response.serializeBinary()};
            });
    };

    static grpcGetShops(payload, token) {

        const promise = new MapWeb.MapPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        return promise.getShops(new EmptyPb.Empty(), {jwt: token.getJwt()})
            .then(response => {
                const list = response.toObject().shopListList;

                const shops = Object.fromEntries(list.map(name => [name, {name: name.toLocaleString()}]));
                console.log('grpcGetShops', shops);
                return {shops};
            });
    };

    static grpcAnalyticShopsInstructions(payload, token) {

        const promise = new AnalyticWeb.AnalyticPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        return promise.getShopsInstructions(this.getShopsArg(payload), {jwt: token.getJwt()})
            .then(response => {
                const shopsInstructions = response.toObject().dataList;
                console.log('grpcAnalyticShopsInstructions', shopsInstructions);
                return {shopsInstructions};
            });
    };

    static grpcAnalyticShopsAnalytics(payload, token) {

        const promise = new AnalyticWeb.AnalyticPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);
        const oldShopsArg = new AnalyticPb.GetShopsArg();

        const [duration, unit] = this.getDuration(payload.duration);
        oldShopsArg.setPastComparisonDate(TimestampPb.Timestamp.fromDate(moment().subtract(duration, unit).toDate()));
        oldShopsArg.setTargetDate(TimestampPb.Timestamp.fromDate(new Date()));
        this.addFilters(oldShopsArg, payload);

        const currentShopsArg = new AnalyticPb.GetShopsArg();
        currentShopsArg.setPastComparisonDate(TimestampPb.Timestamp.fromDate(new Date()));
        currentShopsArg.setTargetDate(TimestampPb.Timestamp.fromDate(new Date()));
        this.addFilters(currentShopsArg, payload);

        const old = promise.getShopsAnalytics(oldShopsArg, {jwt: token.getJwt()});
        const current = promise.getShopsAnalytics(currentShopsArg, {jwt: token.getJwt()});

        const format = (current, last, regExp) => {
            for(let key in current) {
                if (regExp.test(key)) {
                    current[key] = {current: current[key], last: typeof last[key] !== 'undefined' ? last[key] : null};
                }
            }
            return current;
        }

        return Promise.all([old, current])
                .then(([oldResponse, currentResponse]) => {
                    const last = oldResponse.toObject();
                    const current = currentResponse.toObject();

                    if (!(current.metricsList instanceof Array) || current.metricsList.length !== 1) {
                        throw new Error('metricsList not valid, expected : Array[1]');
                    }

                    let lastMetricsList = {}, lastProductMetricsList = {};
                    if ((last.metricsList instanceof Array) && last.metricsList.length === 1) {
                        lastMetricsList = last.metricsList[0];
                    }
                    if (last.productMetricsList instanceof Array) {
                        lastProductMetricsList = Object.fromEntries(
                            last.productMetricsList.map(row => [row.ean, row])
                        );
                    }

                    const shopsAnalytics = {
                        ...format(current.metricsList[0], lastMetricsList, /Percent$/),
                        products: current.productMetricsList.map(row => format(row, lastProductMetricsList[row.ean], /^(nb|rate|is|holdTime|testerStatus)/))
                    };

                    console.log('grpcAnalyticShopsAnalytics', shopsAnalytics);

                    return {shopsAnalytics};
                });
    };

    static grpcAnalyticMetrics(payload, token) {

        const promise = new AnalyticWeb.AnalyticPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        return promise.getMetrics(this.getShopsArg(payload), {jwt: token.getJwt()})
            .then(response => {
                return {};
            });
    };

    static grpcMapFurnitures(payload, token) {

        const promise = new MapWeb.MapPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const filters = new CommonPb.Filters();
        this.addFilters(filters, payload);

        return promise.getFurnitures(filters, {jwt: token.getJwt()})
            .then(response => {
                const {dataList} = response.toObject();
                console.log('grpcMapFurnitures', dataList);

                let furnitures = {};
                dataList
                    .sort((fur1, fur2) => fur1.sn.localeCompare(fur2.sn))
                    .forEach(item => {
                        item.name = item.sn;
                        item.levels = item.shelfListList.map(shelf => ({
                            width: 2000,
                            height: 300,
                            offsetWidth: 2000,
                            offsetHeight: 300,
                            margin: {"top":0,"left":0,"right":0,"bottom":0},
                            padding: {"top":0,"left":0,"right":0,"bottom":0},
                        }));
                        furnitures[item.sn] = item;
                    });
                return {furnitures};
            });
    };

    static grpcMapPlanosStatus(payload, token) {

        const promise = new MapWeb.MapPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const getPlanosStatusArg = new MapPb.GetPlanosStatusArg();
        getPlanosStatusArg.setRefDate(TimestampPb.Timestamp.fromDate(new Date(2020, 1, 1)));
        this.addFilters(getPlanosStatusArg, payload);

        return promise.getPlanosStatus(getPlanosStatusArg, {jwt: token.getJwt()})
            .then(response => {
                const planosStatus = response.toObject();
                console.log('grpcMapPlanosStatus', planosStatus);
                return {planosStatus};
            });
    };

    static getShopsArg(payload) {
        const shopsArg = new AnalyticPb.GetShopsArg();

        const [duration, unit] = this.getDuration(payload.duration);
        shopsArg.setPastComparisonDate(TimestampPb.Timestamp.fromDate(new Date()));
        shopsArg.setTargetDate(TimestampPb.Timestamp.fromDate(new Date()));
        this.addFilters(shopsArg, payload);

        return shopsArg;
    }

    static grpcAnalyticLastMomentum(payload, token) {

        const promise = new AnalyticWeb.AnalyticPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

            const filters = new CommonPb.Filters();
        this.addFilters(filters, payload);

        return promise.getLastMomentum(filters, {jwt: token.getJwt()})
            .then(response => {
                const momentum = response.getMomentum().toObject();
                return {momentum: momentum.seconds};
            });
    };

    static grpcProductsInfo(payload, token) {

        const promise = new ProductWeb.ProductPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);
        const getInfoArg = new ProductPb.GetInfoArg();
        this.addFilters(getInfoArg, payload);
        getInfoArg.setRefDate(TimestampPb.Timestamp.fromDate(new Date()));

        const stream = new Promise((resolve, reject) => {
            let products = {};
            const call = promise.getInfo(getInfoArg, {jwt: token.getJwt()});
            call.on('data', function(data) {
                const product = data.toObject();
                products[product.ean] = product;
            });
            call.on('end', function() {
                console.log('grpcProductsInfo', products);
                resolve({products});
            });
            call.on('error', function(e) {
                reject(e);
            });
        });

        return stream;
    };


    static grpcGetOosNotifierInfo(payload, token) {

        const promise = new NoostifyWeb.OosNotifyPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const shop = new NoostifyPb.Shop();
        shop.setShop(payload.shop);

        return promise.getOosNotifierInfo(shop, {jwt: token.getJwt()})
            .then(response => {
                const data = response.toObject();
                console.log('grpcGetOosNotifierInfo', data);
                return data;
            });
    };

    static grpcEnableOosNotifierInfo(payload, token) {

        const promise = new NoostifyWeb.OosNotifyPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new NoostifyPb.EnableOosNotifierArg();
        arg.setShop(payload.shop);
        arg.setPhoneNumber(payload.phoneNumber);

        return promise.enableOosNotifier(arg, {jwt: token.getJwt()})
            .then(response => true);
    };

    static grpcDisableOosNotifierInfo(payload, token) {

        const promise = new NoostifyWeb.OosNotifyPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new NoostifyPb.Shop();
        arg.setShop(payload.shop);

        return promise.disableOosNotifier(arg, {jwt: token.getJwt()})
            .then(response => {
                console.log('grpcDisableOosNotifierInfo', {shop: payload.shop, oosNotifierState: NoostifyPb.OosNotifierState.DISABLE});
                return {};
            });
    };

    static grpcGetPlanoOvermap(payload, token) {

        const promise = new PlanoWeb.PlanoPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new PlanoPb.FurID();
        arg.setShop(payload.shop);
        arg.setSn(payload.furSn);

        return promise.getPlanosByFur(arg, {jwt: token.getJwt()})
            .then(response => {
                const {planosList} = response.toObject();
                const isPlanoOvermap = planosList.some(({componentsList}) => componentsList.some(({isOverMap}) => isOverMap));
                return {isPlanoOvermap};
            });
    };

    static grpcSetPlanoOvermap(payload, token) {

        const promise = new PlanoWeb.PlanoPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new PlanoPb.OvermapArg();
        arg.setShop(payload.shop);
        arg.setFurnitureSn(payload.furSn);

        return promise.setPlanoOvermap(arg, {jwt: token.getJwt()})
            .then(response => true);
    };


    static grpcUnsetPlanoOvermap(payload, token) {

        const promise = new PlanoWeb.PlanoPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new PlanoPb.OvermapArg();
        arg.setShop(payload.shop);
        arg.setFurnitureSn(payload.furSn);

        return promise.unsetPlanoOvermap(arg, {jwt: token.getJwt()})
            .then(response => true);
    };


    static grpcSetRedirectUrl(payload, token) {

        const promise = new ProductWeb.ProductPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new ProductPb.ItemsURL();
        arg.setEan(payload.sku);
        arg.setUrl(payload.redirectUrl);
        arg.setRedirectType(ProductPb.RedirectType.SHOP_URL);
        arg.addFilters(this.createFilter('shop', payload.shop), 0);

        return promise.updateURL(arg, {jwt: token.getJwt()})
            .then(response => true);
    };


    static grpcSetPromo(payload, token) {

        const promise = new ProductWeb.ProductPromiseClient(process.env.REACT_APP_GRPC_WEB_PROXY_URL);

        const arg = new ProductPb.Promo();
        arg.setEan(payload.sku);
        arg.setIsActive(payload.active);
        arg.addFilters(this.createFilter('shop', payload.shop), 0);

        return promise.setPromo(arg, {jwt: token.getJwt()})
            .then(response => true);
    };

    static addFilters(object, filter) {
        const keys = {
            'shop': CommonPb.Category.SHOP,
            'gamme': CommonPb.Category.LABEL,
            'furSn': CommonPb.Category.FURSN
        };

        let cpt = 0;
        for(let key in filter) {
            if (filter[key] !== null && key in keys) {
                object.addFilters(this.createFilter(key, filter[key]), cpt++);
            }
        }
    }

    static createFilter(key, value) {

        const keys = {
            'shop': CommonPb.Category.SHOP,
            'gamme': CommonPb.Category.LABEL,
            'furSn': CommonPb.Category.FURSN
        };

        const filter = new CommonPb.Filter();
        filter.setValue(value);
        filter.setCategory(keys[key]);

        return filter;
    }

    static getDuration(duration) {
        switch (duration) {
            case '1day': return [1, 'day']
            case '1week': return [1, 'week']
            case '1month': return [1, 'month']
            default:
                throw new Error(`Unknown duration "${duration}"`);
        }
    }
}

export default GrpcFactory;