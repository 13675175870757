import * as auth from '../constants/authConstants'

const initialState = {
    rememberMe: typeof localStorage.getItem('remember_me') === 'string',
    token: null,
    profile: null,
    acl: [],
    idle: true
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case auth.LOGIN:
            return {
                ...state,
                token: null,
                profile: null,
                idle: false,
                username: action.username,
                password: action.password,
                rememberMe: action.rememberMe
            };

        case auth.LOGOUT:
        case auth.UNSET_JWT:
            return {
                ...state,
                token: null,
                profile: null,
                idle: true
            };

        case auth.RENEW_JWT:
            return {
                ...state,
                token: action.token,
                idle: true
            };

        case auth.LOGIN_SUCCESS:
        case auth.CREATE_JWT:
        case auth.UPDATE_JWT:
            return {
                ...state,
                token: action.token,
                idle: true
            };

        default:
            return state
    }
}

export default authReducer;
