import {GRPC_CALL, GRPC_ERROR, GRPC_REQUEST, GRPC_SUCCESS} from "../constants/gRpcConstants";

import GrpcFactory from "../lib/GrpcFactory";
import {SubmissionError} from "redux-form";
import {UNSET_JWT} from "../constants/authConstants";

const gRpcMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    if (action.type !== GRPC_CALL) {
        return;
    }

    const { auth } = getState();
    const { actionType, successType, failureType, onSuccess, onFailure, grpcType, payload } = action;

    const token = auth.token;

    if (typeof actionType === 'string') {
        dispatch({type: GRPC_REQUEST, actionType, grpcType});
    }

    return GrpcFactory.create(grpcType, payload, token)
        .then((response) => {
            dispatch({type: GRPC_SUCCESS, actionType, grpcType, response, payload});

            if (typeof actionType === 'string') {
                dispatch({type: actionType, grpcType, response, payload});
            }

            if (typeof successType === 'string') {
                dispatch({type: successType, ...response});
            }

            if (typeof onSuccess === 'function') {
                onSuccess(response);
            }
        })
        .catch(error => {
            if (typeof error === 'object' && error.code === 2) {
                dispatch({type: UNSET_JWT});
            }

            dispatch({type: GRPC_ERROR, actionType, grpcType, error, payload});

            if (typeof actionType === 'string') {
                dispatch({type: actionType, grpcType, error, payload});
            }

            if (typeof failureType === 'string') {
                dispatch({type: failureType, error, payload});
            }

            if (typeof onFailure === 'function') {
                onFailure(error);
            }

            if (error instanceof SubmissionError) {
                throw error;
            }
        });
}

export default gRpcMiddleware
