import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import app from './appReducer';
import auth from './authReducer';
import gRpc from './gRpcReducer';
import store from './storeReducer';
import viewer from './viewerReducer';
import furniture from './furnitureReducer';

const rootReducer = history =>
    combineReducers({
        form,
        app,
        auth,
        gRpc,
        store,
        viewer,
        furniture,
        router: connectRouter(history)
    });

export default rootReducer;
