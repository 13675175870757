import {Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CheckboxField, InputField} from './Form';
import {authLoginAction} from '../actions/authAction';

import Logo from '../assets/images/logo.svg';
import Background from '../assets/images/signin.jpg';
import Background_2 from '../assets/images/signin@2x.jpg';
import Background_3 from '../assets/images/signin@3x.jpg';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link target={"_blank"} color="inherit" to="http://www.gegm.fr">
                GEGM
            </Link>{' '}
            {new Date().getFullYear()}
            {' - all rights reserved'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100vh',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    card: {
        padding: '2em',
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.background.dark}`,
        borderRadius: 3,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    logo: {
        width: 150, // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        '& > img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%'
        }
    },
    header: {
        textAlign: 'center'
    },
    footer: {
        textAlign: 'center'
    },
    divider: {
        position: 'relative',
        lineHeight: '1em',
        textAlign: 'center',
        '&:before, &:after': {
            content: '" "',
            position: 'absolute',
            top: '.5em',
            left: 0,
            width: '40%',
            height: 1,
            background: theme.palette.primary.light,
        },
        '&:after': {
            left: '60%',
        }
    }
}));

const SignIn = (props) => {

    const classes = useStyles();

    const {handleSubmit, submitting} = props;
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.background}>
                <img src={Background} srcSet={`${Background_2} 2x, ${Background_3} 3x`}/>
            </div>
            <div className={classes.paper}>
                <div className={classes.card}>
                    <header className={classes.header}>
                        <img src={Logo} className={classes.logo} />
                    </header>
                    <main>
                        <Form onSubmit={handleSubmit(values => props.authLoginAction(values))}>
                            <InputField
                                variant="outlined"
                                name="username"
                                label="Username"
                                icon="email"
                                type="text"
                                fullWidth={true}
                                required={true}
                                autoFocus
                                className={classes.form}
                            />
                            <InputField
                                variant="outlined"
                                name="password"
                                label="Password"
                                type="password"
                                icon="lock_outline"
                                fullWidth={true}
                                required={true}
                                className={classes.form}
                            />

                            <br/>
                            <br/>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={submitting}
                            >
                                Sign In
                            </Button>

                        </Form>
                    </main>
                    <footer className={classes.footer}>
                        <Link to="#" variant="h6">
                            Forgot your password?
                        </Link>
                    </footer>
                </div>
            </div>
        </Container>
    );
};

const mapDispatchToProps = {
    authLoginAction
};

const form = reduxForm({
    form: 'SignIn'
})(SignIn)

export default connect(null, mapDispatchToProps)(form);