import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography,} from "@material-ui/core";
import {connect} from "react-redux";
import TableWrapper from "./TableWrapper";
import moment from "moment";
import defaultColumns from './columns';


const duration = (seconds) => {
    const time = moment.utc(seconds * 1000).format("H[h] m[m] s[s]");
    return time.replaceAll(/0[hm]\ /g, '');
}

const columns = [
    ...defaultColumns,
    {
        label: 'Hit',
        group: 'Holding power',
        align: 'left',
        format: row => <Typography variant="body1">{row.nbHits.current}</Typography>,
        orderBy: row => row.nbHits.current
    },
    {
        label: 'Hold Average',
        group: 'Holding power',
        align: 'left',
        format: row => <Typography variant="body1">{row.nbHits.current > 0 ? duration(row.holdTime.current.seconds / row.nbHits.current) : '-'}</Typography>,
        orderBy: row => row.nbHits.current > 0 ? row.holdTime.current.seconds / row.nbHits.current : 0,
    },
    {
        label: 'Taps',
        group: 'Holding power',
        align: 'left',
        format: row => <Typography variant="body1">{row.nbTaps.current}</Typography>,
        orderBy: row => row.nbTesterTaps.current,
    },
    {
        label: 'Hot',
        group: 'Holding power',
        align: 'left',
        format: row => <Typography variant="body1">{row.nbHots.current}</Typography>,
        orderBy: row => row.nbHots.current,
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    },
    table: {
        maxHeight: 440,
    },
    search: {
        width: 400,
        marginTop: '1em'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

const TopProducts = ({shopsAnalytics}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Performance
            </Typography>
            <TableWrapper rows={shopsAnalytics.products} columns={columns} defaultOrderBy={3} />
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shopsAnalytics: app.shopsAnalytics
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TopProducts);