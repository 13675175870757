import {useEffect, useMemo, useRef} from "react";
import {connect} from "react-redux";
import ComponentFactory from "./ComponentFactory";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import ItemFactory from "./ItemFactory";

const useStyles = makeStyles({
    root: {
        position: 'absolute',
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
});

const useChange = (previousValue, value) => {
    previousValue = useMemo(() => value, [value]);
    return previousValue !== value;
};

const Shelf = ({node, ratio, mtime, onOpen, ...rest}) => {

    const classes = useStyles(rest);

    const {items, children} = node;
    useChange(items, items);
    useChange(children, children);

    const rootRef = useRef();
    useEffect(() => {
        const style = {
            top: size(mm2px(node.top), ratio) + 'px',
            left: size(mm2px(node.left), ratio) + 'px',
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px'
        };

        const element = rootRef.current;

        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [node.top, node.left, node.width, node.height, ratio]);

    useEffect(() => {

    }, [mtime]);

    return (
        <div className={classes.root} draggable="false" ref={rootRef} title={`${node.name} (#${node.reference})`}>
            <div className={classes.wrapper}>
                {children.map((childId, idx) => <ComponentFactory key={idx} componentId={childId} onOpen={onOpen} ratio={ratio} />)}
                {items.map((item, idx) => <ItemFactory  key={`${node.id}_${idx}`} node={node} item={item} mapped={false} ratio={ratio} />)}
            </div>
        </div>
    );
};

const mapStateToProps = ({app, viewer}, ownProps) => ({
    ...ownProps,
    mtime: viewer.mtime,
    ratio: ownProps.ratio,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Shelf);