import {GRPC_CALL, GRPC_ERROR, GRPC_REQUEST, GRPC_SUCCESS} from '../constants/gRpcConstants'

const initialState = {
    data: null,
    idle: true,
    calls: {}
};

const gRpcReducer = (state = initialState, action) => {
    switch (action.type) {
        case GRPC_REQUEST:
            return {
                ...state,
                type: GRPC_CALL,
                calls: {
                    ...state.calls,
                    [action.grpcType]: false
                },
                idle: false
            };

        case GRPC_ERROR:
            return {
                ...state,
                data: null,
                calls: {
                    ...state.calls,
                    [action.grpcType]: true
                },
                idle: true
            };

        case GRPC_SUCCESS:
            return {
                ...state,
                data: null,
                calls: {
                    ...state.calls,
                    [action.grpcType]: true
                },
                idle: true
            };

        default:
            return state
    }
}

export default gRpcReducer
