export const LOAD = 'LOAD';
export const READY = 'READY';
export const WATCH = 'WATCH';
export const SHOP = 'SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';

export const GET_SHOPS = 'GET_SHOPS';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_FAILURE = 'GET_SHOPS_FAILURE';

export const LAST_MOMENTUM = 'LAST_MOMENTUM';
export const LAST_MOMENTUM_SUCCESS = 'LAST_MOMENTUM_SUCCESS';
export const LAST_MOMENTUM_FAILURE = 'LAST_MOMENTUM_FAILURE';

export const LIST_FURNITURE = 'LIST_FURNITURE';
export const LIST_STORE = 'LIST_STORE';
export const BREADCRUMB = 'BREADCRUMB';

export const INIT_FILTER = 'INIT_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';

export const SHOPS_ANALYTICS = 'SHOPS_ANALYTICS';
export const SHOPS_ANALYTICS_SUCCESS = 'SHOPS_ANALYTICS_SUCCESS';
export const SHOPS_ANALYTICS_FAILURE = 'SHOPS_ANALYTICS_FAILURE';

export const SHOPS_INSTRUCTIONS = 'SHOPS_INSTRUCTIONS';
export const SHOPS_INSTRUCTIONS_SUCCESS = 'SHOPS_INSTRUCTIONS_SUCCESS';
export const SHOPS_INSTRUCTIONS_FAILURE = 'SHOPS_INSTRUCTIONS_FAILURE';

export const METRICS = 'METRICS';
export const METRICS_SUCCESS = 'METRICS_SUCCESS';
export const METRICS_FAILURE = 'METRICS_FAILURE';

export const PRODUCTS_INFO = 'PRODUCTS_INFO';
export const PRODUCTS_INFO_SUCCESS = 'PRODUCTS_INFO_SUCCESS';
export const PRODUCTS_INFO_FAILURE = 'PRODUCTS_INFO_FAILURE';

export const FURNITURES = 'FURNITURES';
export const FURNITURES_SUCCESS = 'FURNITURES_SUCCESS';
export const FURNITURES_FAILURE = 'FURNITURES_FAILURE';

export const PLANOS_STATUS = 'PLANOS_STATUS';
export const PLANOS_STATUS_SUCCESS = 'PLANOS_STATUS_SUCCESS';
export const PLANOS_STATUS_FAILURE = 'PLANOS_STATUS_FAILURE';