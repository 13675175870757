import {responsiveFontSizes, unstable_createMuiStrictModeTheme as createMuiTheme} from "@material-ui/core/styles";

export default responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            main: '#212121',
        },
        secondary: {
            main: '#c42929',
        },
        success: {
            main: '#16c79a',
            light: 'rgba(22, 199, 154, 0.15)'
        },
        info: {
            main: '#28c7fa',
            light: 'rgba(83, 210, 251, .15)'
        },
        error: {
            main: '#f95959',
            light: 'rgb(250, 122, 122, .15)'
        },
        warning: {
            main: '#f9a825',
            light: 'rgba(255, 179, 68, 0.15)'
        },
        danger: {
            main: '#c52811',
            light: 'rgba(255, 97, 109, 0.15)',
        },
        background: {
            black: '#000000',
            dark: '#262626'
        }
    },
    typography: {
        fontFamily: 'Avalon',
        h1: {fontSize: '1.5rem', fontFamily: 'Avalon', fontWeight: 400, textTransform: 'capitalize'},
        h2: {fontSize: '1.2rem', fontFamily: 'Avalon-demi', fontWeight: 400, color: '#9e9e9e'},
        h3: {fontSize: '1.1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        h4: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        h5: {fontSize: '.8rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        h6: {fontSize: '.7rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        subtitle1: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        subtitle2: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        body1: {fontSize: '1rem', fontFamily: 'Avalon', fontWeight: 400},
        body2: {fontSize: '.9rem', fontFamily: 'Avalon', fontWeight: 400},
        button: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        caption: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
        overline: {fontSize: '1rem', fontFamily: 'Avalon-demi', fontWeight: 400},
    },
    overrides: {
        // Style sheet name ⚛️
        MuiBadge: {
            badge: {
                color: 'inherit',
                backgroundColor: 'inherit',
            }
        },
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                textTransform: 'capitalize',
                display: 'inline-flex',
                alignItems: 'flex-start',
            },
            contained: {
                fontWeight: 'normal',
            }
        }
    },
}));