import {Box, Container, Grid} from '@material-ui/core';
import {useBreadcrumb} from "../actions/appAction";
import {makeStyles} from "@material-ui/core/styles";
import GridCardItem from "../components/GridCardItem";
import CastConnectedIcon from "@material-ui/icons/CastConnected";
import {AddAlert} from "@material-ui/icons";
import Filter from "../components/Filter";
import PerformanceTable from "../components/table/PerformanceTable";
import PromotionTable from "../components/table/PromotionTable";

const useStyles = makeStyles({
    item: {
        width: 250
    }
});

const ManagePromotionView = (props) => {

    useBreadcrumb([
        {text: 'Settings', href: '/settings'},
    ]);

    return (
        <Box>
            <Filter />
            <PromotionTable />
        </Box>
    );
};

export default ManagePromotionView;
