export const INIT           = 'VIEWER_INIT';
export const INIT_SUCCESS   = 'VIEWER_INIT_SUCCESS';
export const INIT_FAILURE   = 'VIEWER_INIT_FAILURE';

export const LOAD       = 'VIEWER_LOAD';
export const OPEN       = 'VIEWER_OPEN';
export const CLOSE      = 'VIEWER_CLOSE';
export const UPDATE     = 'VIEWER_UPDATE';
export const GRID       = 'VIEWER_GRID';
export const FRAME      = 'VIEWER_FRAME';
export const WORKSPACE  = 'VIEWER_WORKSPACE';
export const REFRAME    = 'VIEWER_REFRAME';
export const ZOOM_IN    = 'VIEWER_ZOOM_IN';
export const ZOOM_OUT   = 'VIEWER_ZOOM_OUT';
export const ADAPT_H    = 'VIEWER_ADAPT_H';
export const ADAPT_V    = 'VIEWER_ADAPT_V';
