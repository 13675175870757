import {useEffect} from 'react';
import * as app from '../constants/appConstants';
import {INIT_FILTER, UPDATE_FILTER} from '../constants/appConstants';
import * as grpc from "../constants/gRpcConstants";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export const updateBreadcrumb = (items) => ({
    type: app.BREADCRUMB,
    items
});

export const appShopsInstructions = (shop, gamme, duration) => ({
    type: grpc.GRPC_CALL,
    actionType: app.SHOPS_INSTRUCTIONS,
    successType: app.SHOPS_INSTRUCTIONS_SUCCESS,
    failureType: app.SHOPS_INSTRUCTIONS_FAILURE,
    grpcType: grpc.GRPC_ANALYTIC_SHOPS_INSTRUCTIONS,
    payload: {shop, gamme, duration}
});

export const appMetrics = () => ({
    type: grpc.GRPC_CALL,
    actionType: app.METRICS,
    successType: app.METRICS_SUCCESS,
    failureType: app.METRICS_FAILURE,
    grpcType: grpc.GRPC_ANALYTIC_METRICS,
    payload: {}
});

export const appGetShops = () => ({
    type: grpc.GRPC_CALL,
    actionType: app.GET_SHOPS,
    successType: app.GET_SHOPS_SUCCESS,
    failureType: app.GET_SHOPS_FAILURE,
    grpcType: grpc.GRPC_GET_SHOPS,
    payload: {}
});

export const appFurnitures = (shop) => ({
    type: grpc.GRPC_CALL,
    actionType: app.FURNITURES,
    successType: app.FURNITURES_SUCCESS,
    failureType: app.FURNITURES_FAILURE,
    grpcType: grpc.GRPC_MAP_FURNITURES,
    payload: {shop}
});

export const appPlanosStatus = (shop, furSn) => ({
    type: grpc.GRPC_CALL,
    actionType: app.PLANOS_STATUS,
    successType: app.PLANOS_STATUS_SUCCESS,
    failureType: app.PLANOS_STATUS_FAILURE,
    grpcType: grpc.GRPC_MAP_PLANOS_STATUS,
    payload: {shop, furSn}
});

export const appShopsAnalytics = (shop, gamme, duration) => ({
    type: grpc.GRPC_CALL,
    actionType: app.SHOPS_ANALYTICS,
    successType: app.SHOPS_ANALYTICS_SUCCESS,
    failureType: app.SHOPS_ANALYTICS_FAILURE,
    grpcType: grpc.GRPC_ANALYTIC_SHOPS_ANALYTICS,
    payload: {shop, gamme, duration}
});

export const appProductsInfo = (shop) => ({
    type: grpc.GRPC_CALL,
    actionType: app.PRODUCTS_INFO,
    successType: app.PRODUCTS_INFO_SUCCESS,
    failureType: app.PRODUCTS_INFO_FAILURE,
    grpcType: grpc.GRPC_PRODUCTS_INFO,
    payload: {shop}
});

export const appLastMomentum = (shop, furSn) => ({
    type: grpc.GRPC_CALL,
    actionType: app.LAST_MOMENTUM,
    successType: app.LAST_MOMENTUM_SUCCESS,
    failureType: app.LAST_MOMENTUM_FAILURE,
    grpcType: grpc.GRPC_ANALYTIC_LAST_MOMENTUM,
    payload: {shop, furSn}
});

export const appInitFilter = (filter) => ({
    type: INIT_FILTER,
    filter
});

export const appUpdateFilter = (filter) => ({
    type: UPDATE_FILTER,
    filter
});

export const useBreadcrumb = (items) => {
    const dispatch = useDispatch();
    useEffect(() => {
        // Run! Like go get some data from an API.
        dispatch(updateBreadcrumb(items));
    }, []);
};

export const useWatch = (types) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch({type: app.WATCH, types});
        return history.listen((location) => {
            dispatch({type: app.WATCH, types: []});
        });
    }, []);
};
