/**
 * @fileoverview gRPC-Web generated client stub for apidashboardgrpc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.apidashboardgrpc = require('./noostify_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.OosNotifyClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.OosNotifyPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.Shop,
 *   !proto.apidashboardgrpc.GetOosNotifierInfoRet>}
 */
const methodDescriptor_OosNotify_GetOosNotifierInfo = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.OosNotify/GetOosNotifierInfo',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.Shop,
  proto.apidashboardgrpc.GetOosNotifierInfoRet,
  /**
   * @param {!proto.apidashboardgrpc.Shop} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.GetOosNotifierInfoRet.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.Shop,
 *   !proto.apidashboardgrpc.GetOosNotifierInfoRet>}
 */
const methodInfo_OosNotify_GetOosNotifierInfo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.GetOosNotifierInfoRet,
  /**
   * @param {!proto.apidashboardgrpc.Shop} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.GetOosNotifierInfoRet.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.Shop} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.GetOosNotifierInfoRet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.GetOosNotifierInfoRet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.OosNotifyClient.prototype.getOosNotifierInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/GetOosNotifierInfo',
      request,
      metadata || {},
      methodDescriptor_OosNotify_GetOosNotifierInfo,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.Shop} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.GetOosNotifierInfoRet>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.OosNotifyPromiseClient.prototype.getOosNotifierInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/GetOosNotifierInfo',
      request,
      metadata || {},
      methodDescriptor_OosNotify_GetOosNotifierInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.EnableOosNotifierArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OosNotify_EnableOosNotifier = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.OosNotify/EnableOosNotifier',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.EnableOosNotifierArg,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.EnableOosNotifierArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.EnableOosNotifierArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OosNotify_EnableOosNotifier = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.EnableOosNotifierArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.EnableOosNotifierArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.OosNotifyClient.prototype.enableOosNotifier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/EnableOosNotifier',
      request,
      metadata || {},
      methodDescriptor_OosNotify_EnableOosNotifier,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.EnableOosNotifierArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.OosNotifyPromiseClient.prototype.enableOosNotifier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/EnableOosNotifier',
      request,
      metadata || {},
      methodDescriptor_OosNotify_EnableOosNotifier);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.Shop,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OosNotify_DisableOosNotifier = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.OosNotify/DisableOosNotifier',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.Shop,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Shop} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.Shop,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OosNotify_DisableOosNotifier = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.Shop} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.Shop} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.OosNotifyClient.prototype.disableOosNotifier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/DisableOosNotifier',
      request,
      metadata || {},
      methodDescriptor_OosNotify_DisableOosNotifier,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.Shop} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.OosNotifyPromiseClient.prototype.disableOosNotifier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.OosNotify/DisableOosNotifier',
      request,
      metadata || {},
      methodDescriptor_OosNotify_DisableOosNotifier);
};


module.exports = proto.apidashboardgrpc;

