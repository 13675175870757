import {all, cancel, put, take, takeEvery, takeLatest} from 'redux-saga/effects';
import delay from '@redux-saga/delay-p';
import * as auth from '../constants/authConstants';
import {authCreateJWTAction, authLogoutAction, authRenewJWTAction, authUnsetJWTAction} from '../actions/authAction';

function* renewJWT({token}) {
    yield delay(300000);
    let task = yield put(authRenewJWTAction(token));
    yield take(auth.UNSET_JWT);
    yield cancel(task);
}

function* restoreJWT() {
    const tokenItem = localStorage.getItem('token');
    const tokenCtimeItem = localStorage.getItem('token_ctime');
    if (tokenItem !== null && tokenCtimeItem !== null && getTimestamp() - parseInt(tokenCtimeItem) < 15 * 60 * 1000 /* 15min */) {
        const token = new Uint8Array(JSON.parse(tokenItem));
        yield put(authCreateJWTAction(token));
    } else {
        yield put(authLogoutAction());
    }
}

function* storeCredentials({credentials}) {
    if (credentials.rememberMe) {
        localStorage.setItem('remember_me', JSON.stringify(credentials));
    } else {
        localStorage.removeItem('remember_me');
    }
}

function* storeJWT({token}) {
    const value = JSON.stringify(Array.apply([], token));
    localStorage.setItem('token', value);
    localStorage.setItem('token_ctime', getTimestamp().toString());
}

function* clearLocalStorage() {
    localStorage.removeItem('token');
}

function getTimestamp() {
    return (new Date()).getTime();
}

function* loginWatcher() {
    yield all([
        restoreJWT(),
        takeLatest(auth.LOGIN, authUnsetJWTAction),
        takeEvery(auth.LOGIN_SUCCESS, storeJWT),
        takeEvery(auth.LOGIN_SUCCESS, storeCredentials),
        takeEvery(auth.LOGIN_SUCCESS, authCreateJWTAction),
        takeEvery(auth.LOGIN_FAILURE, authUnsetJWTAction),
        takeEvery(auth.UPDATE_JWT, storeJWT),
        takeEvery(auth.LOGIN_SUCCESS, renewJWT),
        takeEvery(auth.CREATE_JWT, renewJWT),
        takeEvery(auth.UPDATE_JWT, renewJWT),
        takeEvery(auth.LOGOUT, clearLocalStorage),
        takeEvery(auth.LOGOUT, authUnsetJWTAction),
    ])
}

export default loginWatcher;

