import {connect} from "react-redux";
import {useBreadcrumb, useWatch} from "../actions/appAction";
import StoreCheckTable from "../components/table/StoreCheckTable";
import {Box} from "@material-ui/core";
import Filter from "../components/Filter";
import * as app from "../constants/appConstants";

const StoreCheckView = (props) => {

    useBreadcrumb([
        {text: 'Store check'}
    ]);

    useWatch([app.SHOPS_ANALYTICS]);

    return (
        <Box>
            <Filter />
            <StoreCheckTable />
        </Box>
    );
};

export default connect(null, null)(StoreCheckView);
