import {LIST_STORE} from "../constants/appConstants";

const initialState = {
    list: {
        1: {id: 1, name: 'Marseille St Ferreol'},
        2: {id: 2, name: 'Paris Rivoli'}
    },
};

const storeReducer = (state = initialState, action) => {
    switch (action.type) {

        case LIST_STORE:
            return {
                ...state
            };

        default:
            return state
    }
}

export default storeReducer;
