import {connect} from "react-redux";
import {useBreadcrumb, useWatch} from "../actions/appAction";
import {Box, Grid} from "@material-ui/core";
import TransformationRateTable from "../components/table/TransformationRateTable";
import LowStockLevelTable from "../components/table/LowStockLevelTable";
import Filter from "../components/Filter";
import React from "react";
import * as app from "../constants/appConstants";

const TopView = (props) => {

    useBreadcrumb([
        {text: 'Top products'}
    ]);

    useWatch([app.SHOPS_ANALYTICS]);

    return (
        <Box>
            <Filter />
            <LowStockLevelTable />
        </Box>
    );
};

export default connect(null, null)(TopView);
