import {Button, makeStyles, Typography} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles({
    root: {
    },
    content: {
        textAlign: 'center'
    },
    media: {
        height: 160,
    },
    icon: {
        position: 'absolute',
        right: 10
    },
    title: {
        margin: 0
    },
    badge: {
        borderRadius: '1em',
        minWidth: '50px',
        padding: 0,
        fontWeight: 'bold',
        boxShadow: 'none',
        display: 'none',
        '& .MuiButton-startIcon': {
            margin: 0
        }
    },
    success: {
        display: 'inline-flex',
        backgroundColor: '#45eba5'
    },
    danger: {
        display: 'inline-flex',
        backgroundColor: '#f95959'
    }
});

const percent = (operand, divider) => divider > 0 ? 100 - Math.round((operand / divider * 100) * 100 / 100) : null;

export const Variation = ({operand}) => {

    const classes = useStyles();

    let diff = percent(operand.current, operand.last);

    return (
        <Button variant="contained" size="small" color="primary" component={'span'}
                startIcon={ diff > 0 ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
                className={clsx(classes.badge, {
                    [classes.danger] : diff < 0,
                    [classes.success] : diff > 0
                })}
        >
            {diff}%
        </Button>
    )
}

export const Percentage = ({operand, divider=null, variant = 'body1'}) => {

    const classes = useStyles();

    const value = divider !== null ? percent(operand.current, divider.current) : operand.current;

    if (value === null) {
        return null;
    }

    return (
        <Typography gutterBottom variant={variant} noWrap={true} className={classes.title}>
            {value}<em>%</em>
            <Variation {...{operand}} />
        </Typography>
    )
}