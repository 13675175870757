import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        maxWidth: 50,
        maxHeight: 50
    }
});

const ProductImage = ({image}) => {

    const classes = useStyles();

    return <img className={classes.root} src={`data:image/png;base64,${image}`} alt=""/>;
}

export default ProductImage;
