import CommonPb from "@itrayl/api-dashboard-grpc/grpc/common_pb";

const SlotStatus = {
    [CommonPb.SlotStatus.SLOT_OK]: {
        name: 'slot_ok',
        level: 0,
        color: 'success',
        text: 'Ok'
    },
    [CommonPb.SlotStatus.NO_FACING]: {
        name: 'no_facing',
        level: 2,
        color: 'error',
        title: 'Urgent',
        text: 'Facing \'important\' position is empty'
    },
    [CommonPb.SlotStatus.WRONG_FACING]: {
        name: 'wrong_facing',
        level: 1,
        color: 'warning',
        title: 'Need your attention',
        text: 'There is a bad product at facing position, which could lead to mistake with furniture price indications'
    },
    [CommonPb.SlotStatus.PLANOGRAM_MISSING]: {
        name: 'planogram_missing',
        level: 1,
        color: 'warning',
        title: 'Need your attention',
        text: 'Some products in the slot do not stand on planogram defined positions'
    },
    [CommonPb.SlotStatus.OUT_OF_STOCK]: {
        name: 'out_of_stock',
        level: 3,
        color: 'danger',
        title: 'Out of stock',
        text: 'The product referenced by the slot is out of stock'
    },
    [CommonPb.SlotStatus.RISK_OUT_OF_STOCK]: {
        name: 'risk_out_of_stock',
        level: 2,
        color: 'error',
        title: 'Urgent',
        text: 'The product referenced by the slot may need attention regarding coming sales trend'
    },
    [CommonPb.SlotStatus.TESTER_PROBLEM]: {
        name: 'tester_problem',
        level: 1,
        color: 'warning',
        title: 'Need your attention',
        text: 'The tester of the slot requires attention, it should be empty or just to be replaced'
    },
    [CommonPb.SlotStatus.COMPONENT_ERROR]: {
        name: 'component_error',
        level: 1,
        color: 'warning',
        title: 'Need your attention',
        text: 'Inform that checking the module (component) planogram is impossible. Too much nested errors'
    },
    [CommonPb.SlotStatus.COMPONENT_MISPLACED]: {
        name: 'component_misplaced',
        level: 1,
        color: 'warning',
        title: 'Need your attention',
        text: 'Component is not correctly placed, internal verification is still possible'
    },
};

/**
 * ProductStatus describes singular product status.
 */
const ProductStatus = {
    [CommonPb.ProductStatus.PRODUCT_OK]: {
        name: 'product_ok',
        color: 'success',
        text: 'Ok'
    },
    [CommonPb.ProductStatus.WRONG_PLANOGRAM]: {
        name: 'wrong_planogram',
        color: 'warning',
        text: 'The product description (iRef) doesn\'t match with the expected product on this planogram required position'
    },
    [CommonPb.ProductStatus.MISPLACED]: {
        name: 'misplaced',
        color: 'warning',
        text: 'The product position is unknown of any planogram expectations'
    },
    [CommonPb.ProductStatus.NO_PRODUCT]: {name: 'no_product', color: 'danger', text: 'No product is detected here'},
};


export {SlotStatus, ProductStatus};