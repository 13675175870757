import AnalyticPb from "@itrayl/api-dashboard-grpc/grpc/analytic_pb";
import {Error, Warning} from "@material-ui/icons";

export default {
    [AnalyticPb.InstructionCode.OOS]: {
        text: 'Out of stock',
        title: 'Out of stock',
        level: 'error',
        icon: Error
    },
    [AnalyticPb.InstructionCode.ROOS]: {
        text: 'Out of stock risk',
        title: 'Out of stock risk',
        level: 'warning',
        icon: Warning
    },
    [AnalyticPb.InstructionCode.TESTER_MISSING]: {
        text: 'Tester is missing',
        title: 'A tester is missing',
        level: 'warning',
        icon: Warning
    },
    [AnalyticPb.InstructionCode.TESTER_MISPLACED]: {
        text: 'Tester is misplaced',
        title: 'A tester is misplaced',
        level: 'warning',
        icon: Warning
    },
    [AnalyticPb.InstructionCode.TESTER_TO_REPLACE]: {
        text: 'Tester to replace',
        title: 'A tester is almost empty, to replace',
        level: 'warning',
        icon: Warning
    },
    [AnalyticPb.InstructionCode.TESTER_EMPTY]: {
        text: 'Tester is empty',
        title: 'A tester is supposed empty',
        level: 'error',
        icon: Error
    },
    [AnalyticPb.InstructionCode.FACING_MISSING]: {
        text: 'Product on the front is missing',
        title: 'A product on the front is missing',
        level: 'error',
        icon: Error
    },
    [AnalyticPb.InstructionCode.WRONG_PLANO]: {
        text: 'Wrong planogram',
        title: 'A product is on the right furniture but it doesn\'t respect the planogram',
        level: 'warning',
        icon: Warning
    },
    [AnalyticPb.InstructionCode.PRODUCT_MISPLACED]: {
        text: 'Product misplaced',
        title: 'A product is not on the right furniture',
        level: 'error',
        icon: Error
    },
    [AnalyticPb.InstructionCode.LOW_TRANSFORMATION]: {
        text: 'Low transformation',
        title: 'A product is taken on hand a lot but never sold',
        level: 'warning',
        icon: Warning
    },
};