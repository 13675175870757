import {GRID, INIT, LOAD} from "../constants/viewerConstants";
import {generateGrid} from "../utils/grid";
import Types from '../lib/Types';
import {appPlanosStatus} from "../actions/appAction";

const appMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const state = getState();

    if (action.type === INIT) {

        /* Generating grid image*/
        const {furSn} = action;
        if (['Fur_test', 'Sephora Cosmetic', 'Suitcase'].indexOf(action.furSn) >= 0) {
            fetch(`/assets/${action.furSn}.json`)
                .then(response => response.json())
                .then(node => {
                    let components = {};
                    let walk = (node) => {
                        node.children.forEach(walk);
                        node.children = node.children.map(child => child.id);
                        node.items = [];
                        components[node.id] = node;
                    };
                    walk(node);
                    dispatch({type: LOAD, furSn, components});

                    dispatch(appPlanosStatus(state.app.shop, furSn));
                });
        } else {
            dispatch({type: LOAD, furSn, components: {}});
            dispatch(appPlanosStatus(state.app.shop, furSn));
        }
    }

    if (action.type === LOAD) {
        const {furSn} = action;
        if (furSn !== null) {
            const component = state.viewer.components[furSn];
            const furniture = state.viewer.furnitures[furSn];

            if (Types.is(component.type, Types.PLANOGRAM)) {
                generateGrid(component.width, component.height, furniture.levels, 10)
                    .then(grid => {
                        dispatch({type: GRID, grid});
                    });
            }
        }

    }
}

export default appMiddleware;
