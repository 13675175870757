import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ReactComponent as SearchIcon} from "../../assets/icons/loupe.svg";

import {Box, InputAdornment, SvgIcon, TableSortLabel, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    },
    thead: {
        fontWeight: "bold",
        backgroundColor: "white",
        verticalAlign: "baseline",
    },
    thead2: {
        backgroundColor: "white",
        padding: 0
    },
    empty: {
        textAlign: "center"
    },
    noborder: {
        border: "none"
    },
    group: {
        textAlign: "center",
        '&:before' : {
            content: '" "',
            border: '1px solid gray',
            borderBottom: 'none',
            position: 'absolute',
            bottom: 0,
            left: '.5em',
            height: '1em',
            right: '.5em',
        }
    },
    search: {
        width: 400,
        marginTop: '1em',
        border: "none",
        '& .MuiInput-underline:before': {
            display: 'none'
        }
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

const orderByCompare = (value1, value2) => {
    if (typeof value1 === 'string') {
        return value1.localeCompare(value2);
    }

    if (typeof value1 === 'number') {
        return value1 - value2;
    }

    return 1;
};

const getColumnGroups = (columns) => {
    let groups = {};
    columns.forEach((column, idx) => {
        const group = typeof column.group === 'string' ? column.group : column.label;
        if (typeof groups[group] === 'undefined') {
            groups[group] = {idx, label: group, columns: []};
        }
        groups[group].columns.push({...column, idx});
    });

    return Object.values(groups).sort((group1, group2) => group1.idx - group2.idx);
};

const skeleton = (columns, key) => (
    <TableRow key={`row_${key}`} hover tabIndex={-1}>
        {columns.map((column, idx) => {
            return (
                <TableCell key={`cell_${idx}`} align={column.align}>
                    {typeof column.skeleton === 'object' ? column.skeleton : <Skeleton animation="wave" />}
                </TableCell>
            );
        })}
    </TableRow>
);

const TableWrapper = ({header: tableHead = null, rows, products, columns, filterFn, sortFn, defaultOrderBy=1, idle, gRpc, gRpcTypes=[]}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    const [search, setSearch] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    if (typeof filterFn === 'function') {
        rows = rows.filter(filterFn);
    }

    if (typeof sortFn === 'function') {
        rows = rows.sort(sortFn);
    }

    if (search.length > 0) {
        const regExp = new RegExp(search.replaceAll(' ', '.*'), 'gi');
        rows = rows.filter(row => {
            const product = products[row.ean];
            return  regExp.test(row.ean) || (
                    typeof product === 'object' && (
                        regExp.test(product.sku)
                    ||  regExp.test(product.model)
                    ||  regExp.test(product.description)
                ));
        });
    }

    useEffect(() => {
        setPage(0);
    }, [search, orderBy, idle]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let pageRows = rows;

    pageRows = pageRows
                    .filter(row => typeof products[row.ean] === 'object')
                    .sort((row1, row2) => row1.ean.localeCompare(row2.ean));

    const onSort = (columnIdx) => (event) => {
        if (Math.abs(orderBy) - 1 === columnIdx) {
            return setOrderBy(-1 * orderBy);
        }
        return setOrderBy(columnIdx + 1);
    };

    const orderByColumn = Math.abs(orderBy) - 1;
    const orderByDirection = orderBy > 0 ? 'asc' : 'desc';
    if (orderByColumn >= 0 && orderByColumn < columns.length) {
        const orderByProp = columns[orderByColumn].orderBy;
        if (typeof orderByProp === 'string') {
            pageRows = pageRows.sort((row1, row2) => orderByCompare(row1[orderByProp], row2[orderByProp]));
        }
        if (typeof orderByProp === 'function') {
            pageRows = pageRows.sort((row1, row2) =>
                orderByCompare(orderByProp.call(null, row1), orderByProp.call(null, row2))
            );
        }

        if (orderBy < 0) {
            pageRows = pageRows.reverse();
        }
    }

    pageRows = pageRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let tableBody = null;
    if (!idle) {
        tableBody = new Array(5).fill().map((_, key) => skeleton(columns, key));
    }
    else if (pageRows.length > 0) {
        tableBody = pageRows.map((row, idx) => {
            return (
                <TableRow hover tabIndex={-1} key={`row_${idx}`}>
                    {columns.map((column, idx) => {
                        const product = products[row.ean];
                        return (
                            <TableCell key={`cell_${idx}`} align={column.align}>
                                {column.format(row, product)}
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        });
    } else {
        tableBody = [
            <TableRow hover key={`row_0`} tabIndex={-1}>
                <TableCell className={classes.empty} colSpan={columns.length}>
                    No results found
                </TableCell>
            </TableRow>
        ];
    }

    if (tableHead === null) {

        const groups = getColumnGroups(columns);
        const twoLevels = groups.filter(group => group.columns.length > 1).length > 0;

        const TableHeadRow = ({idx, label, className, align, minWidth, rowSpan = 1}) => (
            <TableCell
                align={align}
                style={{minWidth}}
                className={className}
                rowSpan={rowSpan}
                sortDirection={orderByColumn === idx ? orderByDirection : false}
            >
                <TableSortLabel
                    active={orderByColumn === idx}
                    direction={orderByColumn === idx ? orderByDirection : 'asc'}
                    onClick={onSort(idx)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );

        const TableHeadGroup = ({idx, label, className, colSpan = 1}) => (
            <TableCell
                colSpan={colSpan}
                className={className}
            >
                <div className={classes.group}>{label}</div>
            </TableCell>
        );

        const firstRow = groups.map(group => {
            if (group.columns.length === 1) {
                return <TableHeadRow key={`th1_${group.idx}`} {...group.columns[0]} rowSpan={2} className={classes.thead} />
            }
            return <TableHeadGroup key={`th1_${group.idx}`} {...group} className={clsx(classes.thead, classes.noborder)} colSpan={group.columns.length} />
        });

        const secondRow = groups
            .filter(group => group.columns.length > 1)
            .map(group => {
                return group.columns.map(column => <TableHeadRow key={`th2_${column.idx}`} {...column} className={classes.thead2} />);
            });

        tableHead = (
            <TableHead>
                <TableRow>
                    {firstRow}
                </TableRow>
                {secondRow.length > 0 ?
                    <TableRow>{[].concat(...secondRow)}</TableRow>:
                    null
                }
            </TableHead>
        );
    }

    return (
        <Paper>
            <Box m={2}>
                <TextField
                    className={classes.search}
                    type="text"
                    placeholder="Filter by name, SKU, ..."
                    autoComplete="false"
                    value={search}
                    InputProps={{
                        onChange: (event) => setSearch(event.target.value),
                        startAdornment: (
                            <InputAdornment position="start">
                                <SvgIcon fontSize="small" viewBox="0 0 32 32">
                                    <SearchIcon />
                                </SvgIcon>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <TableContainer className={classes.table}>
                <Table stickyHeader aria-label="sticky table">
                    {tableHead}
                    <TableBody>
                        {tableBody}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

const mapStateToProps = ({app, gRpc}) => ({
    products: app.products,
    idle: app.idle,
    gRpc
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TableWrapper);