import * as grpc from "../constants/gRpcConstants";
import * as viewer from "../constants/viewerConstants";

export const init = (filter, furSn) => ({
    type: grpc.GRPC_CALL,
    actionType: viewer.INIT,
    successType: viewer.INIT_SUCCESS,
    failureType: viewer.INIT_FAILURE,
    grpcType: grpc.GRPC_MAP_PLANOS_STATUS,
    payload: {filter, furSn}
});

export const load = (components) => ({
    type: viewer.LOAD,
    components
});

export const open = (componentId) => ({
    type: viewer.OPEN,
    componentId
});

export const update = (componentId, items) => ({
    type: viewer.UPDATE,
    componentId,
    items
});

export const updateWorkspace = (width, height) => ({
    type: viewer.WORKSPACE,
    width,
    height
});