import * as viewer from "../constants/viewerConstants";
import {mm2px} from "../utils/conversion";
import {recomputeFrame} from "../utils/frame";
import {PLANOS_STATUS_SUCCESS} from "../constants/appConstants";
import Types from "../lib/Types";

import CommonPb from "@itrayl/api-dashboard-grpc/grpc/common_pb";

const initialState = {
    furnitures: {},
    levels: [],
    grid: null,
    planogram: null,
    furSn: null,
    components: {},
    mapping: {},
    workspace: {width: 0, height: 0},
    frame: {top: 0, left: 0, width: 0, height: 0, behavior: 'smooth'},
    ratio: 1,
    minRatio: 1,
    maxRatio: 1,
    isPlanoOvermap: false,
    mtime: (new Date()).getTime(),
    idle: false,
    dependencies: {
        "464950": {sku: "464950", image: '/uploads/hugo.png', croppedImage: '/uploads/hugo.png', height: 140, width: 70, depth: 30},
        "31854": {sku: "31854", image: '/uploads/hugo2.png', croppedImage: '/uploads/hugo2.png', height: 120, width: 70, depth: 35},
        "262762": {sku: "262762", image: '/uploads/hugo2.png', croppedImage: '/uploads/hugo2.png', height: 120, width: 70, depth: 35},
        "287808": {sku: "287808", image: '/uploads/hugo2.png', croppedImage: '/uploads/hugo2.png', height: 120, width: 70, depth: 35},
        "27731": {sku: "27731", image: '/uploads/hugomen.png', croppedImage: '/uploads/hugomen.png', height: 130, width: 65, depth: 65},
    },
};

const ratioReducer = (planogram, workspace) => {

    if (planogram === null) {
        return {};
    }

    const width = planogram.width;
    const height = planogram.height;
    const hRatio = mm2px(width) / workspace.width;
    const vRatio = mm2px(height) / workspace.height;
    const ratio = Math.max(hRatio, vRatio);

    return {
        minRatio: ratio / 10,
        maxRatio: ratio,
        hRatio,
        vRatio,
        ratio
    };
}

const reducePlanogram = (furSn, furniture) => {

    const isMakeup = ['Fur_test', 'Sephora Cosmetic', 'Suitcase'].indexOf(furSn) >= 0;

    const levels = furniture.shelfListList.map(shelf => shelf.dimensionMmList);
    let width = 0;
    let height = 0;
    levels.forEach(level => {
        width = Math.max(level[0], width);
        height += isMakeup ? level[1] : 300;
    });

    const component = {
        id: furSn,
        type: Types.PLANOGRAM,
        top: 0,
        left: 0,
        width,
        height,
        items: [],
        children: furniture.shelfListList.map(shelf => shelf.sn)
    };

    const components = {
        [furSn]: component
    };

    let top = 0;
    furniture.shelfListList
        .sort((shelf1, shelf2) => shelf2.floor - shelf1.floor)
        .forEach((shelf, idx) => {

            const [width, height] = shelf.dimensionMmList;

            components[shelf.sn] = {
                id: shelf.sn,
                name: `Floor n°${shelf.floor}`,
                reference: shelf.sn,
                zIndex: shelf.floor,
                type: isMakeup ? Types.SHELF : Types.SHELF3D,
                top: top,
                left: 0,
                width,
                height,
                acceptItems: {},
                items: [],
                children: []
            };

            top += height;
        });

    return components;
};

const reduceShelf = (data, shelf, mapping, components) => {

    const key = `${data.furSn}-${data.shelfSn}-${data.compName}`;

    const items = data.slotListList.map(slot => {
        let item = {
            quantity: 0,
            reference: null,
            productStatus: null
        };

        if (typeof slot.item === 'object') {
            item = {
                quantity: 1,
                reference: slot.item.ean,
                productStatus: slot.item.productStatus
            };
        }

        const [left, top] = slot.posMmList;
        return {
            ...item,
            id: `${data.parentCompName}-${slot.planoReference}`,
            type: Types.PRODUCT,
            expectedSku: slot.expectedEan,
            expectedType: slot.expectedType,
            component: slot.planoReference,
            compStatus: data.compStatus,
            slotStatus: slot.status,
            left,
            top
        };
    });

    if (typeof components[key] === 'undefined') {

        let module = mapping[data.compSupplierRef];
        const [left, top] = data.compPosList;
        const [width, height] = data.shelfDimensionMmList;

        components[key] = {
            ...module,
            id: key,
            compStatus: data.compStatus,
            top,
            left,
            items
        };
        components[data.shelfSn].children.push(key);
    } else {
        components[key].items = items;
    }
}

const reduceShelf3d = (data, shelf, mapping, dependencies, components) => {
    const [left, top] = data.compPosList;
    const [width, height] = data.shelfDimensionMmList;

    const items = data.slotListList
        .map(slot => {

            let item = {
                quantity: 1,
                reference: slot.expectedEan,
                productStatus: CommonPb.ProductStatus.NO_PRODUCT,
            };

            if (typeof slot.item === 'object') {
                item = {
                    quantity: 1,
                    reference: slot.item.ean,
                    productStatus: slot.item.productStatus
                };
            }

            let [slotLeft, slotTop] = slot.posMmList;
            const expectedSku = item.reference || slot.expectedEan;
            const product = dependencies[expectedSku];

            return {
                ...item,
                id: `${data.parentCompName}-${slot.planoReference}`,
                type: Types.PRODUCT,
                expectedSku: slot.expectedEan,
                expectedType: slot.expectedType,
                component: slot.planoReference,
                left: left - (product.width / 2),
                top: shelf.height - slotTop - (product.depth * 2),
                compStatus: data.compStatus,
                slotStatus: slot.status,
            };
        });

    shelf.items = shelf.items.concat(items);
}

const reduceMisplaced = (data, shelf, components, dependencies) => {

    const items = data.slotListList.map(slot => {
        if (typeof slot.item !== 'object') {
            return;
        }

        const product = dependencies[slot.item.ean];
        const [slotLeft, slotTop] = slot.posMmList;

        let item = {
            id: data.compName,
            reference: slot.item.ean,
            type: Types.PRODUCT,
            expectedSku: slot.expectedEan,
            expectedType: slot.expectedType,
            component: slot.planoReference,
            compStatus: data.compStatus,
            slotStatus: slot.status,
            productStatus: CommonPb.ProductStatus.MISPLACED,
            quantity: 1,
            left: slotLeft - (product.width / 2) + data.compPosList[0],
            top: shelf.height - slotTop - (product.depth * 2),
            width: product.width,
            height: product.height,
            depth: product.depth,
        };


        return item;
    });

    components[data.shelfSn].items = components[data.shelfSn].items.concat(items);
}

const viewerReducer = (state = initialState, action) => {
    let ratio;

    switch (action.type) {

        case viewer.INIT:

            const {furSn, furnitures, dependencies} = action;
            const furniture = furnitures[furSn];

            return {
                ...state,
                furSn,
                levels: [],
                furnitures,
                dependencies: {
                    ...state.dependencies,
                    ...dependencies
                },
                components: reducePlanogram(furSn, furniture)
            };

        case viewer.CLOSE:
            return initialState;

        case PLANOS_STATUS_SUCCESS:
            const {dataList} = action.planosStatus;
            console.log(dataList);

            const components = {
                ...state.components,
                ...state.mapping
            };

            for(let idx in components) {
                components[idx].items = [];
            }


            let isPlanoOvermap = false;
            dataList.forEach(data => {
                const shelf = components[data.shelfSn];
                if (/^misplaced/.test(data.compName)) {
                    reduceMisplaced(data, shelf, components, state.dependencies);
                }
                else if ((shelf.type & Types.SHELF3D) > 0) {
                    reduceShelf3d(data, shelf, state.mapping, state.dependencies, components);
                }
                else {
                    reduceShelf(data, shelf, state.mapping, components);
                }

                isPlanoOvermap = isPlanoOvermap || data.slotListList.some((slot) => typeof slot.planoReference === 'string' && /^over.+$/.test(slot.planoReference));
            });

            return {
                ...state,
                mtime: (new Date()).getTime(),
                isPlanoOvermap,
                components
            };

        case viewer.INIT_SUCCESS:
            return {
                ...state
            };

        case viewer.LOAD:
            const planogram = state.components[action.furSn];
            const width = planogram.width;
            const height = planogram.height;
            return {
                ...state,
                planogram: planogram,
                levels: action.levels,
                frame: {top: 0, left: 0, width, height},
                mapping: {
                    ...state.components,
                    ...action.components
                },
                ...ratioReducer(planogram, state.workspace)
            };

        case viewer.UPDATE:
            return {
                ...state,
                components: {
                    ...state.components,
                    [action.componentId]: {
                        ...state.components[action.componentId],
                        items: action.items
                    }
                }
            };

        case viewer.REFRAME:
            ratio = state.ratio;
            if (typeof action.ratio === 'number') {
                ratio = Math.max(state.minRatio, Math.min(state.maxRatio, state.ratio + action.ratio));
            }
            return {
                ...state,
                frame: recomputeFrame(state.planogram, action.frame, state.workspace, state.ratio, ratio, action.center),
                ratio
            };

        case viewer.ZOOM_IN:
            ratio = Math.max(state.minRatio, state.ratio - .2);
            return {
                ...state,
                frame: recomputeFrame(state.planogram, state.frame, state.workspace, state.ratio, ratio),
                ratio
            };

        case viewer.ZOOM_OUT:
            ratio = Math.min(state.maxRatio, state.ratio + .2);
            return {
                ...state,
                frame: recomputeFrame(state.planogram, state.frame, state.workspace, state.ratio, ratio),
                ratio
            };

        case viewer.ADAPT_H:
            ratio = state.hRatio;
            return {
                ...state,
                frame: {
                    top : 0,
                    left : 0,
                    width : state.planogram.width,
                    height : Math.min(state.planogram.height, state.planogram.height * state.hRatio / state.vRatio)
                },
                ratio
            };

        case viewer.ADAPT_V:
            ratio = state.vRatio;
            return {
                ...state,
                frame: {
                    top : 0,
                    left : 0,
                    width : Math.min(state.planogram.width, state.planogram.width * state.vRatio / state.hRatio),
                    height : state.planogram.height
                },
                ratio
            };

        case viewer.GRID:
            return {
                ...state,
                grid: action.grid
            };

        case viewer.WORKSPACE:
            const workspace = {
                width: action.width,
                height: action.height
            };

            return {
                ...state,
                workspace,
                ...ratioReducer(state.planogram, workspace)
            };

        default:
            return state
    }
}

export default viewerReducer;
