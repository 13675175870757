/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var apidashboardgrpc_common_pb = require('./common_pb.js')
goog.object.extend(proto, apidashboardgrpc_common_pb);
goog.exportSymbol('proto.apidashboardgrpc.GetShopsArg', null, global);
goog.exportSymbol('proto.apidashboardgrpc.InstructionCode', null, global);
goog.exportSymbol('proto.apidashboardgrpc.LastMomentum', null, global);
goog.exportSymbol('proto.apidashboardgrpc.MainMetrics', null, global);
goog.exportSymbol('proto.apidashboardgrpc.MetricsRet', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ProductAnalytics', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ShopInstruction', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ShopsAnalyticsRet', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ShopsInstructionsRet', null, global);
goog.exportSymbol('proto.apidashboardgrpc.SubMetrics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.LastMomentum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.LastMomentum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.LastMomentum.displayName = 'proto.apidashboardgrpc.LastMomentum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.GetShopsArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.GetShopsArg.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.GetShopsArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.GetShopsArg.displayName = 'proto.apidashboardgrpc.GetShopsArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ShopsAnalyticsRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.ShopsAnalyticsRet.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.ShopsAnalyticsRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ShopsAnalyticsRet.displayName = 'proto.apidashboardgrpc.ShopsAnalyticsRet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.MainMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.MainMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.MainMetrics.displayName = 'proto.apidashboardgrpc.MainMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.SubMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.SubMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.SubMetrics.displayName = 'proto.apidashboardgrpc.SubMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.MetricsRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.MetricsRet.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.MetricsRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.MetricsRet.displayName = 'proto.apidashboardgrpc.MetricsRet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ProductAnalytics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.ProductAnalytics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ProductAnalytics.displayName = 'proto.apidashboardgrpc.ProductAnalytics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ShopInstruction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.ShopInstruction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ShopInstruction.displayName = 'proto.apidashboardgrpc.ShopInstruction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ShopsInstructionsRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.ShopsInstructionsRet.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.ShopsInstructionsRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ShopsInstructionsRet.displayName = 'proto.apidashboardgrpc.ShopsInstructionsRet';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.LastMomentum.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.LastMomentum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.LastMomentum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.LastMomentum.toObject = function(includeInstance, msg) {
  var f, obj = {
    momentum: (f = msg.getMomentum()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.LastMomentum}
 */
proto.apidashboardgrpc.LastMomentum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.LastMomentum;
  return proto.apidashboardgrpc.LastMomentum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.LastMomentum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.LastMomentum}
 */
proto.apidashboardgrpc.LastMomentum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMomentum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.LastMomentum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.LastMomentum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.LastMomentum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.LastMomentum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMomentum();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp momentum = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.LastMomentum.prototype.getMomentum = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.LastMomentum.prototype.setMomentum = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.LastMomentum.prototype.clearMomentum = function() {
  this.setMomentum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.LastMomentum.prototype.hasMomentum = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.GetShopsArg.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.GetShopsArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.GetShopsArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetShopsArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastComparisonDate: (f = msg.getPastComparisonDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    targetDate: (f = msg.getTargetDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    apidashboardgrpc_common_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.GetShopsArg}
 */
proto.apidashboardgrpc.GetShopsArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.GetShopsArg;
  return proto.apidashboardgrpc.GetShopsArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.GetShopsArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.GetShopsArg}
 */
proto.apidashboardgrpc.GetShopsArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPastComparisonDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTargetDate(value);
      break;
    case 3:
      var value = new apidashboardgrpc_common_pb.Filter;
      reader.readMessage(value,apidashboardgrpc_common_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.GetShopsArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.GetShopsArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetShopsArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastComparisonDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTargetDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      apidashboardgrpc_common_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp past_comparison_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.getPastComparisonDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.GetShopsArg.prototype.setPastComparisonDate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.GetShopsArg.prototype.clearPastComparisonDate = function() {
  this.setPastComparisonDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.hasPastComparisonDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp target_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.getTargetDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.GetShopsArg.prototype.setTargetDate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.GetShopsArg.prototype.clearTargetDate = function() {
  this.setTargetDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.hasTargetDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Filter filters = 3;
 * @return {!Array<!proto.apidashboardgrpc.Filter>}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, apidashboardgrpc_common_pb.Filter, 3));
};


/** @param {!Array<!proto.apidashboardgrpc.Filter>} value */
proto.apidashboardgrpc.GetShopsArg.prototype.setFiltersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apidashboardgrpc.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Filter}
 */
proto.apidashboardgrpc.GetShopsArg.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apidashboardgrpc.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.GetShopsArg.prototype.clearFiltersList = function() {
  this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ShopsAnalyticsRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ShopsAnalyticsRet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricsList: jspb.Message.toObjectList(msg.getMetricsList(),
    proto.apidashboardgrpc.MainMetrics.toObject, includeInstance),
    productMetricsList: jspb.Message.toObjectList(msg.getProductMetricsList(),
    proto.apidashboardgrpc.ProductAnalytics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ShopsAnalyticsRet}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ShopsAnalyticsRet;
  return proto.apidashboardgrpc.ShopsAnalyticsRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ShopsAnalyticsRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ShopsAnalyticsRet}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.MainMetrics;
      reader.readMessage(value,proto.apidashboardgrpc.MainMetrics.deserializeBinaryFromReader);
      msg.addMetrics(value);
      break;
    case 2:
      var value = new proto.apidashboardgrpc.ProductAnalytics;
      reader.readMessage(value,proto.apidashboardgrpc.ProductAnalytics.deserializeBinaryFromReader);
      msg.addProductMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ShopsAnalyticsRet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ShopsAnalyticsRet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apidashboardgrpc.MainMetrics.serializeBinaryToWriter
    );
  }
  f = message.getProductMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.apidashboardgrpc.ProductAnalytics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MainMetrics metrics = 1;
 * @return {!Array<!proto.apidashboardgrpc.MainMetrics>}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.getMetricsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.MainMetrics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.MainMetrics, 1));
};


/** @param {!Array<!proto.apidashboardgrpc.MainMetrics>} value */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.setMetricsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apidashboardgrpc.MainMetrics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.MainMetrics}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.addMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apidashboardgrpc.MainMetrics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.clearMetricsList = function() {
  this.setMetricsList([]);
};


/**
 * repeated ProductAnalytics product_metrics = 2;
 * @return {!Array<!proto.apidashboardgrpc.ProductAnalytics>}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.getProductMetricsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.ProductAnalytics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.ProductAnalytics, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.ProductAnalytics>} value */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.setProductMetricsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.ProductAnalytics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.ProductAnalytics}
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.addProductMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.ProductAnalytics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ShopsAnalyticsRet.prototype.clearProductMetricsList = function() {
  this.setProductMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.MainMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.MainMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.MainMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.MainMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shelfStockPercent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outOfStockPercent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    riskOutOfStockPercent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    riskOverOfStockPercent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    firstFacingPercent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    planoCompliancyPercent: jspb.Message.getFieldWithDefault(msg, 7, 0),
    transformationRatePercent: jspb.Message.getFieldWithDefault(msg, 8, 0),
    shop: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.MainMetrics}
 */
proto.apidashboardgrpc.MainMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.MainMetrics;
  return proto.apidashboardgrpc.MainMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.MainMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.MainMetrics}
 */
proto.apidashboardgrpc.MainMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGlobalScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShelfStockPercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutOfStockPercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskOutOfStockPercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRiskOverOfStockPercent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirstFacingPercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanoCompliancyPercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTransformationRatePercent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.MainMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.MainMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.MainMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.MainMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalScore();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getShelfStockPercent();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOutOfStockPercent();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRiskOutOfStockPercent();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRiskOverOfStockPercent();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFirstFacingPercent();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPlanoCompliancyPercent();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTransformationRatePercent();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 global_score = 1;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getGlobalScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setGlobalScore = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 shelf_stock_percent = 2;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getShelfStockPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setShelfStockPercent = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 out_of_stock_percent = 3;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getOutOfStockPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setOutOfStockPercent = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 risk_out_of_stock_percent = 4;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getRiskOutOfStockPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setRiskOutOfStockPercent = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 risk_over_of_stock_percent = 5;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getRiskOverOfStockPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setRiskOverOfStockPercent = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 first_facing_percent = 6;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getFirstFacingPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setFirstFacingPercent = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 plano_compliancy_percent = 7;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getPlanoCompliancyPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setPlanoCompliancyPercent = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 transformation_rate_percent = 8;
 * @return {number}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getTransformationRatePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.MainMetrics.prototype.setTransformationRatePercent = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string shop = 9;
 * @return {string}
 */
proto.apidashboardgrpc.MainMetrics.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.MainMetrics.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.SubMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.SubMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.SubMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.SubMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    subgroupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metrics: (f = msg.getMetrics()) && proto.apidashboardgrpc.MainMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.SubMetrics}
 */
proto.apidashboardgrpc.SubMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.SubMetrics;
  return proto.apidashboardgrpc.SubMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.SubMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.SubMetrics}
 */
proto.apidashboardgrpc.SubMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubgroupName(value);
      break;
    case 3:
      var value = new proto.apidashboardgrpc.MainMetrics;
      reader.readMessage(value,proto.apidashboardgrpc.MainMetrics.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.SubMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.SubMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.SubMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.SubMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubgroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.apidashboardgrpc.MainMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subgroup_name = 1;
 * @return {string}
 */
proto.apidashboardgrpc.SubMetrics.prototype.getSubgroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.SubMetrics.prototype.setSubgroupName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MainMetrics metrics = 3;
 * @return {?proto.apidashboardgrpc.MainMetrics}
 */
proto.apidashboardgrpc.SubMetrics.prototype.getMetrics = function() {
  return /** @type{?proto.apidashboardgrpc.MainMetrics} */ (
    jspb.Message.getWrapperField(this, proto.apidashboardgrpc.MainMetrics, 3));
};


/** @param {?proto.apidashboardgrpc.MainMetrics|undefined} value */
proto.apidashboardgrpc.SubMetrics.prototype.setMetrics = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.SubMetrics.prototype.clearMetrics = function() {
  this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.SubMetrics.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.MetricsRet.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.MetricsRet.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.MetricsRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.MetricsRet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.MetricsRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupMetrics: (f = msg.getGroupMetrics()) && proto.apidashboardgrpc.MainMetrics.toObject(includeInstance, f),
    subgroupMetricsList: jspb.Message.toObjectList(msg.getSubgroupMetricsList(),
    proto.apidashboardgrpc.SubMetrics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.MetricsRet}
 */
proto.apidashboardgrpc.MetricsRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.MetricsRet;
  return proto.apidashboardgrpc.MetricsRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.MetricsRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.MetricsRet}
 */
proto.apidashboardgrpc.MetricsRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.MainMetrics;
      reader.readMessage(value,proto.apidashboardgrpc.MainMetrics.deserializeBinaryFromReader);
      msg.setGroupMetrics(value);
      break;
    case 2:
      var value = new proto.apidashboardgrpc.SubMetrics;
      reader.readMessage(value,proto.apidashboardgrpc.SubMetrics.deserializeBinaryFromReader);
      msg.addSubgroupMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.MetricsRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.MetricsRet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.MetricsRet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.MetricsRet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupMetrics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apidashboardgrpc.MainMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSubgroupMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.apidashboardgrpc.SubMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional MainMetrics group_metrics = 1;
 * @return {?proto.apidashboardgrpc.MainMetrics}
 */
proto.apidashboardgrpc.MetricsRet.prototype.getGroupMetrics = function() {
  return /** @type{?proto.apidashboardgrpc.MainMetrics} */ (
    jspb.Message.getWrapperField(this, proto.apidashboardgrpc.MainMetrics, 1));
};


/** @param {?proto.apidashboardgrpc.MainMetrics|undefined} value */
proto.apidashboardgrpc.MetricsRet.prototype.setGroupMetrics = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.MetricsRet.prototype.clearGroupMetrics = function() {
  this.setGroupMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.MetricsRet.prototype.hasGroupMetrics = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SubMetrics subgroup_metrics = 2;
 * @return {!Array<!proto.apidashboardgrpc.SubMetrics>}
 */
proto.apidashboardgrpc.MetricsRet.prototype.getSubgroupMetricsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.SubMetrics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.SubMetrics, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.SubMetrics>} value */
proto.apidashboardgrpc.MetricsRet.prototype.setSubgroupMetricsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.SubMetrics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.SubMetrics}
 */
proto.apidashboardgrpc.MetricsRet.prototype.addSubgroupMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.SubMetrics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.MetricsRet.prototype.clearSubgroupMetricsList = function() {
  this.setSubgroupMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ProductAnalytics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ProductAnalytics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ProductAnalytics.toObject = function(includeInstance, msg) {
  var f, obj = {
    furSn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ean: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    nbHits: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nbLongHits: jspb.Message.getFieldWithDefault(msg, 5, 0),
    holdTime: (f = msg.getHoldTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    nbHots: jspb.Message.getFieldWithDefault(msg, 7, 0),
    nbItems: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nbItemCapacity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    nbDiscards: jspb.Message.getFieldWithDefault(msg, 10, 0),
    testerStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nbTesterHits: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nbTesterTaps: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isRiskOutOfStock: jspb.Message.getFieldWithDefault(msg, 14, false),
    isRiskOverStock: jspb.Message.getFieldWithDefault(msg, 15, false),
    nbWeeksOfStockProvision: jspb.Message.getFieldWithDefault(msg, 16, 0),
    nbPlanogramOk: jspb.Message.getFieldWithDefault(msg, 17, 0),
    nbFacingOk: jspb.Message.getFieldWithDefault(msg, 19, 0),
    nbFacingCapacity: jspb.Message.getFieldWithDefault(msg, 20, 0),
    nbSold: jspb.Message.getFieldWithDefault(msg, 21, 0),
    nbSoldMiss: jspb.Message.getFieldWithDefault(msg, 22, 0),
    rateTransformationHoldPercent: jspb.Message.getFieldWithDefault(msg, 23, 0),
    rateTransformationTesterPercent: jspb.Message.getFieldWithDefault(msg, 24, 0),
    rateTransformationTapPercent: jspb.Message.getFieldWithDefault(msg, 25, 0),
    rateTransformationTmpredirectTapPercent: jspb.Message.getFieldWithDefault(msg, 26, 0),
    rateTransformationPromoPercent: jspb.Message.getFieldWithDefault(msg, 27, 0),
    rateTransformationSelfcheckoutPercent: jspb.Message.getFieldWithDefault(msg, 28, 0),
    rateTransformationRecommendationPercent: jspb.Message.getFieldWithDefault(msg, 29, 0),
    nbTaps: jspb.Message.getFieldWithDefault(msg, 31, 0),
    nbTmpredirectedTaps: jspb.Message.getFieldWithDefault(msg, 32, 0),
    shop: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ProductAnalytics}
 */
proto.apidashboardgrpc.ProductAnalytics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ProductAnalytics;
  return proto.apidashboardgrpc.ProductAnalytics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ProductAnalytics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ProductAnalytics}
 */
proto.apidashboardgrpc.ProductAnalytics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFurSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 3:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbHits(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbLongHits(value);
      break;
    case 6:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setHoldTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbHots(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbItems(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbItemCapacity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbDiscards(value);
      break;
    case 11:
      var value = /** @type {!proto.apidashboardgrpc.TesterStatus} */ (reader.readEnum());
      msg.setTesterStatus(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbTesterHits(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbTesterTaps(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRiskOutOfStock(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRiskOverStock(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbWeeksOfStockProvision(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbPlanogramOk(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbFacingOk(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbFacingCapacity(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbSold(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbSoldMiss(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationHoldPercent(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationTesterPercent(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationTapPercent(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationTmpredirectTapPercent(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationPromoPercent(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationSelfcheckoutPercent(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRateTransformationRecommendationPercent(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbTaps(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbTmpredirectedTaps(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ProductAnalytics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ProductAnalytics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ProductAnalytics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFurSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNbHits();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNbLongHits();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHoldTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getNbHots();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNbItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNbItemCapacity();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNbDiscards();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTesterStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getNbTesterHits();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getNbTesterTaps();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getIsRiskOutOfStock();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsRiskOverStock();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getNbWeeksOfStockProvision();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getNbPlanogramOk();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getNbFacingOk();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getNbFacingCapacity();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getNbSold();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getNbSoldMiss();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getRateTransformationHoldPercent();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getRateTransformationTesterPercent();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getRateTransformationTapPercent();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getRateTransformationTmpredirectTapPercent();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getRateTransformationPromoPercent();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getRateTransformationSelfcheckoutPercent();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getRateTransformationRecommendationPercent();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getNbTaps();
  if (f !== 0) {
    writer.writeInt32(
      31,
      f
    );
  }
  f = message.getNbTmpredirectedTaps();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * optional string fur_sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getFurSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setFurSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ean = 2;
 * @return {string}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ItemType type = 3;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 nb_hits = 4;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbHits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbHits = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 nb_long_hits = 5;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbLongHits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbLongHits = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Duration hold_time = 6;
 * @return {?proto.google.protobuf.Duration}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getHoldTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 6));
};


/** @param {?proto.google.protobuf.Duration|undefined} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setHoldTime = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.clearHoldTime = function() {
  this.setHoldTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.hasHoldTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 nb_hots = 7;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbHots = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbHots = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 nb_items = 8;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbItems = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 nb_item_capacity = 9;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbItemCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbItemCapacity = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 nb_discards = 10;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbDiscards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbDiscards = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional TesterStatus tester_status = 11;
 * @return {!proto.apidashboardgrpc.TesterStatus}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getTesterStatus = function() {
  return /** @type {!proto.apidashboardgrpc.TesterStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.apidashboardgrpc.TesterStatus} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setTesterStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 nb_tester_hits = 12;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbTesterHits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbTesterHits = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 nb_tester_taps = 13;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbTesterTaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbTesterTaps = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool is_risk_out_of_stock = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getIsRiskOutOfStock = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setIsRiskOutOfStock = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_risk_over_stock = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getIsRiskOverStock = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setIsRiskOverStock = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int32 nb_weeks_of_stock_provision = 16;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbWeeksOfStockProvision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbWeeksOfStockProvision = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 nb_planogram_ok = 17;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbPlanogramOk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbPlanogramOk = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 nb_facing_ok = 19;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbFacingOk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbFacingOk = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 nb_facing_capacity = 20;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbFacingCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbFacingCapacity = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 nb_sold = 21;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbSold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbSold = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 nb_sold_miss = 22;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbSoldMiss = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbSoldMiss = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 rate_transformation_hold_percent = 23;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationHoldPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationHoldPercent = function(value) {
  jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 rate_transformation_tester_percent = 24;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationTesterPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationTesterPercent = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 rate_transformation_tap_percent = 25;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationTapPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationTapPercent = function(value) {
  jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 rate_transformation_tmpredirect_tap_percent = 26;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationTmpredirectTapPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationTmpredirectTapPercent = function(value) {
  jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 rate_transformation_promo_percent = 27;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationPromoPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationPromoPercent = function(value) {
  jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 rate_transformation_selfcheckout_percent = 28;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationSelfcheckoutPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationSelfcheckoutPercent = function(value) {
  jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 rate_transformation_recommendation_percent = 29;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getRateTransformationRecommendationPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setRateTransformationRecommendationPercent = function(value) {
  jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int32 nb_taps = 31;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbTaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbTaps = function(value) {
  jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int32 nb_tmpredirected_taps = 32;
 * @return {number}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getNbTmpredirectedTaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setNbTmpredirectedTaps = function(value) {
  jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional string shop = 33;
 * @return {string}
 */
proto.apidashboardgrpc.ProductAnalytics.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductAnalytics.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ShopInstruction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ShopInstruction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopInstruction.toObject = function(includeInstance, msg) {
  var f, obj = {
    ean: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    furSn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instruction: jspb.Message.getFieldWithDefault(msg, 4, 0),
    shop: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ShopInstruction}
 */
proto.apidashboardgrpc.ShopInstruction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ShopInstruction;
  return proto.apidashboardgrpc.ShopInstruction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ShopInstruction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ShopInstruction}
 */
proto.apidashboardgrpc.ShopInstruction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 2:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFurSn(value);
      break;
    case 4:
      var value = /** @type {!proto.apidashboardgrpc.InstructionCode} */ (reader.readEnum());
      msg.setInstruction(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ShopInstruction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ShopInstruction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopInstruction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFurSn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstruction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string ean = 1;
 * @return {string}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ShopInstruction.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ItemType type = 2;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.getType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.ShopInstruction.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string fur_sn = 3;
 * @return {string}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.getFurSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ShopInstruction.prototype.setFurSn = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InstructionCode instruction = 4;
 * @return {!proto.apidashboardgrpc.InstructionCode}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.getInstruction = function() {
  return /** @type {!proto.apidashboardgrpc.InstructionCode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.apidashboardgrpc.InstructionCode} value */
proto.apidashboardgrpc.ShopInstruction.prototype.setInstruction = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string shop = 5;
 * @return {string}
 */
proto.apidashboardgrpc.ShopInstruction.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ShopInstruction.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.ShopsInstructionsRet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ShopsInstructionsRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ShopsInstructionsRet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopsInstructionsRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.apidashboardgrpc.ShopInstruction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ShopsInstructionsRet}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ShopsInstructionsRet;
  return proto.apidashboardgrpc.ShopsInstructionsRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ShopsInstructionsRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ShopsInstructionsRet}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.ShopInstruction;
      reader.readMessage(value,proto.apidashboardgrpc.ShopInstruction.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ShopsInstructionsRet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ShopsInstructionsRet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ShopsInstructionsRet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apidashboardgrpc.ShopInstruction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ShopInstruction data = 1;
 * @return {!Array<!proto.apidashboardgrpc.ShopInstruction>}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.getDataList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.ShopInstruction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.ShopInstruction, 1));
};


/** @param {!Array<!proto.apidashboardgrpc.ShopInstruction>} value */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apidashboardgrpc.ShopInstruction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.ShopInstruction}
 */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apidashboardgrpc.ShopInstruction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ShopsInstructionsRet.prototype.clearDataList = function() {
  this.setDataList([]);
};


/**
 * @enum {number}
 */
proto.apidashboardgrpc.InstructionCode = {
  OOS: 0,
  ROOS: 1,
  TESTER_MISSING: 2,
  TESTER_MISPLACED: 3,
  TESTER_TO_REPLACE: 4,
  TESTER_EMPTY: 5,
  FACING_MISSING: 6,
  WRONG_PLANO: 7,
  PRODUCT_MISPLACED: 8,
  LOW_TRANSFORMATION: 9
};

goog.object.extend(exports, proto.apidashboardgrpc);
