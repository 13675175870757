import {SlotStatus} from "@itrayl/api-dashboard-grpc/grpc/common_pb";

const statusList = [
    {level: 0, color: 'success', values: [SlotStatus.SLOT_OK], text: 'OK'},
    {level: 1, color: 'warning', values: [], text: 'Need Your attention'},
    {level: 2, color: 'error', values: [SlotStatus.RISK_OUT_OF_STOCK, SlotStatus.NO_FACING], text: 'Urgent'},
    {level: 3, color: 'danger', values: [SlotStatus.OUT_OF_STOCK], text: 'Out of stock'},
];

const getStatus = (slotStatus) => {

    for(let i in statusList) {
        if (statusList[i].values.indexOf(slotStatus) >= 0) {
            return statusList[i];
        }
    }

    return statusList[1];
};

export {statusList, getStatus};

