import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import defaultColumns from './columns';
import TableWrapper from "./TableWrapper";
import {Percentage} from "../Percentage";

const columns = [
    ...defaultColumns,
    {
        label: 'Transformation rate',
        align: 'left',
        format: row => <Percentage operand={row.rateTransformationHoldPercent} />,
        orderBy: row => row.rateTransformationHoldPercent.current
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    }
}));

const Table = ({shopsAnalytics}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Transformation rate
            </Typography>
            <TableWrapper rows={shopsAnalytics.products} columns={columns} defaultOrderBy={3} />
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shopsAnalytics: app.shopsAnalytics
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);