import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        margin: '1em'
    }
});

const BreadcrumbItem = ({item}) => {
    if (typeof item.href === 'string') {
        return (
            <Link underline="none" color="inherit" to={item.href}>
                {item.text}
            </Link>
        );
    }
    return (<Typography color="textPrimary">{item.text}</Typography>);
};

const Breadcrumb = ({items, ...rest}) => {
    const classes = useStyles();

    if (items.length === 0) {
        return null;
    }

    return (
        <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link underline="none" color="inherit" to="/">
                Dashboard
            </Link>
            {items.map((item, key) => <BreadcrumbItem key={key} item={item} />)}
        </Breadcrumbs>
    );
};

export default Breadcrumb;