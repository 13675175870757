import {Link, useHistory} from 'react-router-dom';

import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import ListItemText from "@material-ui/core/ListItemText";
import {SvgIcon} from "@material-ui/core";

import {ReactComponent as HomeIcon} from "../assets/icons/shiseido.svg";
import {ReactComponent as TopIcon} from "../assets/icons/top.svg";
import {ReactComponent as StoreCheckIcon} from "../assets/icons/storecheck.svg";
import {ReactComponent as PerformanceIcon} from "../assets/icons/performance.svg";
import {ReactComponent as SettingsIcon} from "../assets/icons/settings.svg";

import List from "@material-ui/core/List";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        color: 'white',
        height: '100%',
        '& .score' : {
            color: 'white',
        }
    },
    icon : {
        transform: 'scale(1.5)',
        '& > g': {
            fill: theme.palette.grey.A200,
        },
        '&.active > g': {
            fill: 'white'
        },
    },
    score: {
        marginLeft: '-.75em',
        marginBottom: '2em',
        color: 'white'
    },
    scoreOpen: {
        display: 'flex',
        flexDirection: 'column',
        '& > .MuiListItemIcon-root': {
            height: 100,
            '& .MuiTypography-h6': {
                marginRight: '5px'
            }
        },
        '& > .MuiListItemText-root > .MuiTypography-root': {
            marginTop: '-1em',
            textTransform: 'uppercase',
            fontWeight: '500',
            fontFamily: 'Avalon-demi'
        }
    },
    active: {
        borderLeft: '3px solid white',
        backgroundColor: theme.palette.grey[900]
    },
    item: {
        padding: '0.5rem 50px'
    }
}));

const list = [
    {href: '/', icon: HomeIcon, title: "Dashboard"},
    {href: '/top', icon: TopIcon, title: "Top"},
    {href: '/check', icon: StoreCheckIcon, title: "Store check"},
    {href: '/performance', icon: PerformanceIcon, title: "Performance"},
    {href: '/settings', icon: SettingsIcon, title: "Settings"},
];

const isActive = (href) => (href !== '/' &&  window.location.pathname.startsWith(href)) || (window.location.pathname === '/' && href === '/');

const Menu = ({open, globalScore}) => {
    const classes = useStyles();

    const history = useHistory();

    let [location, setLocation] = useState(history.location);

    useEffect(() => {
        return history.listen((location) => {
            setLocation(location);
        })
    },[location]);

    return (
        <List className={classes.root}>
            <ListItem
                className={clsx(classes.score, {
                    [classes.scoreOpen]: open
                })}
            >
                <ListItemIcon>
                    <CircularProgressWithLabel
                        value={globalScore}
                        size={open ? 140 : 48}
                        thickness={open ? 6 : 4}
                        fontSize={`${open ? 30 : 20}px`}
                        half={open}
                        suffix={open ? ' / 100' : '%'}
                    />
                </ListItemIcon>
                <span>
                    Store Score
                </span>
            </ListItem>

            {list.map(({href, title, icon, iconFontSize="large"}, idx) => (
                <ListItem key={idx} button component={Link} to={href}
                          className={clsx({
                              [classes.item]: open,
                              [classes.active]: isActive(href)
                          })}
                >
                    <ListItemIcon>
                        <SvgIcon component={icon}
                                 viewBox="0 0 50 50"
                                 fontSize={iconFontSize}
                                 className={clsx(classes.icon, {'active': isActive(href)})}
                        />
                    </ListItemIcon>
                    <ListItemText primary={title}/>
                </ListItem>
            ))}
        </List>
    );
};

const mapStateToProps = ({app}) => ({
    globalScore: app.shopsAnalytics.globalScore,
    shops: app.shops,
    filter: app.filter
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);