import {Grid, Typography} from "@material-ui/core";
import ProductImage from "./ProductImage";
import React from "react";

const ProductCard = ({sku, brand, model, gamme, quantityMl, image}) => (
    <Grid container spacing={3}>
        <Grid item>
            <ProductImage image={image} />
        </Grid>
        <Grid item>
            <Typography variant="h4">{sku}</Typography>
            <Typography variant="body1">{model} - {quantityMl}ml</Typography>
            <Typography variant="body1">{[gamme, brand].filter(text => text && text.length > 0).join(' - ')}</Typography>
        </Grid>
    </Grid>
);

export default ProductCard;