import {useEffect, useMemo, useRef} from "react";
import {connect} from "react-redux";
import ComponentFactory from "./ComponentFactory";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import ItemFactory from "./ItemFactory";

const SHELF_THICKNESS = 150;

const useStyles = (ratio) => makeStyles({
    root: {
        position: 'absolute',
        border: '1px solid #999999',
        transform: 'rotateX(70deg) rotateY(-10deg) rotateZ(26deg)',
        transformOrigin: 'center',
        backgroundColor: 'rgba(200, 200, 200, .2)',
        '&:before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: SHELF_THICKNESS / ratio,
            border: '2px solid #999999',
            backgroundColor: 'rgba(200, 200, 200, .2)',
            transform: `rotateX(20deg) rotateY(80deg) rotateZ(0deg) translateX(${SHELF_THICKNESS / ratio}px) scaleY(1.07)`,
            transformOrigin: 'right'
        },
        '&:after': {
            content: '" "',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            border: '2px solid #999999',
            backgroundColor: 'rgba(200, 200, 200, .2)',
            height: SHELF_THICKNESS / ratio,
            transform: 'rotateX(68deg) rotateY(4.5deg) rotateZ(169deg) scaleX(1.023)',
            transformOrigin: 'bottom'
        },
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
});

const useChange = (previousValue, value) => {
    previousValue = useMemo(() => value, [value]);
    return previousValue !== value;
};

const Shelf3d = ({node, ratio, mtime, ...rest}) => {

    const classes = useStyles(ratio)();

    const {items, children} = node;
    useChange(items, items);
    useChange(children, children);

    const rootRef = useRef();
    useEffect(() => {
        const style = {
            top: size(mm2px(node.top + node.height), ratio) + 'px',
            left: size(mm2px(node.left), ratio) + 'px',
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px',
            zIndex: node.zIndex,
        };

        const element = rootRef.current;

        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [node.top, node.left, node.width, node.height, node.zIndex, ratio]);

    useEffect(() => {

    }, [mtime]);

    return (
        <div className={classes.root} draggable="false" ref={rootRef} title={node.name}>
            <div className={classes.wrapper}>
                {children.map((childId, idx) => <ComponentFactory key={idx} componentId={childId} ratio={ratio} />)}
                {items.map((item, idx) => <ItemFactory key={`${node.id}_${idx}`} node={node} item={item} mapped={false} ratio={ratio} shelf3d={true} />)}
            </div>
        </div>
    );
};

const mapStateToProps = ({app, viewer}, ownProps) => ({
    ...ownProps,
    mtime: viewer.mtime,
    ratio: ownProps.ratio,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Shelf3d);