import React, {useState} from 'react';
import Divider from '@material-ui/core/Divider';
import {Menu, MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import {UPDATE_SHOP} from "../constants/appConstants";
import {LOGOUT} from "../constants/authConstants";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";

const HeaderMenu = ({shops, selectedShop, dispatch}) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const onToggleMenu = (open) => {
        setMenuOpen(open);
    };

    const onClick = (shop) => {
        dispatch({type: UPDATE_SHOP, shop});
        setMenuOpen(false);
    }

    const menuId = 'HeaderToolboxMenu'
    return (
        <>
            <IconButton
                ref={setAnchorEl}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={() => onToggleMenu(!isMenuOpen)}
                aria-haspopup="true"
                color="inherit"
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={isMenuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {Object.entries(shops).map(([shop, {name}]) => (
                    <MenuItem key={shop} onClick={(event) => onClick(shop)} selected={shop === selectedShop}>{name}</MenuItem>
                ))}
                <MenuItem><Divider /></MenuItem>
                <MenuItem onClick={(event) => dispatch({type: LOGOUT})}>Logout</MenuItem>
            </Menu>
        </>
    );
}

const mapStateToProps = ({app}, ownProps) => ({
    shops: app.shops,
    selectedShop: app.shop
});

export default connect(mapStateToProps, null)(HeaderMenu);