import {all, cancel, cancelled, fork, take} from 'redux-saga/effects'
import delay from '@redux-saga/delay-p';
import * as viewer from "../constants/viewerConstants";

function* evtTest() {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJib2FyZHMiOlsiY2IxIiwiY2IyIl0sImV4cCI6MTYxNDkzNzYwMywibmJmIjoxNjEyNTE4NDAzLCJ1aWRzIjpbImN1MSIsImN1MiIsImN1MyIsImN1NCJdLCJ1c2VybmFtZSI6WyJjZWxtZWNocmFmaSJdfQ._hkpGzokgXUmnsPgS9-Vz4_msJBK25KWzF-SUPhropI");
    myHeaders.append("Content-Type", "text/plain");

    const positions = [
        [13,295],
        [85,295],
        [13,246],
        [85,246],
        [13,196],
        [85,196],
        [13,147],
        [85,147],
        [8,86],
        [81,86],
    ];

    const products = ['cu1', 'cu2'];

    const [pos_x_mm, pos_y_mm] = positions[Math.floor(Math.random()*positions.length)];
    const uid = 'cu1'; // products[Math.floor(Math.random()*2)];

    var raw = JSON.stringify({
        "board_sn": "cb1",
        "is_in": true,
        uid,
        pos_x_mm,
        pos_y_mm,
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("/evttest/v1/move", requestOptions)
        .catch(error => console.log('error', error));
}

function* loop() {
    try {
        console.log('Start faker');
        while (true) {
           // yield evtTest();
            yield delay(5000);
        }
    } finally {
        if (yield cancelled()) {
            console.log('Stop faker');
        }
    }
}

function* start() {

    let furSn = null;
    let task = null;
    while ( {furSn} = yield take([viewer.OPEN, viewer.CLOSE]) ) {
        if (task !== null) {
            yield cancel(task);
            task = null;
        }

        if (furSn === 'Fur_test') {
            task = yield fork(loop);
        }
    }
}

function* fakerWatcher() {
    yield all([
        start(),
    ]);
}

export default fakerWatcher;

