import {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import ItemFactory from "./ItemFactory";
import {getStatus} from '../../lib/GetStatus';
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            '&:after': {
                content: '" "',
                position: 'absolute',
                bottom: 0,
                left: 0,
                backgroundColor: 'inherit',
                width: 'calc(100% + 2px)',
                height: '80px',
                transform: 'rotateX(69deg) rotateY(3deg) rotateZ(170deg) translateX(-9px) translateZ(-57px)',
            },
            '&.primary': {
                backgroundColor: theme.palette.primary.main,
            },
            '&.secondary': {
                backgroundColor: theme.palette.secondary.main,
            },
            '&.success': {
               // backgroundColor: theme.palette.success.main,
            },
            '&.info': {
                backgroundColor: theme.palette.info.main,
            },
            '&.error': {
                backgroundColor: theme.palette.error.main,
            },
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            }
        },
        wrapper: {
            position: 'relative',
            width: '100%',
            height: '100%'
        },
        image: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }
    };
});

const Range = ({node, ratio, mtime, ...rest}) => {

    const classes = useStyles(rest);

    const rootRef = useRef();
    useEffect(() => {
        const style = {
            top: size(mm2px(node.top), ratio) + 'px',
            left: size(mm2px(node.left), ratio) + 'px',
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px'
        };

        const element = rootRef.current;

        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [node.top, node.left, node.width, node.height, ratio]);

    useEffect(() => {}, [mtime]);

    const statusList = node.items
                            .map(item => getStatus(item.status))
                            .sort((status1, status2) => status1.level - status2.level);

    const className = statusList.length > 0 ? statusList[0].color : '';

    return (
        <div className={clsx(classes.root, className)} draggable="false" ref={rootRef} title={`${node.name} (#${node.reference})`}>
            <div className={classes.wrapper}>
                {node.items.map((item, idx) => <ItemFactory  key={`${node.id}_${idx}`} node={node} item={item} mapped={false} ratio={ratio} shelf3d={true} />)}
            </div>
        </div>
    );
};

const mapStateToProps = ({app, viewer}, ownProps) => ({
    ...ownProps,
    mtime: viewer.mtime,
    ratio: ownProps.ratio,
});

export default connect(mapStateToProps, null)(Range);