/**
 * @fileoverview gRPC-Web generated client stub for apidashboardgrpc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var apidashboardgrpc_common_pb = require('./common_pb.js')
const proto = {};
proto.apidashboardgrpc = require('./plano_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.PlanoClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.PlanoPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.OvermapArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plano_SetPlanoOvermap = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Plano/SetPlanoOvermap',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.OvermapArg,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.OvermapArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.OvermapArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Plano_SetPlanoOvermap = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.OvermapArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.OvermapArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.PlanoClient.prototype.setPlanoOvermap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Plano/SetPlanoOvermap',
      request,
      metadata || {},
      methodDescriptor_Plano_SetPlanoOvermap,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.OvermapArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.PlanoPromiseClient.prototype.setPlanoOvermap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Plano/SetPlanoOvermap',
      request,
      metadata || {},
      methodDescriptor_Plano_SetPlanoOvermap);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.OvermapArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plano_UnsetPlanoOvermap = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Plano/UnsetPlanoOvermap',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.OvermapArg,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.OvermapArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.OvermapArg,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Plano_UnsetPlanoOvermap = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.OvermapArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.OvermapArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.PlanoClient.prototype.unsetPlanoOvermap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Plano/UnsetPlanoOvermap',
      request,
      metadata || {},
      methodDescriptor_Plano_UnsetPlanoOvermap,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.OvermapArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.PlanoPromiseClient.prototype.unsetPlanoOvermap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Plano/UnsetPlanoOvermap',
      request,
      metadata || {},
      methodDescriptor_Plano_UnsetPlanoOvermap);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.PlanoComponents,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plano_SetPlanos = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Plano/SetPlanos',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.PlanoComponents,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.PlanoComponents} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.PlanoComponents,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Plano_SetPlanos = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.PlanoComponents} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.PlanoComponents} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.PlanoClient.prototype.setPlanos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Plano/SetPlanos',
      request,
      metadata || {},
      methodDescriptor_Plano_SetPlanos,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoComponents} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.PlanoPromiseClient.prototype.setPlanos =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Plano/SetPlanos',
      request,
      metadata || {},
      methodDescriptor_Plano_SetPlanos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.FurniturePlanos,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plano_AssignPlanosToFur = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Plano/AssignPlanosToFur',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.FurniturePlanos,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.FurniturePlanos} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.FurniturePlanos,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Plano_AssignPlanosToFur = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.apidashboardgrpc.FurniturePlanos} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.FurniturePlanos} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.PlanoClient.prototype.assignPlanosToFur =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Plano/AssignPlanosToFur',
      request,
      metadata || {},
      methodDescriptor_Plano_AssignPlanosToFur,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.FurniturePlanos} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.PlanoPromiseClient.prototype.assignPlanosToFur =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Plano/AssignPlanosToFur',
      request,
      metadata || {},
      methodDescriptor_Plano_AssignPlanosToFur);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.FurID,
 *   !proto.apidashboardgrpc.FurniturePlanos>}
 */
const methodDescriptor_Plano_GetPlanosByFur = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Plano/GetPlanosByFur',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.FurID,
  proto.apidashboardgrpc.FurniturePlanos,
  /**
   * @param {!proto.apidashboardgrpc.FurID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.FurniturePlanos.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.FurID,
 *   !proto.apidashboardgrpc.FurniturePlanos>}
 */
const methodInfo_Plano_GetPlanosByFur = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.FurniturePlanos,
  /**
   * @param {!proto.apidashboardgrpc.FurID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.FurniturePlanos.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.FurID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.FurniturePlanos)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.FurniturePlanos>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.PlanoClient.prototype.getPlanosByFur =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Plano/GetPlanosByFur',
      request,
      metadata || {},
      methodDescriptor_Plano_GetPlanosByFur,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.FurID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.FurniturePlanos>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.PlanoPromiseClient.prototype.getPlanosByFur =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Plano/GetPlanosByFur',
      request,
      metadata || {},
      methodDescriptor_Plano_GetPlanosByFur);
};


module.exports = proto.apidashboardgrpc;

