import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardActionArea, CardActions, CardContent, Grid, Typography} from '@material-ui/core';
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import {Variation} from "./Percentage";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
    },
    content: {
        textAlign: 'center'
    },
    media: {
        height: 140,
    },
    icon: {
        position: 'absolute',
        right: 10
    },
    link: {
        textDecoration: "none"
    },
    title: {
        margin: 0,
        marginBottom: '1em'
    },
    badge: {
        borderRadius: '1em',
        minWidth: '50px',
        padding: 0,
        fontWeight: 'bold',
        display: 'none',
        '& .MuiButton-startIcon': {
            margin: 0
        }
    },
    success: {
        display: 'inline-flex',
        backgroundColor: '#45eba5'
    },
    danger: {
        display: 'inline-flex',
        backgroundColor: '#f95959'
    },
    footer: {
        textDecoration: 'underline'
    }
});

const DashboardCard = ({title, operand, link, to, divider}) => {
    const classes = useStyles();

    return (
        <Link className={classes.link} to={to}>
            <Card className={classes.root} variant="outlined">
                <CardActionArea>
                    <CardContent className={classes.content}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography gutterBottom variant="h3" noWrap={true}>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Variation operand={operand} divider={divider}/>
                            </Grid>
                        </Grid>
                        <CircularProgressWithLabel value={operand.current}/>
                    </CardContent>
                    <CardActions>
                        <Typography className={classes.footer} gutterBottom variant="h6">
                            {link}
                        </Typography>
                    </CardActions>
                </CardActionArea>
            </Card>
        </Link>
    );
};

export default DashboardCard;