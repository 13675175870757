import React from 'react';
import {connect} from "react-redux";
import {Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';

import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import {history} from './configureStore';

import Breadcrumb from "./components/Breadcrumb";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SignIn from "./components/SignIn";

import DashboardView from "./views/DashboardView";
import StoreCheckView from "./views/StoreCheckView";
import TopView from "./views/TopView";
import PerformanceView from "./views/PerformanceView";
import FurnituresView from "./views/FurnituresView";
import PlanogramViewer from "./views/PlanogramViewer";

import theme from './theme';
import './App.css';
import clsx from "clsx";
import SettingsView from "./views/SettingsView";
import ManagePromotionView from "./views/ManagePromotionView";
import ManagePhoneAlertView from "./views/ManagePhoneAlertView";
import ManageRedirectUrlView from "./views/ManageRedirectUrlView";

const useStyles = makeStyles({
    body: {
        height: '100vh',
        backgroundColor: 'white',
        display: "flex",
        flexDirection: "column",
        flexWrap: 'wrap',
        fontFamily: 'Avalon',
        fontSize: '16px',
        fontWeight: '400'
    },
    main: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        marginTop: 64,
        borderTopLeftRadius: '1em',
        backgroundColor: '#FAFAFA'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    breadcrumb: {
        display: "none"
    },
    loading: {
        '&:after': {
            content: '" "',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, .3)'
        }
    },
    spinner: {
        display: "block",
        position: "absolute",
        top: '50%',
        left: '50%',
        zIndex: 1100
    },
    loaded: {
        display: "none"
    }
});

function App({breadcrumb, token, idle}) {

    const classes = useStyles();

    if (token === null) {
        return (
            <ThemeProvider theme={theme}>
                <SignIn/>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={clsx(classes.body, {[classes.loading]: !idle})}>
                <ConnectedRouter history={history}>
                    <Header />
                    <div className={classes.main}>
                        <CircularProgress color="secondary" className={clsx(classes.spinner, {[classes.loaded]: idle})} />
                        <div className={classes.breadcrumb}>
                            <Breadcrumb items={breadcrumb}/>
                        </div>
                        <Route exact path="/" component={DashboardView}/>
                        <Route path="/top" component={TopView}/>
                        <Route path="/check" component={StoreCheckView}/>
                        <Route path="/performance" component={PerformanceView}/>
                        <Route exact path="/furniture" component={FurnituresView}/>
                        <Route exact path="/furniture/:furSn" component={PlanogramViewer}/>
                        <Route exact path="/settings" component={SettingsView}/>
                        <Route exact path="/settings/promotion" component={ManagePromotionView}/>
                        <Route exact path="/settings/redirect_url" component={ManageRedirectUrlView}/>
                        <Route exact path="/settings/alert" component={ManagePhoneAlertView}/>
                    </div>
                    <Footer/>
                </ConnectedRouter>
            </div>
        </ThemeProvider>
    );
}

const mapStateToProps = ({app, auth}) => ({
    breadcrumb: app.breadcrumb,
    token: auth.token,
    idle: app.idle
});

export default connect(mapStateToProps, null)(App);
