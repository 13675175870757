import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {IconButton, SwipeableDrawer, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {mm2px} from "../../utils/conversion";
import Module from "./Module";

const useStyles = makeStyles({
    root: {
        transform: 'none',
        transformOrigin: 'top left',
        "& > .MuiPaper-root" : {
            width: '30%',
        }
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        height: '100%',
        width: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        width: '100%'
    },
    title: {
        width: '100%',
        padding: '0 15px'
    },
    module: {
        position: 'relative',
        padding: '1em',
        '& > div': {
            position: 'initial',
        }
    },
    body: {
        height: '100%'
    }
});

const Viewer = ({componentId, node, workspace, onOpen, onClose, ...rest}) => {

    const classes = useStyles();

    const [ratio, setRatio] = useState(1);

    useEffect(() => {
        if (node !== null) {
            const hRatio = (mm2px(node.width) / workspace.width);
            const vRatio = (mm2px(node.height) / workspace.height);
            setRatio(Math.max(hRatio, vRatio));
        }
    }, [workspace, componentId]);

    let module = null;
    let header = null;
    if (node !== null) {
        module = <Module node={{...node, top: 0, left: 0}} ratio={ratio} />;
        header = (
            <>
                <Typography component={"h2"} className={classes.title}>{node.name} (#{node.reference})</Typography>
            </>
        );
    }

    return (
        <SwipeableDrawer
            anchor="right"
            open={node !== null}
            onOpen={onOpen}
            onClose={onClose}
            className={classes.root}
        >
            <header className={classes.header}>
                {header}
                <IconButton onClick={onClose} size="medium"><CloseIcon /></IconButton>
            </header>
            <div className={classes.wrapper}>
                <div className={classes.module}>
                    {module}
                </div>
                <div className={classes.body}>

                </div>
            </div>
        </SwipeableDrawer>
    );
};

const mapStateToProps = ({viewer}, ownProps) => ({
    ...ownProps,
    node: viewer.components[ownProps.componentId] || null,
    workspace: viewer.workspace
});

export default connect(mapStateToProps, null)(Viewer);