/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var apidashboardgrpc_common_pb = require('./common_pb.js')
goog.object.extend(proto, apidashboardgrpc_common_pb);
goog.exportSymbol('proto.apidashboardgrpc.FurID', null, global);
goog.exportSymbol('proto.apidashboardgrpc.FurniturePlanos', null, global);
goog.exportSymbol('proto.apidashboardgrpc.OvermapArg', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanoComponent', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanoComponents', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanoReq', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanosByFloor', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.OvermapArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.OvermapArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.OvermapArg.displayName = 'proto.apidashboardgrpc.OvermapArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanoComponents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanoComponents.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanoComponents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanoComponents.displayName = 'proto.apidashboardgrpc.PlanoComponents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanoComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanoComponent.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanoComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanoComponent.displayName = 'proto.apidashboardgrpc.PlanoComponent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanoReq.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanoReq.displayName = 'proto.apidashboardgrpc.PlanoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.FurniturePlanos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.FurniturePlanos.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.FurniturePlanos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.FurniturePlanos.displayName = 'proto.apidashboardgrpc.FurniturePlanos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanosByFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanosByFloor.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanosByFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanosByFloor.displayName = 'proto.apidashboardgrpc.PlanosByFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.FurID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.FurID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.FurID.displayName = 'proto.apidashboardgrpc.FurID';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.OvermapArg.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.OvermapArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.OvermapArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.OvermapArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    furnitureSn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shop: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.OvermapArg}
 */
proto.apidashboardgrpc.OvermapArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.OvermapArg;
  return proto.apidashboardgrpc.OvermapArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.OvermapArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.OvermapArg}
 */
proto.apidashboardgrpc.OvermapArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFurnitureSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.OvermapArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.OvermapArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.OvermapArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.OvermapArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFurnitureSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string furniture_sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.OvermapArg.prototype.getFurnitureSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.OvermapArg.prototype.setFurnitureSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop = 2;
 * @return {string}
 */
proto.apidashboardgrpc.OvermapArg.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.OvermapArg.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanoComponents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanoComponents.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanoComponents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanoComponents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoComponents.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.apidashboardgrpc.PlanoComponent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanoComponents}
 */
proto.apidashboardgrpc.PlanoComponents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanoComponents;
  return proto.apidashboardgrpc.PlanoComponents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanoComponents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanoComponents}
 */
proto.apidashboardgrpc.PlanoComponents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.PlanoComponent;
      reader.readMessage(value,proto.apidashboardgrpc.PlanoComponent.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanoComponents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanoComponents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanoComponents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoComponents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apidashboardgrpc.PlanoComponent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanoComponent components = 1;
 * @return {!Array<!proto.apidashboardgrpc.PlanoComponent>}
 */
proto.apidashboardgrpc.PlanoComponents.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanoComponent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanoComponent, 1));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanoComponent>} value */
proto.apidashboardgrpc.PlanoComponents.prototype.setComponentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoComponent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanoComponent}
 */
proto.apidashboardgrpc.PlanoComponents.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apidashboardgrpc.PlanoComponent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoComponents.prototype.clearComponentsList = function() {
  this.setComponentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanoComponent.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanoComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanoComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planoReqsList: jspb.Message.toObjectList(msg.getPlanoReqsList(),
    proto.apidashboardgrpc.PlanoReq.toObject, includeInstance),
    isOverMap: jspb.Message.getFieldWithDefault(msg, 4, false),
    posMmList: jspb.Message.getRepeatedField(msg, 5)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanoComponent}
 */
proto.apidashboardgrpc.PlanoComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanoComponent;
  return proto.apidashboardgrpc.PlanoComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanoComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanoComponent}
 */
proto.apidashboardgrpc.PlanoComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierRef(value);
      break;
    case 3:
      var value = new proto.apidashboardgrpc.PlanoReq;
      reader.readMessage(value,proto.apidashboardgrpc.PlanoReq.deserializeBinaryFromReader);
      msg.addPlanoReqs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOverMap(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanoComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanoComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanoReqsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apidashboardgrpc.PlanoReq.serializeBinaryToWriter
    );
  }
  f = message.getIsOverMap();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoComponent.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_ref = 2;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.getSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoComponent.prototype.setSupplierRef = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PlanoReq plano_reqs = 3;
 * @return {!Array<!proto.apidashboardgrpc.PlanoReq>}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.getPlanoReqsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanoReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanoReq, 3));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanoReq>} value */
proto.apidashboardgrpc.PlanoComponent.prototype.setPlanoReqsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanoReq}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.addPlanoReqs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apidashboardgrpc.PlanoReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoComponent.prototype.clearPlanoReqsList = function() {
  this.setPlanoReqsList([]);
};


/**
 * optional bool is_over_map = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.getIsOverMap = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.apidashboardgrpc.PlanoComponent.prototype.setIsOverMap = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated int32 pos_mm = 5;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.PlanoComponent.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.PlanoComponent.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.PlanoComponent.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoComponent.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanoReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
    posMmList: jspb.Message.getRepeatedField(msg, 2),
    ean: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eanType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    eanComponentSupplierRef: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanoReq}
 */
proto.apidashboardgrpc.PlanoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanoReq;
  return proto.apidashboardgrpc.PlanoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanoReq}
 */
proto.apidashboardgrpc.PlanoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierRef(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 4:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setEanType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEanComponentSupplierRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEanType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getEanComponentSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string supplier_ref = 1;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoReq.prototype.getSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoReq.prototype.setSupplierRef = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 pos_mm = 2;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.PlanoReq.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.PlanoReq.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.PlanoReq.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoReq.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};


/**
 * optional string ean = 3;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoReq.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoReq.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ItemType ean_type = 4;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.PlanoReq.prototype.getEanType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.PlanoReq.prototype.setEanType = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string ean_component_supplier_ref = 5;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoReq.prototype.getEanComponentSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoReq.prototype.setEanComponentSupplierRef = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.FurniturePlanos.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.FurniturePlanos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.FurniturePlanos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.FurniturePlanos.toObject = function(includeInstance, msg) {
  var f, obj = {
    furSn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shop: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planosList: jspb.Message.toObjectList(msg.getPlanosList(),
    proto.apidashboardgrpc.PlanosByFloor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.FurniturePlanos}
 */
proto.apidashboardgrpc.FurniturePlanos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.FurniturePlanos;
  return proto.apidashboardgrpc.FurniturePlanos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.FurniturePlanos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.FurniturePlanos}
 */
proto.apidashboardgrpc.FurniturePlanos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFurSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 3:
      var value = new proto.apidashboardgrpc.PlanosByFloor;
      reader.readMessage(value,proto.apidashboardgrpc.PlanosByFloor.deserializeBinaryFromReader);
      msg.addPlanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.FurniturePlanos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.FurniturePlanos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.FurniturePlanos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFurSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apidashboardgrpc.PlanosByFloor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fur_sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.getFurSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.FurniturePlanos.prototype.setFurSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop = 2;
 * @return {string}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.FurniturePlanos.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PlanosByFloor planos = 3;
 * @return {!Array<!proto.apidashboardgrpc.PlanosByFloor>}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.getPlanosList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanosByFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanosByFloor, 3));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanosByFloor>} value */
proto.apidashboardgrpc.FurniturePlanos.prototype.setPlanosList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanosByFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanosByFloor}
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.addPlanos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apidashboardgrpc.PlanosByFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.FurniturePlanos.prototype.clearPlanosList = function() {
  this.setPlanosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanosByFloor.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanosByFloor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanosByFloor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanosByFloor.toObject = function(includeInstance, msg) {
  var f, obj = {
    floor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.apidashboardgrpc.PlanoComponent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanosByFloor}
 */
proto.apidashboardgrpc.PlanosByFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanosByFloor;
  return proto.apidashboardgrpc.PlanosByFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanosByFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanosByFloor}
 */
proto.apidashboardgrpc.PlanosByFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 2:
      var value = new proto.apidashboardgrpc.PlanoComponent;
      reader.readMessage(value,proto.apidashboardgrpc.PlanoComponent.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanosByFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanosByFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanosByFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.apidashboardgrpc.PlanoComponent.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 floor = 1;
 * @return {number}
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.PlanosByFloor.prototype.setFloor = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PlanoComponent components = 2;
 * @return {!Array<!proto.apidashboardgrpc.PlanoComponent>}
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanoComponent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanoComponent, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanoComponent>} value */
proto.apidashboardgrpc.PlanosByFloor.prototype.setComponentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoComponent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanoComponent}
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.PlanoComponent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanosByFloor.prototype.clearComponentsList = function() {
  this.setComponentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.FurID.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.FurID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.FurID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.FurID.toObject = function(includeInstance, msg) {
  var f, obj = {
    sn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shop: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.FurID}
 */
proto.apidashboardgrpc.FurID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.FurID;
  return proto.apidashboardgrpc.FurID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.FurID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.FurID}
 */
proto.apidashboardgrpc.FurID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.FurID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.FurID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.FurID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.FurID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.FurID.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.FurID.prototype.setSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop = 2;
 * @return {string}
 */
proto.apidashboardgrpc.FurID.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.FurID.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.apidashboardgrpc);
