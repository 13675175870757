import {connect} from "react-redux";
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import ComponentFactory from "./ComponentFactory";
import {motion, useMotionValue,} from "framer-motion";

import {makeStyles} from '@material-ui/core/styles';

import {updateWorkspace} from "../../actions/viewerAction";
import {mm2px, px2mm, size} from "../../utils/conversion";
import {REFRAME} from "../../constants/viewerConstants";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        padding: 15,
        width: '100%',
        height: '100%',
        userSelect: 'none',
        overflow: 'hidden'
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        maxWidth: 'calc(100vw - 78px)'
    }
});

const useScroll = (element, frame, ratio, reframe) => {
    let scrollTo = {
        top: size(mm2px(frame.top), ratio),
        left: size(mm2px(frame.left), ratio),
        behavior: frame.behavior
    };

    const move = useCallback((event) => {
        scrollTo = {
            top: px2mm(event.target.scrollTop) * ratio,
            left: px2mm(event.target.scrollLeft) * ratio,
            behavior: 'scroll',
        };
        reframe({...frame, ...scrollTo});
    }, [scrollTo]);

    const onScroll = (event) => {
        if (event.altKey || event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        move(event);
    };

    return [scrollTo, onScroll];
};

const useWheel = (wrapperRef, planogram, workspace, frame, ratio, reframe) => {

    let wheelDeltaY = 0;
    let wheelTimeout = 0;

    const onWheel = (event) => {
        if (!event.altKey && !event.shiftKey) {
            return;
        }

        if (wrapperRef.current === event.target) {
            return;
        }

        clearTimeout(wheelTimeout);

        if (Math.abs(event.deltaY) > Math.abs(wheelDeltaY)) {
            wheelDeltaY = event.deltaY;
        }

        const boundingRect = wrapperRef.current.firstElementChild.firstElementChild.getBoundingClientRect();

        const top = px2mm((event.clientY - boundingRect.y + wrapperRef.current.scrollTop) * ratio);
        const left = px2mm((event.clientX - boundingRect.x + wrapperRef.current.scrollLeft) * ratio);

        wheelTimeout = setTimeout(() => {
            reframe({...frame, behavior: 'wheel'}, -wheelDeltaY / 100, {top, left});
            wheelDeltaY = 0;
        }, 5);
    }

    return [onWheel];
};

const Workspace = ({componentId, node, frame, ratio, updateWorkspace, reframe}) => {

    const classes = useStyles();
    const wrapperRef = useRef();
    const rootRef = useRef();

    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const [dragConstraints, setDragConstraints] = useState({left: 0, right: 0, top: 0, bottom: 0});

    const onDragEnd = (event, info) => {
        const wrapper = wrapperRef.current;
        const element = wrapper.firstElementChild.firstElementChild;

        /** Frame Bounding Rect  */
        const fRect = element.getBoundingClientRect();

        /** Workspace Bounding Rect  */
        const wRect = wrapper.getBoundingClientRect();

        const newFrame = {
            top: Math.max(0, px2mm(wRect.top - fRect.top) * ratio),
            left: Math.max(0, px2mm(wRect.left - fRect.left) * ratio),
            width: px2mm(Math.min(wRect.right, fRect.right) - Math.max(wRect.left, fRect.left)) * ratio,
            height:px2mm(Math.min(wRect.bottom, fRect.bottom) - Math.max(wRect.top, fRect.top)) * ratio
        };

        reframe({
            ...newFrame,
            behavior: 'motion'
        });
    }

    // const [onWheel] = useWheel(wrapperRef, planogram, workspace, frame, ratio, reframe);

    useLayoutEffect(() => {
        const resize = () => {
            updateWorkspace(rootRef.current.clientWidth - 200, rootRef.current.clientHeight - 120);
        };

        window.addEventListener('resize', resize);
        resize();

        return function cleanup() {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        x.set(-size(mm2px(frame.left), ratio));
        y.set(-size(mm2px(frame.top), ratio));
    }, [frame, ratio]);

    useEffect(() => {
        const width = size(mm2px(node.width - frame.width), ratio) + 200;
        const height = size(mm2px(node.height - frame.height), ratio) + 200;
        setDragConstraints({
            left: -width * 2,
            right: width * 2,
            top: -height * 2,
            bottom: height * 2,
        });
    }, [ratio]);

    return (
        <div className={classes.root} ref={rootRef}>
            <motion.div className={classes.wrapper} ref={wrapperRef}>
                <motion.div
                    style={{x, y}}
                    size="100%"
                    drag animate={{}}
                    transition={false}
                    wheelEnabled={true}
                    dragConstraints={rootRef}
                    dragTransition={{min: 0, max: 100, timeConstant: 100}}
                    onDragEnd={onDragEnd}
                    onDragStart={(event) => event.stopPropagation()}
                >
                    <ComponentFactory componentId={componentId} ratio={ratio} />
                </motion.div>
            </motion.div>
        </div>
    );
};

const mapStateToProps = ({viewer}, ownProps) => {
    return {
        componentId: ownProps.componentId,
        node: viewer.components[ownProps.componentId],
        frame : viewer.frame,
        ratio : viewer.ratio,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateWorkspace: (width, height) => dispatch(updateWorkspace(width, height)),
    reframe: (frame, ratio = null, center = null) => dispatch({type: REFRAME, frame, center, ratio}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
