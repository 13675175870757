import {
    BREADCRUMB,
    FURNITURES_SUCCESS,
    GET_SHOPS_SUCCESS,
    INIT_FILTER,
    LAST_MOMENTUM_SUCCESS, LOAD,
    PRODUCTS_INFO_SUCCESS,
    READY,
    SHOP,
    SHOPS_ANALYTICS_SUCCESS,
    SHOPS_INSTRUCTIONS_SUCCESS,
    UPDATE_FILTER,
    UPDATE_SHOP,
} from "../constants/appConstants";

import Types from '../lib/Types';
import deps from '../assets/items.json';

const initialState = {
    breadcrumb: [],
    dashboard: {
        shelfStock: {percent: 0, diff: 0},
        outOfStock: {percent: 0, diff: 0},
        firstFacing: {percent: 0, diff: 0},
        planogramCompliancy: {percent: 0, diff: 0},
        riskOfOutOfStock: {percent: 0, diff: 0},
        riskOfOverStock: {percent: 0, diff: 0},
        transformationRate: {percent: 0, diff: 0},
    },
    shopsAnalytics: {
        products: []
    },
    shopsInstructions: [],
    labels: {
        gamme: {},
        brand: {},
        supplier: {},
    },
    products: {},
    filters: {},
    shop: null,
    shops: null,
    furnitures: {},
    furnituresContent: {},
    furnitureMove: {},
    filter: {
        gamme: null,
        duration: '1day',
    },
    dependencies: {
        ...deps.items
    },
    previousMomentum: 0,
    momentum: 0,
    ready: false,
    idle: false
};

function base64toBlob(dataURI) {

    var byteString = atob(dataURI);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                ready: false,
                idle: false
            };

        case READY:
            return {
                ...state,
                ready: true,
                idle: true
            };

        case BREADCRUMB:
            return {
                ...state,
                breadcrumb: action.items,
            };

        case SHOP:
        case UPDATE_SHOP:
            return {
                ...state,
                ready: false,
                previousMomentum: 0,
                momentum: 0,
                shop: action.shop
            };

        case INIT_FILTER:
        case UPDATE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                },
            };

        case LAST_MOMENTUM_SUCCESS:
            return {
                ...state,
                previousMomentum: state.momentum,
                momentum: action.momentum
            };

        case SHOPS_ANALYTICS_SUCCESS:
            return {
                ...state,
                shopsAnalytics: action.shopsAnalytics
            };

        case SHOPS_INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                shopsInstructions: action.shopsInstructions
            };

        case PRODUCTS_INFO_SUCCESS:

            let dependencies = state.dependencies;
            let labels = {
                gamme: {},
                brand: {},
                supplier: {},
            };

            for(let sku in action.products) {

                const product = action.products[sku];

                product.labelsList.forEach(label => {
                    const matches = /^(gamme|brand|supplier)::(.*)$/.exec(label);
                    if (matches !== null && typeof labels[matches[1]][label] === 'undefined') {
                        labels[matches[1]][label] = matches[2];
                    }
                });

                let {model, image, dimensionMmList} = product;

                if (image.length > 0) {
                    const blob = base64toBlob(image);
                    image = URL.createObjectURL(blob);
                }

                const [width, height, depth] = dimensionMmList;

                let item = {
                    sku,
                    name: model,
                    type: Types.PRODUCT,
                    color: '#ff0000',
                    shade: 'N/C',
                    croppedImage: image,
                    width,
                    height,
                    depth,
                    image,
                };

                if (typeof dependencies[sku] === 'object') {
                    item = {
                        ...item,
                        ...dependencies[sku],
                    };
                }

                if (typeof width === 'number' && typeof height === 'number' && typeof depth === 'number') {
                    item = {
                        ...item,
                        width,
                        height,
                        depth,
                    };
                }

                dependencies[sku] = {
                    ...item,
                    labels: product.labelsList
                };
            }

            return {
                ...state,
                labels,
                products: action.products,
                dependencies
            };

        case GET_SHOPS_SUCCESS:
            const shops = Object.keys(action.shops);
            const shop = state.shop !== null && shops.indexOf(state.shop) >= 0 ? state.shop : null; // filter
            return {
                ...state,
                shop,
                shops: action.shops
            };

        case FURNITURES_SUCCESS:
            const furnitures = action.furnitures;

            const images = {
                'Demo N': '/images/furniture/demo6lvl.png',
                'Demo M': '/images/furniture/demo6lvl.png',
                'Suitcase': '/images/furniture/suitcase.png',
                'Sephora Cosmetic': '/images/furniture/makeup.png',
                'Fur_test': '/images/furniture/makeup.png',
                'Instant': '/images/furniture/instant.png',
                'Gillette': '/images/furniture/gillette.png',
            };

            for(let furSn in furnitures) {
                furnitures[furSn].image = typeof images[furSn] === 'string' ? images[furSn] : 'https://fakeimg.pl/350x200/?text=Image&font=lobster';
            }

            return {
                ...state,
                furnitures
            };

        default:
            return state
    }
}

export default appReducer;
