import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = (color) => makeStyles((theme) => ({
    root: {
        padding: '.5em',
        color: theme.palette[color].main,
        backgroundColor: theme.palette[color].light,
        whiteSpace: "nowrap"
    }
}));

const Label = ({children, variant='caption', color='info'}) => {

    const classes = useStyles(color)();

    return (
        <Typography variant={variant} className={classes.root}>
            {children}
        </Typography>
    );
}

export default Label;