import {useEffect, useRef} from "react";
import {connect} from "react-redux";
import ComponentFactory from "./ComponentFactory";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import clsx from "clsx";
import {SlotStatus} from "../../lib/Status";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        '&.warning' : {
            border: `1px solid ${theme.palette.warning.main}`,
            boxShadow: `0px 0px 5px 0px ${theme.palette.warning.main}`,
            filter: `drop-shadow(0px 0px 5px ${theme.palette.warning.main})`
        },
        '&.error' : {
            border: `1px solid ${theme.palette.error.main}`,
            boxShadow: `0px 0px 5px 0px ${theme.palette.error.main}`,
            filter: `drop-shadow(0px 0px 5px ${theme.palette.error.main})`
        },
        '&.danger' : {
            border: `1px solid ${theme.palette.danger.main}`,
            boxShadow: `0px 0px 5px 0px ${theme.palette.danger.main}`,
            filter: `drop-shadow(0px 0px 5px ${theme.palette.danger.main})`
        }
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: "white"
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
}));

const Module = ({node, ratio, onOpen, ...rest}) => {

    const classes = useStyles(rest);

    const rootRef = useRef();
    useEffect(() => {
        const style = {
            top: size(mm2px(node.top), ratio) + 'px',
            left: size(mm2px(node.left), ratio) + 'px',
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px'
        };

        const element = rootRef.current;

        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [node.top, node.left, node.width, node.height, ratio]);


    const status = Math.max(...node.items.map(item => item.slotStatus));

    const color = status > 0 ? SlotStatus[status].color : '';

    return (
        <div className={clsx(classes.root, color)} draggable="false" ref={rootRef} title={`${node.name} (#${node.reference})`}
             onDoubleClickCapture={(event) => onOpen(node.id)}
        >
            <div className={classes.wrapper}>
                <img src={node.texture} className={classes.image} draggable="false" />
                {node.children.map(childId => <ComponentFactory key={childId} componentId={childId} ratio={ratio} items={node.items} acceptItems={node.acceptItems} />)}
                <img src={node.mask} className={classes.image} draggable="false" />
            </div>
        </div>
    );
};

const mapStateToProps = ({app}, ownProps) => ({
    ...ownProps,
    ratio: ownProps.ratio,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Module);