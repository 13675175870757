import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CastConnectedIcon from '@material-ui/icons/CastConnected';
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 400,
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
    icon: {
        position: 'absolute',
        right: 10
    }
});

export default function MediaCard({title, image, to}) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <Link to={to}>
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title={title}
                    />
                    <CardContent>
                        <CastConnectedIcon color="secondary" className={classes.icon}/>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Typography variant="body2" color="textSecondary" component="p">
                            + More info
                        </Typography>
                    </CardActions>
                </Link>
            </CardActionArea>
        </Card>
    );
}
