import {Field} from "redux-form";
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, TextField} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from "material-ui-phone-number";

export const InputText = ({input, label, meta, ...rest}) => <TextField
    label={label}
    placeholder={label}
    error={meta.touched && meta.invalid}
    helperText={meta.touched && meta.error}
    // icon={typeof icon === 'string' ? <Icon>{icon}</Icon> : null}
    autoComplete="false"
    {...input}
    {...rest}
/>;

export const InputCheckbox = ({input, label, value, meta, ...rest}) => (
    <FormControlLabel
        label={label}
        control={<Checkbox name={input.name} {...input} checked={input.value} required={false}/>}
        labelPlacement="end"
        {...rest}
    />
);

export const InputPhoneNumber = ({input, label, meta, ...rest}) => <MuiPhoneNumber
    defaultCountry={"fr"}
    label={label}
    placeholder={label}
    error={meta.touched && meta.invalid}
    helperText={meta.touched && meta.error}
    // icon={typeof icon === 'string' ? <Icon>{icon}</Icon> : null}
    autoComplete="false"
    {...input}
    {...rest}
/>;

const ChoiceMenuItem = ({value, item}) => <MenuItem key={value} value={value}>{item}</MenuItem>;

export const Choice = ({input, label, meta, required, multiple, placeholder, choices, menuItem=ChoiceMenuItem, ...rest}) => (
    <FormControl {...rest}>
        <Select label={label} required={required} multiple={multiple} {...input}>
            {!required ? <MenuItem value={null}>{placeholder || 'Select an item'}</MenuItem> : null}
            {Object.entries(choices).map(([value, item]) => menuItem({value, item}))}
        </Select>
    </FormControl>
);

export const InputField = ({name, type, ...rest}) => {
    return <Field name={name} type={type} {...rest} component={InputText}/>;
};

export const PhoneField = ({name, type, ...rest}) => {
    return <Field name={name} type={type} {...rest} component={InputPhoneNumber}/>;
};


export const CheckboxField = ({name, type, ...rest}) => {
    return <Field name={name} type={type} {...rest} component={InputCheckbox}/>;
};

export const SelectField = ({name, type, ...rest}) => {
    return <Field name={name} type={type} {...rest} component={Choice}/>;
};