import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, MenuItem,
    Typography,
} from "@material-ui/core";
import {connect} from "react-redux";
import TableWrapper from "./TableWrapper";
import defaultColumns from './columns';
import {Form, reduxForm} from "redux-form";
import {InputField, SelectField} from "../Form";
import ProductCard from "../ProductCard";
import * as grpc from "../../constants/gRpcConstants";
import {TOAST_CREATE} from "../../constants/toastConstants";


const columns = (onEdit, onReset) => [
    ...defaultColumns,
    // {
    //     label: 'Sale link',
    //     align: 'left',
    //     format: row => <Typography variant="body1">Sale link</Typography>,
    // },
    // {
    //     label: 'Status',
    //     align: 'left',
    //     format: row => <BoolBadge value={Math.random() > .5} trueText={"Active"} falseText={"Inactive"}
    //                               falseColor={"warning"}/>,
    // },
    {
        label: '',
        align: 'right',
        format: row => (
            <Box display={'flex'} gridGap={'1em'} justifyContent={'flex-end'}>
                <Button variant={"contained"} color={"primary"} size={"small"}
                        onClickCapture={onEdit(row)}>Edit</Button>
            </Box>
        ),
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    },
    dialogTitle: {
        fontSize: '1.6em'
    },
    table: {
        maxHeight: 440,
    },
    search: {
        width: 400,
        marginTop: '1em'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

const RedirectUrlTable = ({shop, shopsAnalytics, products, handleSubmit, submitting, initialize, setRedirectUrl}) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);

    const onEdit = row => () => {
        initialize({sku: row.ean, redirectUrl: null});
        setShow(true);
    };

    const onReset = ({sku}) => () => {
        setRedirectUrl(shop, sku, '', (response) => {}, (error) => {});
    };

    const onSubmit = ({sku, redirectUrl}) => {
        setRedirectUrl(shop, sku, redirectUrl, (response) => setShow(false), (error) => {});
    };

    const onClose = () => {
        setShow(false);
    };

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Manage Redirect URL
            </Typography>
            <TableWrapper rows={shopsAnalytics.products} columns={columns(onEdit, onReset)} defaultOrderBy={3}/>

            <Dialog
                open={show}
                onClose={onClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle disableTypography={true} className={classes.dialogTitle}>
                        New Redirect URL
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gridGap={'1em'}>
                            <SelectField
                                variant="outlined"
                                name="sku"
                                label="SKU"
                                fullWidth={true}
                                required={true}
                                choices={products}
                                menuItem={({value, item}) => <MenuItem
                                    value={value}><ProductCard {...item} /></MenuItem>}
                                autoFocus
                            />
                            <InputField
                                variant="outlined"
                                name="redirectUrl"
                                label="Redirect Url"
                                icon="link"
                                type="text"
                                fullWidth={true}
                                required={true}
                                autoFocus
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained" disabled={submitting}>
                            Update
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shop: app.shop,
    shopsAnalytics: app.shopsAnalytics,
    products: app.products
});

const mapDispatchToProps = (dispatch) => ({
    setRedirectUrl: (shop, sku, redirectUrl, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: grpc.GRPC_SET_REDIRECT_URL,
        payload: {shop, sku, redirectUrl},
        onSuccess: (response) => {
            dispatch({type: TOAST_CREATE, toastType: 'success', heading: 'Ok', message: `Redirect URL has been updated successfully on product SKU ${sku}`});
            onSuccess(response)
        },
        onFailure: (error) => {
            dispatch({type: TOAST_CREATE, toastType: 'error', heading: 'An error has occurred', message: `Unable to update Redirect URL on product SKU ${sku}`});
            onFailure(error)
        },
    })
});

const form = reduxForm({
    form: 'Promotion'
})(RedirectUrlTable);

export default connect(mapStateToProps, mapDispatchToProps)(form);