import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles({
    root: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg' : {
            strokeLinecap: 'round'
        }
    },
    success: {
        // color: '#16dd88',
        "& .MuiCircularProgress-colorPrimary": {
            color: '#16dd88',
        }
    },
    warning: {
        // color: '#f9a825',
        "& .MuiCircularProgress-colorPrimary": {
            color: '#f9a825',
        }
    },
    error: {
        // color: '#f95959',
        "& .MuiCircularProgress-colorPrimary": {
            color: '#f95959',
        }
    },
    text: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        fontFamily: "Avalon",
        margin: 0,
        padding: 0,
        fontWeight: 'bold',
        fontSize: '.8em',
        color: 'inherit'
    },
    progress: {
        position: 'absolute',
    },
    half: {
        transform: 'rotate(-190deg) !important',
        '& svg': {
            strokeLinecap: "butt",
        }
    },
    placeholder: {
        color: '#eeeeee',
    }
});

const CircularProgressWithLabel = ({value, size=100, thickness=3.6, fontSize='2em', half=false, suffix='%'}) => {
    const [percent, setPercent] = useState(value);

    const classes = useStyles();

    useEffect(() => {
        setTimeout(() => {setPercent(value)}, 500);
    }, [value]);

    const ratio = half ? 1.8 : 1;

    let status = 'success';
    if (value < 50) {
        status = 'error';
    }
    else if (value < 80) {
        status = 'warning';
    }

    if (isNaN(percent)) {
        return null;
    }

    return (
        <Box position="relative" className={clsx([classes.root, classes[status]])} style={{width: size, height: size, fontSize}}>
            <CircularProgress
                variant="determinate"
                size={size}
                color="inherit"
                value={100 / ratio}
                thickness={thickness}
                className={clsx([classes.progress, classes.placeholder], {
                    [classes.half]: half
                })}
            />
            <CircularProgress
                variant="determinate"
                size={size}
                value={percent / ratio}
                thickness={thickness}
                className={clsx([classes.progress], {
                    [classes.half]: half
                })}
            />
            <div className={"score"}>
                <Typography variant="h6" className={classes.text}>
                    {Math.round(percent)}
                </Typography>
                <span style={{fontSize: '.5em', display: 'inline-block'}}>{suffix}</span>
            </div>
        </Box>
    );
}

export default CircularProgressWithLabel;
