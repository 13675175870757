import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import DashboardCard from "./DashboardCard";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: '2em 0',
    },
    item: {
        flexGrow: 2
    }
});

const Dashboard = ({shopsAnalytics}) => {
    const classes = useStyles();

    const items = [
        {key: 'shelfStockPercent', title: 'Shelf stock', link: 'See details', to: '/top'},
        {key: 'outOfStockPercent', title: 'Out of stock', link: 'See details', to: '/check'},
    ];

    return (
        <Box className={classes.root}>
            <Typography variant="h2">Results Overview</Typography>
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
            >
                {[items.map(({key, ...item}) => (
                    <Grid key={key} item className={classes.item}>
                        {
                            typeof shopsAnalytics[key] === 'object' ?
                                <DashboardCard operand={shopsAnalytics[key]} {...item} /> :
                                <Skeleton variant="rect" height={120}/>
                        }
                    </Grid>
                ))]}
            </Grid>
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shopsAnalytics: app.shopsAnalytics,
    shop: app.shop,
    filter: app.filter
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
