/**
 * @fileoverview gRPC-Web generated client stub for apidashboardgrpc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var apidashboardgrpc_common_pb = require('./common_pb.js')
const proto = {};
proto.apidashboardgrpc = require('./analytic_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.AnalyticClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.apidashboardgrpc.AnalyticPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.ShopsAnalyticsRet>}
 */
const methodDescriptor_Analytic_GetShopsAnalytics = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Analytic/GetShopsAnalytics',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.GetShopsArg,
  proto.apidashboardgrpc.ShopsAnalyticsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ShopsAnalyticsRet.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.ShopsAnalyticsRet>}
 */
const methodInfo_Analytic_GetShopsAnalytics = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.ShopsAnalyticsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ShopsAnalyticsRet.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.ShopsAnalyticsRet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.ShopsAnalyticsRet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.AnalyticClient.prototype.getShopsAnalytics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetShopsAnalytics',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetShopsAnalytics,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.ShopsAnalyticsRet>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.AnalyticPromiseClient.prototype.getShopsAnalytics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetShopsAnalytics',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetShopsAnalytics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.ShopsInstructionsRet>}
 */
const methodDescriptor_Analytic_GetShopsInstructions = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Analytic/GetShopsInstructions',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.GetShopsArg,
  proto.apidashboardgrpc.ShopsInstructionsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ShopsInstructionsRet.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.ShopsInstructionsRet>}
 */
const methodInfo_Analytic_GetShopsInstructions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.ShopsInstructionsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.ShopsInstructionsRet.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.ShopsInstructionsRet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.ShopsInstructionsRet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.AnalyticClient.prototype.getShopsInstructions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetShopsInstructions',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetShopsInstructions,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.ShopsInstructionsRet>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.AnalyticPromiseClient.prototype.getShopsInstructions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetShopsInstructions',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetShopsInstructions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.MetricsRet>}
 */
const methodDescriptor_Analytic_GetMetrics = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Analytic/GetMetrics',
  grpc.web.MethodType.UNARY,
  proto.apidashboardgrpc.GetShopsArg,
  proto.apidashboardgrpc.MetricsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.MetricsRet.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.GetShopsArg,
 *   !proto.apidashboardgrpc.MetricsRet>}
 */
const methodInfo_Analytic_GetMetrics = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.MetricsRet,
  /**
   * @param {!proto.apidashboardgrpc.GetShopsArg} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.MetricsRet.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.MetricsRet)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.MetricsRet>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.AnalyticClient.prototype.getMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetMetrics',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetMetrics,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.GetShopsArg} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.MetricsRet>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.AnalyticPromiseClient.prototype.getMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetMetrics',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.apidashboardgrpc.Filters,
 *   !proto.apidashboardgrpc.LastMomentum>}
 */
const methodDescriptor_Analytic_GetLastMomentum = new grpc.web.MethodDescriptor(
  '/apidashboardgrpc.Analytic/GetLastMomentum',
  grpc.web.MethodType.UNARY,
  apidashboardgrpc_common_pb.Filters,
  proto.apidashboardgrpc.LastMomentum,
  /**
   * @param {!proto.apidashboardgrpc.Filters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.LastMomentum.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.apidashboardgrpc.Filters,
 *   !proto.apidashboardgrpc.LastMomentum>}
 */
const methodInfo_Analytic_GetLastMomentum = new grpc.web.AbstractClientBase.MethodInfo(
  proto.apidashboardgrpc.LastMomentum,
  /**
   * @param {!proto.apidashboardgrpc.Filters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.apidashboardgrpc.LastMomentum.deserializeBinary
);


/**
 * @param {!proto.apidashboardgrpc.Filters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.apidashboardgrpc.LastMomentum)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.apidashboardgrpc.LastMomentum>|undefined}
 *     The XHR Node Readable Stream
 */
proto.apidashboardgrpc.AnalyticClient.prototype.getLastMomentum =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetLastMomentum',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetLastMomentum,
      callback);
};


/**
 * @param {!proto.apidashboardgrpc.Filters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.apidashboardgrpc.LastMomentum>}
 *     A native promise that resolves to the response
 */
proto.apidashboardgrpc.AnalyticPromiseClient.prototype.getLastMomentum =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/apidashboardgrpc.Analytic/GetLastMomentum',
      request,
      metadata || {},
      methodDescriptor_Analytic_GetLastMomentum);
};


module.exports = proto.apidashboardgrpc;

