import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button} from '@material-ui/core';
import {connect} from "react-redux";
import {Choice} from "./Form";
import {UPDATE_FILTER} from "../constants/appConstants";

const useStyles = makeStyles({
    root: {
        display: 'none',
        justifyContent: 'space-between',
        padding: '1em',
        width: '100%'
    },
    gamme: {
        borderRadius: '1em',
        margin: '1em',
        padding: '0 1em',
        textTransform: 'capitalize',
        boxShadow: 'none',
    },
    badge: {
        height: '2em',
        borderRadius: '1em',
        textTransform: 'capitalize',
        margin: '0 1em',
        minWidth: '1em',
        '& > .MuiButton-label': {
            padding: '0 .2em',
        }
    }
});

const Filter = ({filter, gammes, updateFilter}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} m={1}>
            <span></span>
            <div>
                <Button key={'none'}
                    variant={null === filter.gamme ? 'contained' : 'text'}
                        size="small" onClick={(event) => updateFilter('gamme', null) }
                        color={null === filter.gamme ? 'secondary' : 'default'}

                        className={classes.badge}
                >
                    All
                </Button>
                {Object.entries(gammes).map(([key, value]) => (
                    <Button key={key} variant={key === filter.gamme ? 'contained' : 'text'} size="small"
                            color={key === filter.gamme ? 'secondary' : 'inherit'}
                            onClick={(event) => updateFilter('gamme', key) }
                            className={classes.badge}
                    >
                        {value}
                    </Button>
                ))}
            </div>
            <Choice required={true} label={null} name="duration"
                    input={{name: "duration", value: filter.duration, onChange: (event) => updateFilter('duration', event.target.value) }}
                    choices={{
                        '1day': 'Compare to yesterday',
                        '1week': 'Compare to one week ago',
                        '1month': 'Compare to one month ago'
                    }} />
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    dashboard: app.dashboard,
    filter: app.filter,
    gammes: app.labels.gamme
});

const mapDispatchToProps = (dispatch) => ({
    updateFilter: (key, value) => dispatch({type: UPDATE_FILTER, filter: {[key]: value}})
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);