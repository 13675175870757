import {connect} from "react-redux";

const Display = ({top, left, width, height, image, children, ratio, ...rest}) => {
    return null;
};

const mapStateToProps = ({app}, ownProps) => ({
    ...ownProps,
    ratio: ownProps.ratio
});

export default connect(mapStateToProps, null)(Display);