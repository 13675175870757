import {connect} from "react-redux";
import {Container, Grid} from '@material-ui/core';
import {useBreadcrumb, useWatch} from "../actions/appAction";
import MediaCard from "../components/MediaCard";
import {makeStyles} from "@material-ui/core/styles";
import * as app from "../constants/appConstants";

const useStyles = makeStyles({
    item: {
        width: 250
    }
});

const FurnituresView = ({furnitures, dispatch, ...rest}) => {

    const classes = useStyles();

    useBreadcrumb([
        {text: 'Furniture', href: '/furniture'},
    ]);

    useWatch([app.FURNITURES]);

    return (
        <Container fixed>
            <Grid container spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
            >
                {Object.entries(furnitures)
                    .map(([furSn, furniture]) =>
                        <Grid key={furSn} item className={classes.item}>
                            <MediaCard title={furniture.name} to={`/furniture/${furSn}`} image={furniture.image} />
                        </Grid>
                    )}
            </Grid>
        </Container>
    );
};

const mapStateToProps = ({app}, ownProps) => {
    return {
        furnitures: app.furnitures
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(FurnituresView);
