import {LIST_FURNITURE} from "../constants/appConstants";

const initialState = {
    list: {
        1: {id: 1, name: 'MIS 15 3 LEVELS',
            levels: [
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316},
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316},
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316}
            ]
        },
        2: {id: 2, name: 'MIS 15 4 LEVELS',
            levels: [
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316},
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316},
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316},
                {"width":153.5,"height":316,"margin":{"top":0,"left":6.5,"right":6.5,"bottom":0},"padding":{"top":0,"left":0,"right":0,"bottom":0},"offsetWidth":166.5,"offsetHeight":316}
            ]
        },
    },
};

const furnitureReducer = (state = initialState, action) => {
    switch (action.type) {

        case LIST_FURNITURE:
            return {
                ...state
            };

        default:
            return state
    }
}

export default furnitureReducer;
