import * as auth from '../constants/authConstants'
import * as gRpc from "../constants/gRpcConstants";

export const authLoginAction = (credentials) => ({
    type: gRpc.GRPC_CALL,
    actionType: auth.LOGIN,
    successType: auth.LOGIN_SUCCESS,
    failureType: auth.LOGIN_FAILURE,
    grpcType: gRpc.GRPC_AUTHENTICATION_CREATE_JWT,
    payload: {credentials}
});

export const authLogoutAction = () => ({
    type: auth.LOGOUT
});

export const authCreateJWTAction = (token) => ({
    type: auth.CREATE_JWT,
    token
});

export const authRenewJWTAction = (token) => ({
    type: gRpc.GRPC_CALL,
    actionType: auth.RENEW_JWT,
    successType: auth.UPDATE_JWT,
    failureType: auth.UNSET_JWT,
    grpcType: gRpc.GRPC_AUTHENTICATION_RENEW_JWT,
    payload: {token}
});

export const authUnsetJWTAction = () => ({
    type: auth.UNSET_JWT
});
