import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Badge, SvgIcon, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import HeaderMenu from "./HeaderMenu";
import {ReactComponent as AlarmIcon} from "../assets/icons/alarm.svg";
import {ReactComponent as SearchIcon} from "../assets/icons/loupe.svg";

const useStyles = makeStyles({
    grow: {
        flexGrow: 1,
        textAlign: 'center'
    }
});

const Toolbar = ({shops, shop}) => {
    const classes = useStyles();

    if (shop === null || shops === null) {
        return null;
    }

    return (
        <>
            <div className={classes.grow}>
                <Typography variant="h1" color="textPrimary">
                    {shops[shop].name}
                </Typography>
            </div>
            <div>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <SvgIcon viewBox="0 0 32 32"><SearchIcon /></SvgIcon>
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={0} color="error">
                        <SvgIcon viewBox="0 0 32 32"><AlarmIcon /></SvgIcon>
                    </Badge>
                </IconButton>
                <HeaderMenu />
            </div>
        </>
    );
}

const mapStateToProps = ({app}) => ({
    shop: app.shop,
    shops: app.shops,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);