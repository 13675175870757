import {Box} from '@material-ui/core';
import {useBreadcrumb} from "../actions/appAction";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Form, reduxForm} from "redux-form";
import {PhoneField} from "../components/Form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import * as grpc from "../constants/gRpcConstants";
import NoostifyPb from "@itrayl/api-dashboard-grpc/grpc/noostify_pb";
import {BoolBadge} from "../components/Badge";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0',
        '& .MuiFormControl-root' : {
            margin: '2em 0'
        },
        '& .MuiPhoneNumber-flagButton' : {
            height: 'auto'
        }
    },
    hide: {
        display: "none"
    }
}));

const ManagePhoneAlertView = ({shop, getPhoneNumber, setPhoneNumber, handleSubmit, submitting, initialize}) => {

    useBreadcrumb([
        {text: 'Settings', href: '/settings'},
    ]);

    const [idle, setIdle] = useState(false);
    const [active, setActive] = useState(false);

    const onLoad = ({phoneNumber, oosNotifierState}) => {
        initialize({phoneNumber});
        setActive(oosNotifierState === NoostifyPb.OosNotifierState.ENABLE);
        setIdle(true);
    };

    const onError = (error) => {
        setIdle(true);
    };

    useEffect(() => {
        setIdle(false);
        getPhoneNumber(shop, onLoad, onError);
    }, []);

    const onSubmit = ({phoneNumber}) => {
        phoneNumber = phoneNumber.replace(/\ /g, '');
        setPhoneNumber(shop, phoneNumber, (response) => {
            getPhoneNumber(shop, onLoad, onError);
        }, onError);
    };

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Manager Phone Number for Alerts
                <BoolBadge
                    value={active}
                    trueText={'active'}
                    falseText={'inactive'}
                />
            </Typography>
            <Box m={2} width={250}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <PhoneField
                        variant="outlined"
                        name="phoneNumber"
                        label="Phone Number"
                        icon="phone"
                        type="text"
                        fullWidth={true}
                        required={true}
                        autoFocus
                    />
                    <Box container
                         gridGap={'1em'}
                         display="flex"
                         direction="row"
                         justifyContent="space-between"
                         alignItems="center"
                    >
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={submitting}
                            className={clsx({
                                [classes.hide]: !active
                            })}
                            onClickCapture={(event) => onSubmit({phoneNumber: ''})}
                        >
                            Disable
                        </Button>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitting}
                        >
                            {!active ? 'Enable & Update' : 'Update'}
                        </Button>
                    </Box>

                </Form>
            </Box>
        </Box>
    );
};

const mapStateToProps = ({app}) => ({
    shop: app.shop
});

const mapDispatchToProps = (dispatch) => ({
    getPhoneNumber: (shop, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: grpc.GRPC_GET_OOS_NOTIFIER_INFO,
        payload: {shop},
        onSuccess,
        onFailure,
    }),

    setPhoneNumber: (shop, phoneNumber, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: phoneNumber.length > 0 ? grpc.GRPC_ENABLE_OOS_NOTIFIER_INFO : grpc.GRPC_DISABLE_OOS_NOTIFIER_INFO,
        payload: {shop, phoneNumber},
        onSuccess,
        onFailure,
    })
});

const form = reduxForm({
    form: 'ManagePhoneAlert'
})(ManagePhoneAlertView)

export default connect(mapStateToProps, mapDispatchToProps)(form);