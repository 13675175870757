import {useEffect, useMemo, useRef} from "react";
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import ItemFactory from "./ItemFactory";
import Types from "../../lib/Types";
import clsx from "clsx";
import {SlotStatus} from "../../lib/Status";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    status : {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0,
        borderRadius: '5px',
        zIndex: 100
    },
    info: {
        animation: 'flash-info 1s cubic-bezier(0.66, 0, 0, 1)',
        animationIterationCount: 1,
        opacity: 1,
    },
    danger: {
        animation: 'flash-danger 1s cubic-bezier(0.66, 0, 0, 1)',
        animationIterationCount: 1,
        opacity: 1,
    },
    tooltip: {
        position: 'absolute',
        zIndex: 1000,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        '& > span' : {
            width: '.5em',
            height: '.5em',
            borderRadius: '1em',
        },
        '&.warning > span' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.warning.main})`
        },
        '&.error > span' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.error.main})`
        },
        '&.danger > span' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.danger.main})`
        }
    }
}));


const useChange = (previousValue, value) => {
    previousValue = useMemo(() => { return sum(value); }, [value]);
    return sum(value) - previousValue;
};

const sum = (items) => items.map(item => item.quantity).reduce((a, b) => a + b, 0);

const Slot = ({node, items, ratio, ...rest}) => {

    const rootRef = useRef();

    const classes = useStyles();

    const quantity = useChange(items, items);

    useEffect(() => {
        const style = {
            top: size(mm2px(node.top), ratio) + 'px',
            left: size(mm2px(node.left), ratio) + 'px',
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px'
        };

        const element = rootRef.current;
        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [node.top, node.left, node.width, node.height, ratio]);

    const status = SlotStatus[node.slotStatus];

    return (
        <div className={classes.root} ref={rootRef}>
            <div className={classes.wrapper}>
                {items
                    .filter(item => parseInt(item.component) === node.id)
                    .map((item, idx) => {

                        const itemInfo = node.acceptItems[item.reference];

                        const mapped = typeof itemInfo === 'object';

                        if (mapped) {
                            item.croppedImage = itemInfo.image;
                        }

                        return <ItemFactory key={`${node.id}_${idx}`} node={node} item={item} mapped={mapped} ratio={ratio} />;
                    })}
            </div>
        </div>
    );
};

const mapStateToProps = ({app}, ownProps) => ({
    ...ownProps,
    ratio: ownProps.ratio,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Slot);