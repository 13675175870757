import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import TableWrapper from "./TableWrapper";
import defaultColumns from './columns';
import * as grpc from "../../constants/gRpcConstants";
import {TOAST_CREATE} from "../../constants/toastConstants";


const columns = (onToggle) => [
    ...defaultColumns,
    // {
    //     label: 'Status',
    //     align: 'left',
    //     format: row => <BoolBadge value={row.promo} trueText={"Active"} falseText={"Inactive"}
    //                               falseColor={"warning"}/>,
    // },
    {
        label: '',
        align: 'right',
        format: row => (
            <Button color="primary" variant={'outlined'} onClick={onToggle(row)}>Active</Button>
        ),
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    },
    dialogTitle: {
        fontSize: '1.6em'
    },
    table: {
        maxHeight: 440,
    },
    search: {
        width: 400,
        marginTop: '1em'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

const PromotionTable = ({shop, products, setPromo}) => {
    const classes = useStyles();

    const onToggle = ({sku, promo}) => () => {
        setPromo(shop, sku, true, (response) => {}, (error) => {});
    };

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                Manage Promotions
            </Typography>
            <TableWrapper rows={Object.values(products)} columns={columns(onToggle)} defaultOrderBy={3}/>
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shop: app.shop,
    products: app.products
});

const mapDispatchToProps = (dispatch) => ({
    setPromo: (shop, sku, active, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: grpc.GRPC_SET_PROMO,
        payload: {shop, sku, active},
        onSuccess: (response) => {
            dispatch({type: TOAST_CREATE, toastType: 'success', heading: 'Ok', message: `Promotion has been ${active ? 'enabled' : 'disabled'} successfully on product SKU ${sku}`});
            onSuccess(response)
        },
        onFailure: (error) => {
            dispatch({type: TOAST_CREATE, toastType: 'error', heading: 'An error has occurred', message: `Unable to ${active ? 'enable' : 'disable'} promotion on product SKU ${sku}`});
            onFailure(error)
        },
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionTable);