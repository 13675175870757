export const GRPC_CALL = 'GRPC_CALL';
export const GRPC_REQUEST = 'GRPC_REQUEST';
export const GRPC_SUCCESS = 'GRPC_SUCCESS';
export const GRPC_ERROR = 'GRPC_ERROR';

export const GRPC_AUTHENTICATION_CREATE_JWT = 1;
export const GRPC_AUTHENTICATION_RENEW_JWT = 2;
export const GRPC_GET_SHOPS = 5;
export const GRPC_ANALYTIC_LAST_MOMENTUM = 10;
export const GRPC_ANALYTIC_SHOPS_ANALYTICS = 11;
export const GRPC_ANALYTIC_SHOPS_INSTRUCTIONS = 12;
export const GRPC_ANALYTIC_METRICS = 13;
export const GRPC_MAP_FURNITURES = 20;
export const GRPC_MAP_PLANOS_STATUS = 21;
export const GRPC_PRODUCTS_INFO = 30;
export const GRPC_GET_OOS_NOTIFIER_INFO = 41;
export const GRPC_ENABLE_OOS_NOTIFIER_INFO = 42;
export const GRPC_DISABLE_OOS_NOTIFIER_INFO = 43;

export const GRPC_GET_PLANO_OVERMAP = 51;
export const GRPC_SET_PLANO_OVERMAP = 52;
export const GRPC_UNSET_PLANO_OVERMAP = 53;

export const GRPC_SET_REDIRECT_URL = 61;
export const GRPC_SET_PROMO = 71;