/**
 * Returns value size according to ratio
 * @param value
 * @param ratio
 * @returns {number}
 */
export const size = (value, ratio) => {
    return value / ratio;
};

/**
 * Convert mm to px (72 DPI)
 * @param value
 * @returns {number}
 */
export const mm2px = (value) => {
    return 72 * value / 25.4;
};

/**
 * Convert px to mm (72 DPI)
 * @param value
 * @returns {number}
 */
export const px2mm = (value) => {
    return value * 25.4 / 72;
};
