import {all, call, cancel, cancelled, fork, put, select, take, takeEvery} from 'redux-saga/effects';
import delay from '@redux-saga/delay-p';
import {push} from 'react-router-redux';
import * as auth from '../constants/authConstants';
import * as app from "../constants/appConstants";
import * as viewer from "../constants/viewerConstants";
import * as actions from "../actions/appAction";

let task = null;

function* momentum() {
    const {shop, furSn, gamme, duration} = yield select(state => ({
        shop: state.app.shop,
        furSn: state.viewer.furSn,
        ...state.app.filter
    }));

    yield put(actions.appLastMomentum(shop, furSn, gamme, duration));
}

function* watch({types}) {
    try {
        console.log('Start watch', types);
        while (true) {
            yield delay(500);

            const ready = select(state => state.app.idle);
            if (ready) {
                yield load(types);
            }
        }
    } finally {
        if (yield cancelled()) {
            console.log('Stop watch', types);
        }
    }
}

function* sync({types}) {

    if (task !== null) {
        yield cancel(task);
        task = null;
    }

    task = yield fork(watch, {types});
}

function* loadShops() {
    const shopListItem = localStorage.getItem('shops');
    if (shopListItem !== null) {
        const shops = JSON.parse(shopListItem);
        if (typeof shops === 'object') {
            yield put({type: app.GET_SHOPS_SUCCESS, shops});
        }
    } else {
        yield put(actions.appGetShops());
    }
}

function* loadShop() {

    const {shops} = yield select(state => ({
        shops: state.app.shops
    }));

    let shop = Object.keys(shops).shift();

    const shopItem = localStorage.getItem('shop');
    if (shopItem !== null) {
        const shopItemText = JSON.parse(shopItem);
        if (typeof shopItemText === 'string' && shopItemText in shops) {
            shop = shopItemText;
        }
    }

    yield put({type: app.SHOP, shop});
}

function* loadFilter() {
    const filterItem = localStorage.getItem('filter');
    if (filterItem !== null) {
        const filter = JSON.parse(filterItem);
        if (typeof filter === 'object') {
            yield put(actions.appInitFilter(filter));
        }
    }
}

function* init() {
    yield put({type: app.LOAD});
    yield* load(null, true);
    yield put({type: app.READY});
}

function* reload() {
    yield put({type: app.LAST_MOMENTUM_SUCCESS, momentum: 0});
    yield* load(null);
}

function* load(types, init=false) {

    const previousMomentum = yield select(state => state.app.momentum);

    if (init) {
        yield* grpcFetch(null);
        return;
    }

    yield* momentum();

    const {response} = yield take(app.LAST_MOMENTUM);

    if (typeof response !== "object") {
        return;
    }

    const nextMomentum = response.momentum;
    if (previousMomentum === 0 || nextMomentum > previousMomentum) {
        console.log(`New momentum ${nextMomentum}`);
        yield* grpcFetch(types);
    }
}

function* waitForViewerInit({furSn}) {
    const {ready} = yield select(state => ({
        ready: state.app.ready
    }));

    if (!ready) {
       yield take(app.READY);
    }

    const {dependencies, furnitures} = yield select(state => ({
        dependencies: state.app.dependencies,
        furnitures: state.app.furnitures
    }));

    if (typeof furnitures[furSn] === 'object') {
        yield delay(100);
        yield put({type: viewer.INIT, furSn, dependencies, furnitures});
    } else {
        yield put(push('/furniture'));
    }

}

function* grpcFetchProductsInfo({shop}) {
    yield put(actions.appProductsInfo(shop));
}

function* grpcFetchFurnitures({shop}) {
    yield put(actions.appFurnitures(shop));
}

function* grpcFetchPlanoStatus({shop, furSn}) {
    yield put(actions.appPlanosStatus(shop, furSn));
}

function* grpcFetchShopsInstructions({shop, gamme, duration}) {
    yield put(actions.appShopsInstructions(shop, gamme, duration));
}

function* grpcFetchShopsAnalytics({shop, gamme, duration}) {
    yield put(actions.appShopsAnalytics(shop, gamme, duration));
}

function* grpcFetchAction(type, payload) {
    switch (type) {
        case app.PRODUCTS_INFO:
            yield call(grpcFetchProductsInfo, payload);
            break;

        case app.SHOPS_INSTRUCTIONS:
            yield call(grpcFetchShopsInstructions, payload);
            break;

        case app.SHOPS_ANALYTICS:
            yield call(grpcFetchShopsAnalytics, payload);
            break;

        case app.FURNITURES:
            yield call(grpcFetchFurnitures, payload);
            break;

        case app.PLANOS_STATUS:
            yield call(grpcFetchPlanoStatus, payload);
            break;
    }

    yield take(type);
}

function* grpcFetch(types = null) {
    const payload = yield select(state => ({
        ...state.app.filter,
        shop: state.app.shop,
        furSn: state.viewer.furSn
    }));

    if (types === null) {
        types = [
            app.PRODUCTS_INFO,
            app.SHOPS_INSTRUCTIONS,
            app.SHOPS_ANALYTICS,
            app.FURNITURES,
        ];
    }

    yield all(types.map(type => call(grpcFetchAction, type, payload)));
}

function *storeShops({shops}) {
    const shop = yield select(state => state.app.shop);
    if (shop === null) {
        yield put({type: app.SHOP, shop: Object.keys(shops).shift()});
    }

    localStorage.setItem('shops', JSON.stringify(shops));
}

function *storeShop({shop}) {
    localStorage.setItem('shop', JSON.stringify(shop));
}

function *storeFilter({filter}) {
    localStorage.setItem('filter', JSON.stringify(filter));
}

function *clearLocalStorage() {
    localStorage.removeItem('filter');
    localStorage.removeItem('shop');
    localStorage.removeItem('shops');
}

function *homepage() {
    yield put(push('/'));
}

function* appWatcher() {
    yield all([
        takeEvery(auth.CREATE_JWT, loadShops),
        takeEvery(auth.CREATE_JWT, loadFilter),
        takeEvery(app.GET_SHOPS_SUCCESS, loadShop),

        takeEvery(auth.LOGOUT, clearLocalStorage),

        takeEvery(auth.LOGIN_SUCCESS, loadShops),
        takeEvery(auth.LOGIN_SUCCESS, loadFilter),

        takeEvery(app.SHOP, init),
        takeEvery(app.UPDATE_SHOP, init),
        takeEvery(app.UPDATE_SHOP, homepage),
        takeEvery(app.UPDATE_FILTER, reload),
        takeEvery(app.WATCH, sync),

        takeEvery(app.UPDATE_SHOP, storeShop),
        takeEvery(app.UPDATE_FILTER, storeFilter),

        takeEvery(viewer.OPEN, waitForViewerInit),
    ]);
}

export default appWatcher;

