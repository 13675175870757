/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var apidashboardgrpc_common_pb = require('./common_pb.js')
goog.object.extend(proto, apidashboardgrpc_common_pb);
goog.exportSymbol('proto.apidashboardgrpc.Furniture', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Furnitures', null, global);
goog.exportSymbol('proto.apidashboardgrpc.GetPlanosStatusArg', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Item', null, global);
goog.exportSymbol('proto.apidashboardgrpc.LocalizedItem', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanoSlot', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanoStatus', null, global);
goog.exportSymbol('proto.apidashboardgrpc.PlanosStatusRet', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Shelf', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Shops', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Furnitures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.Furnitures.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.Furnitures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Furnitures.displayName = 'proto.apidashboardgrpc.Furnitures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Furniture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.Furniture.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.Furniture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Furniture.displayName = 'proto.apidashboardgrpc.Furniture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Shelf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.Shelf.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.Shelf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Shelf.displayName = 'proto.apidashboardgrpc.Shelf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.LocalizedItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.LocalizedItem.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.LocalizedItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.LocalizedItem.displayName = 'proto.apidashboardgrpc.LocalizedItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Item.displayName = 'proto.apidashboardgrpc.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.GetPlanosStatusArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.GetPlanosStatusArg.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.GetPlanosStatusArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.GetPlanosStatusArg.displayName = 'proto.apidashboardgrpc.GetPlanosStatusArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanosStatusRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanosStatusRet.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanosStatusRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanosStatusRet.displayName = 'proto.apidashboardgrpc.PlanosStatusRet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanoStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanoStatus.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanoStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanoStatus.displayName = 'proto.apidashboardgrpc.PlanoStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.PlanoSlot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.PlanoSlot.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.PlanoSlot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.PlanoSlot.displayName = 'proto.apidashboardgrpc.PlanoSlot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Shops = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.Shops.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.Shops, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Shops.displayName = 'proto.apidashboardgrpc.Shops';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.Furnitures.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Furnitures.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Furnitures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Furnitures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Furnitures.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.apidashboardgrpc.Furniture.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Furnitures}
 */
proto.apidashboardgrpc.Furnitures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Furnitures;
  return proto.apidashboardgrpc.Furnitures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Furnitures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Furnitures}
 */
proto.apidashboardgrpc.Furnitures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.Furniture;
      reader.readMessage(value,proto.apidashboardgrpc.Furniture.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Furnitures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Furnitures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Furnitures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Furnitures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apidashboardgrpc.Furniture.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Furniture data = 1;
 * @return {!Array<!proto.apidashboardgrpc.Furniture>}
 */
proto.apidashboardgrpc.Furnitures.prototype.getDataList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Furniture>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.Furniture, 1));
};


/** @param {!Array<!proto.apidashboardgrpc.Furniture>} value */
proto.apidashboardgrpc.Furnitures.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apidashboardgrpc.Furniture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Furniture}
 */
proto.apidashboardgrpc.Furnitures.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apidashboardgrpc.Furniture, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Furnitures.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.Furniture.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Furniture.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Furniture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Furniture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Furniture.toObject = function(includeInstance, msg) {
  var f, obj = {
    shop: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    posMmList: jspb.Message.getRepeatedField(msg, 3),
    shelfListList: jspb.Message.toObjectList(msg.getShelfListList(),
    proto.apidashboardgrpc.Shelf.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Furniture}
 */
proto.apidashboardgrpc.Furniture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Furniture;
  return proto.apidashboardgrpc.Furniture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Furniture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Furniture}
 */
proto.apidashboardgrpc.Furniture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    case 4:
      var value = new proto.apidashboardgrpc.Shelf;
      reader.readMessage(value,proto.apidashboardgrpc.Shelf.deserializeBinaryFromReader);
      msg.addShelfList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Furniture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Furniture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Furniture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Furniture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getShelfListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.apidashboardgrpc.Shelf.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shop = 1;
 * @return {string}
 */
proto.apidashboardgrpc.Furniture.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Furniture.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sn = 2;
 * @return {string}
 */
proto.apidashboardgrpc.Furniture.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Furniture.prototype.setSn = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 pos_mm = 3;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.Furniture.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.Furniture.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.Furniture.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Furniture.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};


/**
 * repeated Shelf shelf_list = 4;
 * @return {!Array<!proto.apidashboardgrpc.Shelf>}
 */
proto.apidashboardgrpc.Furniture.prototype.getShelfListList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Shelf>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.Shelf, 4));
};


/** @param {!Array<!proto.apidashboardgrpc.Shelf>} value */
proto.apidashboardgrpc.Furniture.prototype.setShelfListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.apidashboardgrpc.Shelf=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Shelf}
 */
proto.apidashboardgrpc.Furniture.prototype.addShelfList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.apidashboardgrpc.Shelf, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Furniture.prototype.clearShelfListList = function() {
  this.setShelfListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.Shelf.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Shelf.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Shelf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Shelf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Shelf.toObject = function(includeInstance, msg) {
  var f, obj = {
    sn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    floor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    itemListList: jspb.Message.toObjectList(msg.getItemListList(),
    proto.apidashboardgrpc.LocalizedItem.toObject, includeInstance),
    posMmList: jspb.Message.getRepeatedField(msg, 4),
    dimensionMmList: jspb.Message.getRepeatedField(msg, 5)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Shelf}
 */
proto.apidashboardgrpc.Shelf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Shelf;
  return proto.apidashboardgrpc.Shelf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Shelf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Shelf}
 */
proto.apidashboardgrpc.Shelf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 3:
      var value = new proto.apidashboardgrpc.LocalizedItem;
      reader.readMessage(value,proto.apidashboardgrpc.LocalizedItem.deserializeBinaryFromReader);
      msg.addItemList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDimensionMmList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Shelf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Shelf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Shelf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Shelf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getItemListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apidashboardgrpc.LocalizedItem.serializeBinaryToWriter
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getDimensionMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional string sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.Shelf.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Shelf.prototype.setSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 floor = 2;
 * @return {number}
 */
proto.apidashboardgrpc.Shelf.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.Shelf.prototype.setFloor = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated LocalizedItem item_list = 3;
 * @return {!Array<!proto.apidashboardgrpc.LocalizedItem>}
 */
proto.apidashboardgrpc.Shelf.prototype.getItemListList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.LocalizedItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.LocalizedItem, 3));
};


/** @param {!Array<!proto.apidashboardgrpc.LocalizedItem>} value */
proto.apidashboardgrpc.Shelf.prototype.setItemListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apidashboardgrpc.LocalizedItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.LocalizedItem}
 */
proto.apidashboardgrpc.Shelf.prototype.addItemList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apidashboardgrpc.LocalizedItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Shelf.prototype.clearItemListList = function() {
  this.setItemListList([]);
};


/**
 * repeated int32 pos_mm = 4;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.Shelf.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.Shelf.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.Shelf.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Shelf.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};


/**
 * repeated int32 dimension_mm = 5;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.Shelf.prototype.getDimensionMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.Shelf.prototype.setDimensionMmList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.Shelf.prototype.addDimensionMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Shelf.prototype.clearDimensionMmList = function() {
  this.setDimensionMmList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.LocalizedItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.LocalizedItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.LocalizedItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.LocalizedItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.apidashboardgrpc.Item.toObject(includeInstance, f),
    posMmList: jspb.Message.getRepeatedField(msg, 2),
    isIn: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.LocalizedItem}
 */
proto.apidashboardgrpc.LocalizedItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.LocalizedItem;
  return proto.apidashboardgrpc.LocalizedItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.LocalizedItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.LocalizedItem}
 */
proto.apidashboardgrpc.LocalizedItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.Item;
      reader.readMessage(value,proto.apidashboardgrpc.Item.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.LocalizedItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.LocalizedItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.LocalizedItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apidashboardgrpc.Item.serializeBinaryToWriter
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getIsIn();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Item item = 1;
 * @return {?proto.apidashboardgrpc.Item}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.getItem = function() {
  return /** @type{?proto.apidashboardgrpc.Item} */ (
    jspb.Message.getWrapperField(this, proto.apidashboardgrpc.Item, 1));
};


/** @param {?proto.apidashboardgrpc.Item|undefined} value */
proto.apidashboardgrpc.LocalizedItem.prototype.setItem = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.LocalizedItem.prototype.clearItem = function() {
  this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int32 pos_mm = 2;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.LocalizedItem.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.LocalizedItem.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.LocalizedItem.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};


/**
 * optional bool is_in = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.apidashboardgrpc.LocalizedItem.prototype.getIsIn = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.apidashboardgrpc.LocalizedItem.prototype.setIsIn = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ean: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastChange: (f = msg.getLastChange()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Item}
 */
proto.apidashboardgrpc.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Item;
  return proto.apidashboardgrpc.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Item}
 */
proto.apidashboardgrpc.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 3:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLastChange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.apidashboardgrpc.Item.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Item.prototype.setUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ean = 2;
 * @return {string}
 */
proto.apidashboardgrpc.Item.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Item.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ItemType type = 3;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.Item.prototype.getType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.Item.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_change = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.Item.prototype.getLastChange = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.Item.prototype.setLastChange = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.Item.prototype.clearLastChange = function() {
  this.setLastChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.Item.prototype.hasLastChange = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.GetPlanosStatusArg.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.GetPlanosStatusArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.GetPlanosStatusArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetPlanosStatusArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    refDate: (f = msg.getRefDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    apidashboardgrpc_common_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.GetPlanosStatusArg}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.GetPlanosStatusArg;
  return proto.apidashboardgrpc.GetPlanosStatusArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.GetPlanosStatusArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.GetPlanosStatusArg}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefDate(value);
      break;
    case 2:
      var value = new apidashboardgrpc_common_pb.Filter;
      reader.readMessage(value,apidashboardgrpc_common_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.GetPlanosStatusArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.GetPlanosStatusArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetPlanosStatusArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      apidashboardgrpc_common_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp ref_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.getRefDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.setRefDate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.clearRefDate = function() {
  this.setRefDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.hasRefDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.apidashboardgrpc.Filter>}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, apidashboardgrpc_common_pb.Filter, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.Filter>} value */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.setFiltersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Filter}
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.GetPlanosStatusArg.prototype.clearFiltersList = function() {
  this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanosStatusRet.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanosStatusRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanosStatusRet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanosStatusRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastEvent: (f = msg.getLastEvent()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.apidashboardgrpc.PlanoStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanosStatusRet}
 */
proto.apidashboardgrpc.PlanosStatusRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanosStatusRet;
  return proto.apidashboardgrpc.PlanosStatusRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanosStatusRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanosStatusRet}
 */
proto.apidashboardgrpc.PlanosStatusRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEvent(value);
      break;
    case 2:
      var value = new proto.apidashboardgrpc.PlanoStatus;
      reader.readMessage(value,proto.apidashboardgrpc.PlanoStatus.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanosStatusRet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanosStatusRet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanosStatusRet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.apidashboardgrpc.PlanoStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_event = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.getLastEvent = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.PlanosStatusRet.prototype.setLastEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.clearLastEvent = function() {
  this.setLastEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.hasLastEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PlanoStatus data = 2;
 * @return {!Array<!proto.apidashboardgrpc.PlanoStatus>}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.getDataList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanoStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanoStatus, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanoStatus>} value */
proto.apidashboardgrpc.PlanosStatusRet.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanoStatus}
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.PlanoStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanosStatusRet.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanoStatus.repeatedFields_ = [6,9,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanoStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanoStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    furSn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shelfSn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shelfFloor: jspb.Message.getFieldWithDefault(msg, 3, 0),
    compName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parentCompName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    compPosList: jspb.Message.getRepeatedField(msg, 6),
    nbHot: jspb.Message.getFieldWithDefault(msg, 7, 0),
    compStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    slotListList: jspb.Message.toObjectList(msg.getSlotListList(),
    proto.apidashboardgrpc.PlanoSlot.toObject, includeInstance),
    shop: jspb.Message.getFieldWithDefault(msg, 10, ""),
    compSupplierRef: jspb.Message.getFieldWithDefault(msg, 11, ""),
    shelfDimensionMmList: jspb.Message.getRepeatedField(msg, 12)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanoStatus}
 */
proto.apidashboardgrpc.PlanoStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanoStatus;
  return proto.apidashboardgrpc.PlanoStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanoStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanoStatus}
 */
proto.apidashboardgrpc.PlanoStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFurSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShelfSn(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShelfFloor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentCompName(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCompPosList(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNbHot(value);
      break;
    case 8:
      var value = /** @type {!proto.apidashboardgrpc.SlotStatus} */ (reader.readEnum());
      msg.setCompStatus(value);
      break;
    case 9:
      var value = new proto.apidashboardgrpc.PlanoSlot;
      reader.readMessage(value,proto.apidashboardgrpc.PlanoSlot.deserializeBinaryFromReader);
      msg.addSlotList(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompSupplierRef(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setShelfDimensionMmList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanoStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanoStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFurSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShelfSn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShelfFloor();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParentCompName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompPosList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getNbHot();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCompStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSlotListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.apidashboardgrpc.PlanoSlot.serializeBinaryToWriter
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getShelfDimensionMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
};


/**
 * optional string fur_sn = 1;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getFurSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setFurSn = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shelf_sn = 2;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getShelfSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setShelfSn = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 shelf_floor = 3;
 * @return {number}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getShelfFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setShelfFloor = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string comp_name = 4;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getCompName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setCompName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string parent_comp_name = 5;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getParentCompName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setParentCompName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int32 comp_pos = 6;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getCompPosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setCompPosList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.PlanoStatus.prototype.addCompPos = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoStatus.prototype.clearCompPosList = function() {
  this.setCompPosList([]);
};


/**
 * optional int32 nb_hot = 7;
 * @return {number}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getNbHot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setNbHot = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional SlotStatus comp_status = 8;
 * @return {!proto.apidashboardgrpc.SlotStatus}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getCompStatus = function() {
  return /** @type {!proto.apidashboardgrpc.SlotStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.apidashboardgrpc.SlotStatus} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setCompStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated PlanoSlot slot_list = 9;
 * @return {!Array<!proto.apidashboardgrpc.PlanoSlot>}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getSlotListList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.PlanoSlot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apidashboardgrpc.PlanoSlot, 9));
};


/** @param {!Array<!proto.apidashboardgrpc.PlanoSlot>} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setSlotListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.apidashboardgrpc.PlanoSlot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.PlanoSlot}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.addSlotList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.apidashboardgrpc.PlanoSlot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoStatus.prototype.clearSlotListList = function() {
  this.setSlotListList([]);
};


/**
 * optional string shop = 10;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setShop = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string comp_supplier_ref = 11;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getCompSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setCompSupplierRef = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated int32 shelf_dimension_mm = 12;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.PlanoStatus.prototype.getShelfDimensionMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.PlanoStatus.prototype.setShelfDimensionMmList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.PlanoStatus.prototype.addShelfDimensionMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoStatus.prototype.clearShelfDimensionMmList = function() {
  this.setShelfDimensionMmList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.PlanoSlot.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.PlanoSlot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.PlanoSlot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoSlot.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.apidashboardgrpc.Item.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    posMmList: jspb.Message.getRepeatedField(msg, 3),
    planoReference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expectedEan: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expectedType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.PlanoSlot}
 */
proto.apidashboardgrpc.PlanoSlot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.PlanoSlot;
  return proto.apidashboardgrpc.PlanoSlot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.PlanoSlot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.PlanoSlot}
 */
proto.apidashboardgrpc.PlanoSlot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apidashboardgrpc.Item;
      reader.readMessage(value,proto.apidashboardgrpc.Item.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {!proto.apidashboardgrpc.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPosMmList(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanoReference(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpectedEan(value);
      break;
    case 6:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setExpectedType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.PlanoSlot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.PlanoSlot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.PlanoSlot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apidashboardgrpc.Item.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPosMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getPlanoReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpectedEan();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpectedType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional Item item = 1;
 * @return {?proto.apidashboardgrpc.Item}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getItem = function() {
  return /** @type{?proto.apidashboardgrpc.Item} */ (
    jspb.Message.getWrapperField(this, proto.apidashboardgrpc.Item, 1));
};


/** @param {?proto.apidashboardgrpc.Item|undefined} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setItem = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.PlanoSlot.prototype.clearItem = function() {
  this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductStatus status = 2;
 * @return {!proto.apidashboardgrpc.ProductStatus}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getStatus = function() {
  return /** @type {!proto.apidashboardgrpc.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.apidashboardgrpc.ProductStatus} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated int32 pos_mm = 3;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getPosMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setPosMmList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.PlanoSlot.prototype.addPosMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.PlanoSlot.prototype.clearPosMmList = function() {
  this.setPosMmList([]);
};


/**
 * optional string plano_reference = 4;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getPlanoReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setPlanoReference = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string expected_ean = 5;
 * @return {string}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getExpectedEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setExpectedEan = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ItemType expected_type = 6;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.PlanoSlot.prototype.getExpectedType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.PlanoSlot.prototype.setExpectedType = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.Shops.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Shops.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Shops.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Shops} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Shops.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopListList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Shops}
 */
proto.apidashboardgrpc.Shops.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Shops;
  return proto.apidashboardgrpc.Shops.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Shops} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Shops}
 */
proto.apidashboardgrpc.Shops.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addShopList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Shops.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Shops.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Shops} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Shops.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string shop_list = 1;
 * @return {!Array<string>}
 */
proto.apidashboardgrpc.Shops.prototype.getShopListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.apidashboardgrpc.Shops.prototype.setShopListList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.Shops.prototype.addShopList = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Shops.prototype.clearShopListList = function() {
  this.setShopListList([]);
};


goog.object.extend(exports, proto.apidashboardgrpc);
