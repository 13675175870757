import {all, takeEvery} from 'redux-saga/effects';
import {TOAST_CREATE} from '../constants/toastConstants';
import cogoToast from "cogo-toast";

function* createToast({ toastType, message, heading, position='top-center', hideAfter=5 }) {
    cogoToast[toastType](message, { position, hideAfter, heading });
}

function* toastWatcher() {
    yield all([takeEvery(TOAST_CREATE, createToast)]);
}

export default toastWatcher;
