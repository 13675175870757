import {makeStyles} from '@material-ui/core/styles';
import {Card, CardActionArea, CardContent, Typography} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        borderColor: theme.palette.background.black,
        width: 350,
        margin: '1em',
        '&:hover': {
            backgroundColor: theme.palette.background.black,
            color: 'white',
            '& svg > g': {
                fill: 'white'
            }
        }
    },
    icon: {
        margin: 10,
        fontSize: '5em',
        fontWeight: '100'
    },
    content: {
        textAlign: 'center'
    }
}));

const GridCardItem = ({name, icon, to}) => {

    const history = useHistory();
    const classes = useStyles();

    const onClick = () => {
        if (typeof to === 'string') {
            history.push(to);
        }
    };

    return (
        <Card variant="outlined" className={classes.root}>
            <CardActionArea onClick={onClick}>
                <CardContent className={classes.content}>
                    <Icon className={classes.icon} component={icon} />
                    <Typography gutterBottom variant="h5" component="h2">
                        {name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default GridCardItem;