import {Button} from "@material-ui/core";
import React from "react";

import CommonPb from "@itrayl/api-dashboard-grpc/grpc/common_pb";
import Label from "./Label";

export const BoolBadge = ({value, trueText = 'Yes', falseText = 'No', trueColor='success', falseColor='error'}) => {
    return (
        <Button variant="contained" className={`badge ${value ? trueColor : falseColor }`}>
            {value ? trueText : falseText}
        </Button>
    );
};

export const TesterBadge = (row) => {
    const testerStatus = {
        [CommonPb.TesterStatus.NA]: ['-', null, 'transparent'],
        [CommonPb.TesterStatus.TOREPLACE]: ['R', 'To replace', 'warning'],
        [CommonPb.TesterStatus.EMPTY]: ['E', 'Empty', 'error'],
        [CommonPb.TesterStatus.OK]: ['Ok', 'Ok', 'success'],
    };

    const [text, title, status] = testerStatus[row.testerStatus.current];

    return <Label color={status} title={title}>{text}</Label>;
};