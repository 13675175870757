import {useRef} from "react";
import {connect} from "react-redux";
import {Button, ButtonGroup, Grid} from '@material-ui/core';
import Draggble from 'react-draggable';

import {makeStyles} from '@material-ui/core/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CodeIcon from '@material-ui/icons/Code';
import {mm2px, px2mm, size} from "../utils/conversion";
import {ADAPT_H, ADAPT_V, REFRAME, ZOOM_IN, ZOOM_OUT} from "../constants/viewerConstants";

const useStyles = makeStyles({
    root: {
    },
    button: {
        padding: 0,
        width: '1em',
        minWidth: '2em'
    },
    workspace: {
        position: 'relative',
        backgroundColor: '#424242',
        border: '1px solid darkgray',
        borderRadius: '5px',
        width: 200,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    frame: {
        position: 'absolute',
        border: '2px solid #42a5f5',
        backgroundColor: 'rgba(66, 165, 245, 0.2)',
        borderRadius: '2px',
        transform: 'none',
        transformOrigin: 'top left'
    },
    planogram: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    rotate: {
        transform: 'rotate(90deg)'
    }
});

const Map = ({grid, workspace, frame, ratio, zoomIn, zoomOut, adaptH, adaptV, reframe, ...rest}) => {

    const mapRatio = 50;

    const onStop = (event) => {
        let transform = event.target.style.transform.match(/^translate\(([0-9\-]+)px,\ ([0-9\-]+)px\)$/);
        if (transform !== null) {
            reframe({
                ...frame,
                top: Math.max(0, frame.top + px2mm(parseInt(transform[2]) * mapRatio)),
                left: Math.max( 0, frame.left + px2mm(parseInt(transform[1]) * mapRatio)),
                behavior: 'zoom',
            });
            event.target.style.transform = 'none';
        }
    };

    const classes = useStyles();

    const nodeRef = useRef(null);

    if (grid === null) {
        return null;
    }

    const frameStyle = {
        top: size(mm2px(frame.top), mapRatio),
        left: size(mm2px(frame.left), mapRatio),
        width: size(mm2px(frame.width), mapRatio),
        height: size(mm2px(frame.height), mapRatio)
    };

    const planogramStyle = {
        backgroundImage: `URL("${grid.image}")`,
        width: size(mm2px(grid.width), mapRatio),
        height: size(mm2px(grid.height), mapRatio)
    };

    return (
        <div className={classes.root}>
            <Grid container alignItems="center">
                <Grid item>
                    <ButtonGroup size="small" orientation="vertical" variant="outlined" color="primary">
                        <Button aria-label="Vertical Adaptation" onClick={() => adaptV()} className={classes.button}>
                            <CodeIcon className={classes.rotate} />
                        </Button>
                        <Button aria-label="Horizontal Adaptation" onClick={() => adaptH()} className={classes.button}>
                            <CodeIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item>
                    <div className={classes.workspace}>
                        <div className={classes.planogram} style={planogramStyle}>
                            <Draggble nodeRef={nodeRef} bounds="parent" onStop={onStop}>
                                <div ref={nodeRef} className={classes.frame} style={frameStyle}></div>
                            </Draggble>
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <ButtonGroup size="small" orientation="vertical" variant="outlined" color="primary">
                        <Button aria-label="Zoom in" onClick={() => zoomIn(null)} className={classes.button}>
                            <ZoomInIcon />
                        </Button>
                        <Button aria-label="Zoom out" onClick={() => zoomOut(null)} className={classes.button}>
                            <ZoomOutIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({viewer}, ownProps) => ({
    ...ownProps,
    ...viewer
});

const mapDispatchToProps = (dispatch) => ({
    reframe: (frame, ratio = null, center = null) => dispatch({type: REFRAME, frame, ratio, center}),
    zoomIn: (position) => dispatch({type: ZOOM_IN, position}),
    zoomOut: (position) => dispatch({type: ZOOM_OUT, position}),
    adaptH: () => dispatch({type: ADAPT_H}),
    adaptV: () => dispatch({type: ADAPT_V}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);