import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import clsx from "clsx";
import {SlotStatus, ProductStatus} from '../../lib/Status';
import {Badge, Tooltip, Typography} from "@material-ui/core";
import CommonPb from "@itrayl/api-dashboard-grpc/grpc/common_pb";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute'
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    slot: {
        width: '100%',
        height: '100%',
        borderRadius: '1em',
        '&.success': {
            borderRadius: '50%',
            boxShadow: '0 0 10px 1px rgba(0,0,0,0.1) inset',
        },
        '&.warning': {
            borderRadius: '50%',
            boxShadow: `0 0 15px 3px ${theme.palette.warning.main} inset`,
        },
        '&.error': {
            borderRadius: '50%',
            boxShadow: `0 0 15px 3px ${theme.palette.error.main} inset`,
        },
        '&.danger': {
            borderRadius: '50%',
            boxShadow: `0 0 15px 3px ${theme.palette.danger.main} inset`,
        },
    },
    shade: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        '&.warning' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.warning.main})`
        },
        '&.error' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.error.main})`
        },
        '&.danger' : {
            filter: `drop-shadow(0px 1px 1px white) drop-shadow(0px 1px 5px ${theme.palette.danger.main})`
        }
    },
    image3d: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: 'rotateX(-42deg) rotateY(-35deg) rotateZ(-30deg) scaleX(1.25) scaleY(2.5)',
        transformOrigin: 'bottom',
        '&:hover': {
            filter: 'none'
        },
        '&.missing' : {
            opacity: .2
        },
        '&.success': {
            filter: 'drop-shadow(0 0 1px gray)'
        },
        '&.warning': {
            filter: `drop-shadow(0px 0 1px ${theme.palette.error.main}) drop-shadow(0px -5px 5px ${theme.palette.warning.main})`
        },
        '&.error': {
            filter: `drop-shadow(0px -4px 4px ${theme.palette.error.main})`
        },
        '&.danger': {
            filter: `drop-shadow(0px -4px 4px ${theme.palette.danger.main})`
        },
    },
}));

const TooltipTitle = ({item, product}) => {

    const compStatus = SlotStatus[item.compStatus];
    const slotStatus = SlotStatus[item.slotStatus];

    let list = [
        <Typography variant='h4'>{product.name} (EAN: {product.sku})</Typography>,
        <Typography variant='h5' color={slotStatus.color} className={slotStatus.color}>{slotStatus.title}</Typography>,
    ];

    if (item.expectedSku && item.expectedSku !== product.sku) {
        list.push(<Typography variant='h6' color={slotStatus.color} className={slotStatus.color}>Expected EAN {item.expectedSku}</Typography>);
    }

    if (item.compStatus !== CommonPb.SlotStatus.SLOT_OK) {
        list.push(<Typography variant='h5' color={compStatus.color} className={compStatus.color}>{compStatus.text}</Typography>);
    }

    list.push(<Typography variant='h5' color={slotStatus.color} className={slotStatus.color}>{slotStatus.text}</Typography>);

    return list;
};

const Product = ({node, item, expectedItem, product, ratio, shelf3d = false, ...rest}) => {
    const classes = useStyles();

    if (item.quantity <= 0) {
        return null;
    }

    const render2d = (croppedImage) => {
        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Tooltip className={classes.tooltip} title={<TooltipTitle item={item} product={product} />} arrow>
                        <img src={`${croppedImage}`} className={clsx(classes.image, SlotStatus[item.slotStatus].color)} alt={""}/>
                    </Tooltip>
                </div>
            </div>
        );
    };

    const render3d = () => {
        const {top, left} = item;
        const {width, height, depth, croppedImage} = product;

        const imageStyle = {
            bottom: size(mm2px(width / 2), ratio) + 'px',
            width: size(mm2px(width), ratio) + 'px',
            height: size(mm2px(height), ratio) + 'px'
        };

        let slotStyle = {
            zIndex: Math.ceil(top),
            top: size(mm2px(top + depth), ratio) + 'px',
            left: size(mm2px(left), ratio) + 'px',
            width: size(mm2px(width), ratio) + 'px',
            height: size(mm2px(width), ratio) + 'px'
        };

        return (
            <div className={classes.root} style={slotStyle} draggable="false">
                <div className={classes.wrapper}>
                    <Tooltip className={classes.tooltip} title={<TooltipTitle item={item} product={product} />} arrow>
                        <img src={`${croppedImage}`} style={imageStyle} alt={""}
                             className={clsx(classes.image3d, SlotStatus[item.slotStatus].color, {
                                 misplaced: item.productStatus === CommonPb.ProductStatus.MISPLACED,
                                 missing: item.productStatus === CommonPb.ProductStatus.NO_PRODUCT,
                             })}
                        />
                    </Tooltip>
                    <div className={clsx(classes.slot, SlotStatus[item.compStatus].color)} />
                </div>
            </div>
        );
    };

    const renderShade = () => {
        const {color, shade} = product;

        if (color === null) {
            return null;
        }

        return (
            <div className={clsx(classes.shade, SlotStatus[item.slotStatus].color)} style={{backgroundColor: color, fontSize: size(12, ratio)}}>
                {shade}
            </div>
        );
    };

    if (shelf3d) {
        return render3d();
    }

    if (item.productStatus === CommonPb.ProductStatus.MISPLACED) {

        if (typeof product !== 'object') {
            return null;
        }

        const {image} = product;
        const {top, left} = item;

        const slotStyle = {
            top: size(mm2px(top), ratio) + 'px',
            left: size(mm2px(left), ratio) + 'px',
            width: '30px'
        };

        return (
            <div className={clsx(classes.root, classes.misplaced)} style={slotStyle}>
                <img src={`${image}`} className={classes.image} alt={""}/>
            </div>
        );
    }

    if (typeof node.acceptItems[item.reference] === 'object') {
        const {image} = node.acceptItems[item.reference];

        if (typeof image === 'string') {
            return render2d(image);
        }
    }

    if (typeof product === 'object') {
        return renderShade();
    }

    return null;
};

const mapStateToProps = ({app, viewer}, ownProps) => {
    const {item, node} = ownProps;
    const sku = item.reference || item.expectedSku;
    const product = viewer.dependencies[sku];
    const expectedItem = viewer.dependencies[sku];

    return {
        node,
        item,
        product,
        expectedItem,
        ratio: ownProps.ratio
    };
};

export default connect(mapStateToProps, null)(Product);