/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var apidashboardgrpc_common_pb = require('./common_pb.js')
goog.object.extend(proto, apidashboardgrpc_common_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.apidashboardgrpc.GetInfoArg', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Image', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ItemsReference', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ItemsURL', null, global);
goog.exportSymbol('proto.apidashboardgrpc.ProductInfo', null, global);
goog.exportSymbol('proto.apidashboardgrpc.Promo', null, global);
goog.exportSymbol('proto.apidashboardgrpc.RedirectType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Promo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.Promo.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.Promo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Promo.displayName = 'proto.apidashboardgrpc.Promo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ItemsURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.ItemsURL.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.ItemsURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ItemsURL.displayName = 'proto.apidashboardgrpc.ItemsURL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.GetInfoArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.GetInfoArg.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.GetInfoArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.GetInfoArg.displayName = 'proto.apidashboardgrpc.GetInfoArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ProductInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.ProductInfo.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.ProductInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ProductInfo.displayName = 'proto.apidashboardgrpc.ProductInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.ItemsReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apidashboardgrpc.ItemsReference.repeatedFields_, null);
};
goog.inherits(proto.apidashboardgrpc.ItemsReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.ItemsReference.displayName = 'proto.apidashboardgrpc.ItemsReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apidashboardgrpc.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apidashboardgrpc.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apidashboardgrpc.Image.displayName = 'proto.apidashboardgrpc.Image';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.Promo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Promo.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Promo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Promo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Promo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ean: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    apidashboardgrpc_common_pb.Filter.toObject, includeInstance),
    isActive: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Promo}
 */
proto.apidashboardgrpc.Promo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Promo;
  return proto.apidashboardgrpc.Promo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Promo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Promo}
 */
proto.apidashboardgrpc.Promo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 2:
      var value = new apidashboardgrpc_common_pb.Filter;
      reader.readMessage(value,apidashboardgrpc_common_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Promo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Promo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Promo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Promo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      apidashboardgrpc_common_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string ean = 1;
 * @return {string}
 */
proto.apidashboardgrpc.Promo.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Promo.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.apidashboardgrpc.Filter>}
 */
proto.apidashboardgrpc.Promo.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, apidashboardgrpc_common_pb.Filter, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.Filter>} value */
proto.apidashboardgrpc.Promo.prototype.setFiltersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Filter}
 */
proto.apidashboardgrpc.Promo.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.Promo.prototype.clearFiltersList = function() {
  this.setFiltersList([]);
};


/**
 * optional bool is_active = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.apidashboardgrpc.Promo.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.apidashboardgrpc.Promo.prototype.setIsActive = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.ItemsURL.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ItemsURL.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ItemsURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ItemsURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ItemsURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    ean: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    apidashboardgrpc_common_pb.Filter.toObject, includeInstance),
    redirectType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expiration: (f = msg.getExpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ItemsURL}
 */
proto.apidashboardgrpc.ItemsURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ItemsURL;
  return proto.apidashboardgrpc.ItemsURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ItemsURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ItemsURL}
 */
proto.apidashboardgrpc.ItemsURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 2:
      var value = new apidashboardgrpc_common_pb.Filter;
      reader.readMessage(value,apidashboardgrpc_common_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 3:
      var value = /** @type {!proto.apidashboardgrpc.RedirectType} */ (reader.readEnum());
      msg.setRedirectType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ItemsURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ItemsURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ItemsURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ItemsURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      apidashboardgrpc_common_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getRedirectType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpiration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ean = 1;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsURL.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsURL.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.apidashboardgrpc.Filter>}
 */
proto.apidashboardgrpc.ItemsURL.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, apidashboardgrpc_common_pb.Filter, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.Filter>} value */
proto.apidashboardgrpc.ItemsURL.prototype.setFiltersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Filter}
 */
proto.apidashboardgrpc.ItemsURL.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ItemsURL.prototype.clearFiltersList = function() {
  this.setFiltersList([]);
};


/**
 * optional RedirectType redirect_type = 3;
 * @return {!proto.apidashboardgrpc.RedirectType}
 */
proto.apidashboardgrpc.ItemsURL.prototype.getRedirectType = function() {
  return /** @type {!proto.apidashboardgrpc.RedirectType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.apidashboardgrpc.RedirectType} value */
proto.apidashboardgrpc.ItemsURL.prototype.setRedirectType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsURL.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp expiration = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.ItemsURL.prototype.getExpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.ItemsURL.prototype.setExpiration = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.ItemsURL.prototype.clearExpiration = function() {
  this.setExpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.ItemsURL.prototype.hasExpiration = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.GetInfoArg.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.GetInfoArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.GetInfoArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetInfoArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    refDate: (f = msg.getRefDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    apidashboardgrpc_common_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.GetInfoArg}
 */
proto.apidashboardgrpc.GetInfoArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.GetInfoArg;
  return proto.apidashboardgrpc.GetInfoArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.GetInfoArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.GetInfoArg}
 */
proto.apidashboardgrpc.GetInfoArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefDate(value);
      break;
    case 2:
      var value = new apidashboardgrpc_common_pb.Filter;
      reader.readMessage(value,apidashboardgrpc_common_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.GetInfoArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.GetInfoArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.GetInfoArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      apidashboardgrpc_common_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp ref_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.getRefDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.apidashboardgrpc.GetInfoArg.prototype.setRefDate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.apidashboardgrpc.GetInfoArg.prototype.clearRefDate = function() {
  this.setRefDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.hasRefDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Filter filters = 2;
 * @return {!Array<!proto.apidashboardgrpc.Filter>}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.apidashboardgrpc.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, apidashboardgrpc_common_pb.Filter, 2));
};


/** @param {!Array<!proto.apidashboardgrpc.Filter>} value */
proto.apidashboardgrpc.GetInfoArg.prototype.setFiltersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apidashboardgrpc.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apidashboardgrpc.Filter}
 */
proto.apidashboardgrpc.GetInfoArg.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apidashboardgrpc.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.GetInfoArg.prototype.clearFiltersList = function() {
  this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.ProductInfo.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ProductInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ProductInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ProductInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ProductInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sku: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ean: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    quantityMl: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    labelsList: jspb.Message.getRepeatedField(msg, 7),
    dimensionMmList: jspb.Message.getRepeatedField(msg, 8),
    company: jspb.Message.getFieldWithDefault(msg, 9, ""),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ProductInfo}
 */
proto.apidashboardgrpc.ProductInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ProductInfo;
  return proto.apidashboardgrpc.ProductInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ProductInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ProductInfo}
 */
proto.apidashboardgrpc.ProductInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantityMl(value);
      break;
    case 6:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDimensionMmList(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ProductInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ProductInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ProductInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ProductInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuantityMl();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getDimensionMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional string sku = 1;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductInfo.prototype.setSku = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ean = 2;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductInfo.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductInfo.prototype.setModel = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductInfo.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 quantity_ml = 5;
 * @return {number}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getQuantityMl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ProductInfo.prototype.setQuantityMl = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ItemType type = 6;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.ProductInfo.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated string labels = 7;
 * @return {!Array<string>}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/** @param {!Array<string>} value */
proto.apidashboardgrpc.ProductInfo.prototype.setLabelsList = function(value) {
  jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.ProductInfo.prototype.addLabels = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ProductInfo.prototype.clearLabelsList = function() {
  this.setLabelsList([]);
};


/**
 * repeated int32 dimension_mm = 8;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getDimensionMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.ProductInfo.prototype.setDimensionMmList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.ProductInfo.prototype.addDimensionMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ProductInfo.prototype.clearDimensionMmList = function() {
  this.setDimensionMmList([]);
};


/**
 * optional string company = 9;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ProductInfo.prototype.setCompany = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bytes image = 10;
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes image = 10;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ProductInfo.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/** @param {!(string|Uint8Array)} value */
proto.apidashboardgrpc.ProductInfo.prototype.setImage = function(value) {
  jspb.Message.setProto3BytesField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apidashboardgrpc.ItemsReference.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.ItemsReference.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.ItemsReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.ItemsReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ItemsReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    sku: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ean: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    quantityMl: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    labelsList: jspb.Message.getRepeatedField(msg, 7),
    dimensionMmList: jspb.Message.getRepeatedField(msg, 8),
    shopUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    homeUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    company: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.ItemsReference}
 */
proto.apidashboardgrpc.ItemsReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.ItemsReference;
  return proto.apidashboardgrpc.ItemsReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.ItemsReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.ItemsReference}
 */
proto.apidashboardgrpc.ItemsReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantityMl(value);
      break;
    case 6:
      var value = /** @type {!proto.apidashboardgrpc.ItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDimensionMmList(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopUrl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomeUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.ItemsReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.ItemsReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.ItemsReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.ItemsReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuantityMl();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getDimensionMmList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getShopUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHomeUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string sku = 1;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setSku = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ean = 2;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setModel = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 quantity_ml = 5;
 * @return {number}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getQuantityMl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.apidashboardgrpc.ItemsReference.prototype.setQuantityMl = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ItemType type = 6;
 * @return {!proto.apidashboardgrpc.ItemType}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getType = function() {
  return /** @type {!proto.apidashboardgrpc.ItemType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.apidashboardgrpc.ItemType} value */
proto.apidashboardgrpc.ItemsReference.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated string labels = 7;
 * @return {!Array<string>}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/** @param {!Array<string>} value */
proto.apidashboardgrpc.ItemsReference.prototype.setLabelsList = function(value) {
  jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.ItemsReference.prototype.addLabels = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ItemsReference.prototype.clearLabelsList = function() {
  this.setLabelsList([]);
};


/**
 * repeated int32 dimension_mm = 8;
 * @return {!Array<number>}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getDimensionMmList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<number>} value */
proto.apidashboardgrpc.ItemsReference.prototype.setDimensionMmList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 */
proto.apidashboardgrpc.ItemsReference.prototype.addDimensionMm = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.apidashboardgrpc.ItemsReference.prototype.clearDimensionMmList = function() {
  this.setDimensionMmList([]);
};


/**
 * optional string shop_url = 9;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getShopUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setShopUrl = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string home_url = 10;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getHomeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setHomeUrl = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string company = 11;
 * @return {string}
 */
proto.apidashboardgrpc.ItemsReference.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.ItemsReference.prototype.setCompany = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apidashboardgrpc.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.apidashboardgrpc.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apidashboardgrpc.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    ean: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apidashboardgrpc.Image}
 */
proto.apidashboardgrpc.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apidashboardgrpc.Image;
  return proto.apidashboardgrpc.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apidashboardgrpc.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apidashboardgrpc.Image}
 */
proto.apidashboardgrpc.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apidashboardgrpc.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apidashboardgrpc.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apidashboardgrpc.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string ean = 1;
 * @return {string}
 */
proto.apidashboardgrpc.Image.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.apidashboardgrpc.Image.prototype.setEan = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes image = 2;
 * @return {string}
 */
proto.apidashboardgrpc.Image.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.apidashboardgrpc.Image.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.apidashboardgrpc.Image.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/** @param {!(string|Uint8Array)} value */
proto.apidashboardgrpc.Image.prototype.setImage = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.apidashboardgrpc.RedirectType = {
  SHOP_URL: 0,
  TMP_URL: 1,
  HOME_URL: 2
};

goog.object.extend(exports, proto.apidashboardgrpc);
