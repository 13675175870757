import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useBreadcrumb, useWatch} from "../actions/appAction";
import Map from "../components/Map";
import Workspace from "../components/viewer/Workspace";

import {makeStyles} from '@material-ui/core/styles';
import {CLOSE, OPEN} from "../constants/viewerConstants";
import * as app from "../constants/appConstants";
import {Badge, FormControlLabel, List, ListItem, Switch, Typography} from "@material-ui/core";
import clsx from "clsx";
import {statusList} from '../lib/GetStatus';
import {useHistory} from "react-router-dom";
import * as grpc from "../constants/gRpcConstants";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        overflow: 'hidden'
    },
    header: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 60,
        width: '100%'
    },
    title: {
        width: '100%'
    },
    legend: {
        display: 'flex',
        width: '400px'
    },
    badge: {
        marginRight: '1em'
    }
});

const PlanogramViewer = ({shop, furSn, filter, store, node, dispatch, open, close, idle, getPlanoOvermap, updatePlanoOvermap, ...rest}) => {

    useBreadcrumb([
        {text: 'Furniture', href: '/furniture'},
        {text: furSn, href: `/furniture/${furSn}` },
    ]);

    useWatch([app.PLANOS_STATUS]);

    const history = useHistory();

    const [isPlanoOvermap, setPlanoOvermap] = useState(false);

    const onPlanoOvermapChange = () => {
        updatePlanoOvermap(shop, furSn, !isPlanoOvermap, () => setPlanoOvermap(!isPlanoOvermap), (error) => {
            debugger;
        });
    };

    useEffect(() => {
        if (typeof shop !== "string") {
            return;
        }
        getPlanoOvermap(shop, furSn,
            (response) => setPlanoOvermap(response.isPlanoOvermap),
            (error) => setPlanoOvermap(false),
        )
    }, [shop]);

    useEffect(() => {
        return history.listen((location) => {
            if (location.pathname !== `/furniture/${furSn}`) {
                close();
            }
        });
    },[]);

    useEffect(() => {
        open(furSn);
    }, []);

    const classes = useStyles();

    if (typeof node !== 'object') {
        return null;
    }

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <Typography variant="h1" noWrap={true}>{furSn}</Typography>
                <List className={classes.legend}>
                    {statusList.map(({color, text}) => (
                        <ListItem key={text}>
                            <Badge badgeContent=" " className={clsx(classes.badge, color)} />
                            <Typography variant="h6" noWrap={true}>{text}</Typography>
                        </ListItem>
                    ))}
                </List>
                <div>
                    <FormControlLabel
                        control={<Switch color="primary" checked={isPlanoOvermap} onChange={onPlanoOvermapChange} />}
                        label="Set current mapping as planogram"
                        labelPlacement="end"
                    />
                </div>
                <div><Map /></div>
            </header>
            <Workspace componentId={furSn} />
        </div>
    );
};

const mapStateToProps = ({app, viewer}, ownProps) => {
    const furSn = ownProps.match.params.furSn;
    return {
        furSn,
        shop: app.shop,
        node: viewer.components[furSn],
        filter: app.filter,
        isPlanoOvermap: viewer.isPlanoOvermap,
        idle: viewer.idle && app.idle
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    open: (furSn) => dispatch({type: OPEN, furSn}),
    close: () => dispatch({type: CLOSE}),
    getPlanoOvermap: (shop, furSn, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: grpc.GRPC_GET_PLANO_OVERMAP,
        payload: {shop, furSn},
        onSuccess,
        onFailure,
    }),
    updatePlanoOvermap: (shop, furSn, isPlanoOvermap, onSuccess, onFailure) => dispatch({
        type: grpc.GRPC_CALL,
        grpcType: isPlanoOvermap ? grpc.GRPC_SET_PLANO_OVERMAP : grpc.GRPC_UNSET_PLANO_OVERMAP,
        payload: {shop, furSn},
        onSuccess,
        onFailure,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramViewer);
