import {connect} from "react-redux";
import {useEffect, useRef, useState} from "react";
import ComponentFactory from "./ComponentFactory";
import {makeStyles} from '@material-ui/core/styles';
import {mm2px, size} from "../../utils/conversion";
import SwipeableDrawerViewer from "./SwipeableDrawerViewer";

const useStyles = makeStyles({
    root: {
        transform: 'none',
        transformOrigin: 'top left',
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    }
});

const Planogram = ({node, ratio, frame, workspace, ...rest}) => {

    const classes = useStyles();
    const [moduleId, setModuleId] = useState(null);

    const rootRef = useRef();
    useEffect(() => {
        const style = {
            width: size(mm2px(node.width), ratio) + 'px',
            height: size(mm2px(node.height), ratio) + 'px'
        };

        const element = rootRef.current;
        for(let key in style) {
            element.style[key] = style[key];
        }
    }, [frame, ratio]);

    return (
        <div className={classes.root} ref={rootRef}>
            <div className={classes.wrapper}>
                {node.children.map(childId => (
                    <div key={childId}>
                        <ComponentFactory componentId={childId} ratio={ratio} onOpen={(childId) => setModuleId(childId)} />
                    </div>
                ))}
            </div>
            <SwipeableDrawerViewer componentId={moduleId} onOpen={() => {}} onClose={() => setModuleId(null)}/>
        </div>
    );
};

const mapStateToProps = ({viewer}, ownProps) => ({
    ...ownProps,
    frame: viewer.frame,
    mtime: viewer.mtime,
    workspace: viewer.workspace
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Planogram);