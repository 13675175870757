import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import {connect} from "react-redux";
import * as app from "../constants/appConstants";
import DashboardInstructionTable from "../components/table/DashboardInstructionTable";
import Dashboard from "../components/Dashboard";
import Filter from "../components/Filter";
import {useWatch} from "../actions/appAction";

const useStyles = makeStyles({
    grid: {
        flexGrow: 1,
    },
    item: {
        flexGrow: 2
    },
    filter: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        padding: '1em',
        width: '100%'
    },
    gamme: {
        borderRadius: '1em',
        margin: '1em',
        padding: '0 1em',
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:active': {
            color: 'inherit',
            backgroundColor: 'inherit',
            boxShadow: 'inherit',
        },
    },
    selected: {
        color: 'white',
        backgroundColor: '#42a5f5',
        boxShadow: '0 8px 16px 2px rgba(66, 165, 245, 0.24)',
        '&:hover': {
            color: 'white',
            backgroundColor: '#42a5f5',
            boxShadow: '0 8px 16px 2px rgba(66, 165, 245, 0.24)',
        },
    }
});

const DashboardView = () => {
    const classes = useStyles();

    useWatch([app.SHOPS_INSTRUCTIONS, app.SHOPS_ANALYTICS]);

    return (
        <Container className={classes.root} maxWidth={false}>
            <Filter />
            <Dashboard />
            <DashboardInstructionTable />
        </Container>
    );
}

export default connect(null, null)(DashboardView);