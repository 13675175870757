import React from 'react';
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Icon, Tooltip, Typography} from "@material-ui/core";
import InstructionCode from "../../lib/InstructionCode";
import TableWrapper from "./TableWrapper";
import defaultColumns from './columns';
import Label from "../Label";

const columns = [
    ...defaultColumns,
    {
        label: 'Problem',
        align: 'left',
        format: (row) => {
            let instruction = InstructionCode[row.instruction];
            return (
                <Tooltip title={instruction.title}>
                    <Label color={instruction.level}>
                        {instruction.text}
                    </Label>
                </Tooltip>
            );
        },
        orderBy: 'instruction'
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em 0'
    },
    table: {
        maxHeight: 440,
    },
    search: {
        width: 400,
        marginTop: '1em'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    badge: {
        height: '2em',
        borderRadius: '1em',
        textTransform: 'capitalize',
        margin: '0 1em',
        minWidth: 'auto',
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        '& > .MuiButton-label': {
            padding: '0 .2em',
        }
    }
}));

const DashboardInstructionTable = ({shopsInstructions}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h2">
                What to do to get the perfect score
                <Button variant="contained" color="inherit" className={classes.badge} size="small" component={'div'}>
                    {shopsInstructions.length}
                </Button>
            </Typography>
            <TableWrapper rows={shopsInstructions} columns={columns} defaultOrderBy={3} />
        </Box>
    );
}

const mapStateToProps = ({app}) => ({
    shopsInstructions: app.shopsInstructions,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInstructionTable);