class Types {
    /** bitwise **/
    static PRODUCT   = 0b00000000000001; // 1
    static VISUAL    = 0b00000000000010; // 2 : Display
    static LABEL     = 0b00000000000100; // 4 : Display
    static PEEL_OFF  = 0b00000000001000; // 8 : Display
    static TESTER    = 0b00000000010000; // 16
    static SHELF     = 0b00000000100000; // 32 : Shelf - placement libre
    static GROUP     = 0b00000001000000; // 64 : not used
    static RANGE     = 0b00000100000000; // 128
    static SHELF3D   = 0b00001000000000; // 512
    static MODULE    = 0b00010000000000; // 1024
    static CLUSTER   = 0b00100000000000; // 2048 : not used
    static FIXTURE   = 0b01000000000000; // 4096 : not used
    static PLANOGRAM = 0b10000000000000; // 8192

    static ITEM = this.PRODUCT | this.VISUAL | this.PEEL_OFF | this.LABEL;
    static COMPOUND = this.PLANOGRAM | this.FIXTURE | this.MODULE;

    /**
     *
     * @param value
     * @param type
     * @returns {boolean}
     */
    static is(value, type) {
        return (value & type) > 0;
    }
}

export default Types;