/**
 * @fileoverview gRPC-Web generated client stub for authenticategrpc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.authenticategrpc = require('./jwt_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.authenticategrpc.JWTClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.authenticategrpc.JWTPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authenticategrpc.UserCredentials,
 *   !proto.authenticategrpc.JWTResult>}
 */
const methodDescriptor_JWT_Create = new grpc.web.MethodDescriptor(
  '/authenticategrpc.JWT/Create',
  grpc.web.MethodType.UNARY,
  proto.authenticategrpc.UserCredentials,
  proto.authenticategrpc.JWTResult,
  /**
   * @param {!proto.authenticategrpc.UserCredentials} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.JWTResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.authenticategrpc.UserCredentials,
 *   !proto.authenticategrpc.JWTResult>}
 */
const methodInfo_JWT_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.authenticategrpc.JWTResult,
  /**
   * @param {!proto.authenticategrpc.UserCredentials} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.JWTResult.deserializeBinary
);


/**
 * @param {!proto.authenticategrpc.UserCredentials} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.authenticategrpc.JWTResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authenticategrpc.JWTResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authenticategrpc.JWTClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authenticategrpc.JWT/Create',
      request,
      metadata || {},
      methodDescriptor_JWT_Create,
      callback);
};


/**
 * @param {!proto.authenticategrpc.UserCredentials} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authenticategrpc.JWTResult>}
 *     A native promise that resolves to the response
 */
proto.authenticategrpc.JWTPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authenticategrpc.JWT/Create',
      request,
      metadata || {},
      methodDescriptor_JWT_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authenticategrpc.JWTToken,
 *   !proto.authenticategrpc.VerificationResult>}
 */
const methodDescriptor_JWT_Verify = new grpc.web.MethodDescriptor(
  '/authenticategrpc.JWT/Verify',
  grpc.web.MethodType.UNARY,
  proto.authenticategrpc.JWTToken,
  proto.authenticategrpc.VerificationResult,
  /**
   * @param {!proto.authenticategrpc.JWTToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.VerificationResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.authenticategrpc.JWTToken,
 *   !proto.authenticategrpc.VerificationResult>}
 */
const methodInfo_JWT_Verify = new grpc.web.AbstractClientBase.MethodInfo(
  proto.authenticategrpc.VerificationResult,
  /**
   * @param {!proto.authenticategrpc.JWTToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.VerificationResult.deserializeBinary
);


/**
 * @param {!proto.authenticategrpc.JWTToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.authenticategrpc.VerificationResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authenticategrpc.VerificationResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authenticategrpc.JWTClient.prototype.verify =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authenticategrpc.JWT/Verify',
      request,
      metadata || {},
      methodDescriptor_JWT_Verify,
      callback);
};


/**
 * @param {!proto.authenticategrpc.JWTToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authenticategrpc.VerificationResult>}
 *     A native promise that resolves to the response
 */
proto.authenticategrpc.JWTPromiseClient.prototype.verify =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authenticategrpc.JWT/Verify',
      request,
      metadata || {},
      methodDescriptor_JWT_Verify);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authenticategrpc.JWTToken,
 *   !proto.authenticategrpc.JWTResult>}
 */
const methodDescriptor_JWT_Renew = new grpc.web.MethodDescriptor(
  '/authenticategrpc.JWT/Renew',
  grpc.web.MethodType.UNARY,
  proto.authenticategrpc.JWTToken,
  proto.authenticategrpc.JWTResult,
  /**
   * @param {!proto.authenticategrpc.JWTToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.JWTResult.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.authenticategrpc.JWTToken,
 *   !proto.authenticategrpc.JWTResult>}
 */
const methodInfo_JWT_Renew = new grpc.web.AbstractClientBase.MethodInfo(
  proto.authenticategrpc.JWTResult,
  /**
   * @param {!proto.authenticategrpc.JWTToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authenticategrpc.JWTResult.deserializeBinary
);


/**
 * @param {!proto.authenticategrpc.JWTToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.authenticategrpc.JWTResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authenticategrpc.JWTResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authenticategrpc.JWTClient.prototype.renew =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authenticategrpc.JWT/Renew',
      request,
      metadata || {},
      methodDescriptor_JWT_Renew,
      callback);
};


/**
 * @param {!proto.authenticategrpc.JWTToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authenticategrpc.JWTResult>}
 *     A native promise that resolves to the response
 */
proto.authenticategrpc.JWTPromiseClient.prototype.renew =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authenticategrpc.JWT/Renew',
      request,
      metadata || {},
      methodDescriptor_JWT_Renew);
};


module.exports = proto.authenticategrpc;

