import Types from '../../lib/Types';
import Product from "./Product";
import Display from "./Display";

const ItemFactory = (props) => {

    if (Types.is(props.item.type, Types.PRODUCT)) {
        return <Product {...props} />;
    }

    if (Types.is(props.item.type, Types.ITEM)) {
        return <Display {...props} />;
    }

    return null;
};

export default ItemFactory;